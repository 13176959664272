import React, {Component} from 'react'
import Button from 'react-bootstrap/Button'
import ReactGA from 'react-ga';
import Http from 'axios'
import {Redirect} from 'react-router-dom'
class WFContact extends React.Component{
	constructor(){
		super();
		// Add your tracking ID created from https://analytics.google.com/analytics/web/#home/
		ReactGA.initialize('UA-53038418-2');
		// This just needs to be called once since we have no routes in this case.
		ReactGA.pageview(window.location.pathname);
		//cv=require('./opencv.js');
		this.state={name:"She",role:"Tutor",email:"she@email.com",mobile:"1111122222",status:true,open: false};
		
	}

	handleChange = (event)=>{
		let nam=event.target.name;
		let val=event.target.value;
		this.setState({[nam]:val});
	}
	handleSubmit = (event)=>{
                const formElement = document.querySelector('form');
                const successElement = document.querySelector('.w-form-done');
		const x=new Date();
		event.preventDefault();
		        Http.post('contact-api/contact',{
		          "role":this.state.role,
		          "name":this.state.name,
		          "email":this.state.email,
		          "mobile":this.state.mobile,
		          "message1":this.state.message1,
			  "timezone":x.getTimezoneOffset(),
		        }, {
		          headers: {
		            "Content-Type": "application/json"
		          }
		        })
		      .then(res => {
                      const response = res.data;
                      this.setState({ user:response,status:false});
		      formElement.classList.add('d-none');
		      successElement.classList.remove('w-form-done');
	              return <Redirect to="/" />
                       })
	}
  render() {
    return (
      <div>
        {/*  Last Published: Mon Feb 19 2024 04:04:46 GMT+0000 (Coordinated Universal Time)  */}
        <meta charSet="utf-8" />
        <title>Contact</title>
        <meta content="If you would like to conduct a calm, secure exam with privacy and flexibility please contact MonitorExam" name="description" />
        <meta content="Contact" property="og:title" />
        <meta content="If you would like to conduct a calm, secure exam with privacy and flexibility please contact MonitorExam" property="og:description" />
        <meta content="Contact" property="twitter:title" />
        <meta content="If you would like to conduct a calm, secure exam with privacy and flexibility please contact MonitorExam" property="twitter:description" />
        <meta property="og:type" content="website" />
        <meta content="summary_large_image" name="twitter:card" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <link href="../assets/css/normalize.css" rel="stylesheet" type="text/css" />
        <link href="../assets/css/webflow.css" rel="stylesheet" type="text/css" />
        <link href="../assets/css/monitorexam-4f64b7a40832b476405c7c490d4.webflow.css" rel="stylesheet" type="text/css" />
        <link href="https://fonts.googleapis.com" rel="preconnect" />
        <link href="https://fonts.gstatic.com" rel="preconnect" crossOrigin="anonymous" />
        <link href="../images/website/favicon.png" rel="shortcut icon" type="image/x-icon" />
        <link href="../images/website/webclip.png" rel="apple-touch-icon" />
        <div className="subpage-header contact-1">
          <div className="container w-container">
            <div data-ix="fade-in-on-load-2" className="white-contact-form w-form">
              <form id="wf-form-Email-Form-2" name="wf-form-Email-Form-1" data-name="Email Form 1" method="get" data-wf-page-id="65b24ef7d67891b42aadaa36" data-wf-element-id="6427d2d8-bd35-2a54-5e49-ede3e7334e66" onSubmit={this.handleSubmit} >
                <h1 className="form-title">Contact us now</h1>
                <h2 className="form-title subtitle">Do you have a question? Contact us now!</h2>
	        <label htmlFor="name-2" className="field-label">Name:</label>
	        <input className="field rounded w-input" maxLength={256} name="name" data-name="Name 2" onChange={this.handleChange} placeholder type="text" id="name-2" />
	        <label htmlFor="Email-3" className="field-label">Email address:</label>
	        <input className="field rounded w-input" maxLength={256} name="email"  onChange={this.handleChange} data-name="Email" placeholder type="email" id="Email-3" required />
	        <label htmlFor="Message" className="field-label">Your message:</label>
	        <textarea id="Message" name="message1" maxLength={5000} onChange={this.handleChange} data-name="message1" placeholder className="field rounded area w-input" defaultValue={""} />
	        <input type="submit" data-wait="Please wait..." className="button w-button" defaultValue="Send Email" />
              </form>
              <div className="success-message w-form-done">
                <p>Thank you for contacting us! We will contact you soon!</p>
                  <a href="https://wa.link/omfx22"><em className="icon ni ni-whatsapp"/></a>
              </div>
              <div className="error-bg w-form-fail">
                <p>Oops! Something went wrong while submitting the form</p>
              </div>
            </div>
            <div className="contact-info-block-right">
              <div data-ix="fade-in-on-load-3" className="contact-info-block">
                <div className="contact-info-small-block">
                  <div className="small-contact-info-title">Email</div>
                  <p className="contact-info-description">care@monitorexam.com</p>
                </div>
                <div className="contact-info-small-block">
                  <div className="small-contact-info-title">Phone number</div>
                  <p className="contact-info-description">+91-782-7637-626</p>
                </div>
                <div className="contact-info-small-block">
                  <div className="small-contact-info-title">Our Location</div>
                  <p className="contact-info-description">Nasscom Innovation Hub,
	    <br />Plot 1,<br />Sector 20,<br />Gurugram</p>
                </div>
                <div className="contact-info-small-block">
                  <div className="small-contact-info-title">Support</div>
                  <p className="contact-info-description">We promise you World Class Support. For any issues please contact care@monitorexam.com</p>
                </div>
              </div>
            </div>
          </div>
          <div data-animation="default" className="navlink white link-2 w-nav" data-easing2="ease-in" data-easing="ease-out" data-collapse="all" role="banner" data-duration={400} data-doc-height={1} sr>
            <div className="w-container">
              <a href="/home" className="logo-block w-nav-brand"><img src="../images/website/monitor-exam-logox2.png" alt="" className="logo fixed link-2" /></a>
              <address role="navigation" className="nav-menu w-nav-menu">
                <a href="/product" className="product w-nav-link">PRODUCT</a>
                <a href="/about" className="about w-nav-link">ABOUT</a>
                <a href="/pricing" className="pricing w-nav-link">PRICING</a>
                <a href="https://talk.monitorexam.com" className="blog w-nav-link">BLOG</a>
                <a href="/contact" aria-current="page" className="contact w-nav-link w--current">CONTACT</a>
	        <button className="user-log-in-log-out" data-wf-user-logout="LOGOUT" data-wf-user-login="LOGIN" onClick={()=>window.location.href='/newLogin'} type="button">LOGIN</button>
              </address>
            </div>
            <div className="fixed-nav-menu-button w-nav-button" onClick={()=>this.setState({open:!this.state.open})}>
              <div className="icon w-icon-nav-menu" />
            </div>
          </div>
	  <div class="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0" style={{height:this.state.open?"2846px": "0px", display:this.state.open?"block": "none"}}>
	      <address role="navigation" class="nav-menu w-nav-menu" style={{transform: "translateY(0px) translateX(0px)", transition: "transform 400ms ease-out 0s;"}} data-nav-menu-open="">
                <a href="/product" className="product w-nav-link w--nav-link-open">PRODUCT</a>
                <a href="/about" className="about w-nav-link w--nav-link-open">ABOUT</a>
                <a href="/pricing" className="pricing w-nav-link w--nav-link-open">PRICING</a>
                <a href="https://talk.monitorexam.com" className="blog w-nav-link w--nav-link-open">BLOG</a>
                <a href="/contact" className="contact w-nav-link w--nav-link-open">CONTACT</a>
	      <button className="user-log-in-log-out" data-wf-user-logout="LOGOUT" data-wf-user-login="LOGIN" onClick={()=>window.location.href='/newLogin'}type="button">LOGIN</button>
        </address></div>
        </div>
        <div className="section">
          <div className="container w-container">
            <div className="text-row w-row">
              <div className="text-column w-col w-col-3">
                <div className="text-column-title">Our Support Desk</div>
                <p className="small-paragraph">MonitorExam team believes that someone should have your back when you are conducting your exam. Running a successful live exam comes above averything else</p>
              </div>
              <div className="text-column w-col w-col-3">
                <div className="text-column-title">Frequently asked</div>
                <p className="small-paragraph">We talk to a lot of people and some questions keep coming to us again and again. Those questions have been put in our <a href="https://monitorexam.com/FAQ">FAQ</a>&nbsp;page</p>
              </div>
              <div className="text-column w-col w-col-3">
                <div className="text-column-title">Contact ON CHAT</div>
                <p className="small-paragraph">If you would like to Chat more than talk or email please use our Contact on Chat option</p>
              </div>
              <div className="text-column w-col w-col-3">
                <div className="text-column-title">Community</div>
                <p className="small-paragraph">We currently have a small community of few teachers.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="image-section newsletter">
          <div className="image-section-overlay pricing">
            <div className="container w-container">
              <div className="section-title-wrapper full-centered">
                <h2 className="section-title">Subscribe to our newsletter</h2>
                <div className="section-description">Enter your email below for our newletter. We just share updates once a month</div>
              </div>
              <div className="contact-form w-form">
                <form id="wf-form-Personal-Contact-Form" name="wf-form-Personal-Contact-Form" data-name="Personal Contact Form" method="get" data-wf-page-id="65b24ef7d67891b42aadaa36" data-wf-element-id="cb1fcea2-e1c8-45ba-4e88-700d00f98fe0">
                  <div className="w-row">
                    <div className="w-col w-col-6"><input className="field email w-input" maxLength={256} name="Email-2" data-name="Email 2" placeholder="Enter your email address" type="text" id="Email-2" required /></div>
                    <div className="w-col w-col-6"><input type="submit" data-wait="Please wait..." className="button submit-newsletter w-button" defaultValue="Send Email" /></div>
                  </div>
                </form>
                <div className="success-message w-form-done">
                  <p>Thank you! Your submission has been received!</p>
                </div>
                <div className="error-bg w-form-fail">
                  <p>Oops! Something went wrong while submitting the form</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="simple-footer">
          <div className="container w-container">
            <div className="footer-row w-row">
              <div className="footer-logo-column-left w-col w-col-3">
                <div className="footer-logo">Monitorexam</div>
                <div className="footer-logo subtitle" />
              </div>
              <div className="footer-menu-column w-col w-col-6">
                <a href="\" className="footer-inline-link">Home</a>
                <a href="\about" className="footer-inline-link">About us</a>
                <a href="\pricing" className="footer-inline-link">Pricing</a>
                <a href="\contact" aria-current="page" className="footer-inline-link w--current">Contact</a>
                <a href="https://talk.monitorexam.com" className="footer-inline-link">Blog</a>
              </div>
              <div className="footer-social-col-right w-col w-col-3">
                <a href="http://www.instagram.com/monitorexam" target="_blank" className="footer-social-icon w-inline-block"><img src="../images/website/Icon-facebook_3.png" alt="" className="footer-small-social-icon" /></a>
                <a href="https://www.twitter.com/monitorexam" target="_blank" className="footer-social-icon w-inline-block"><img src="../images/website/Icon-twitter.png" alt="" className="footer-small-social-icon" /></a>
                <a href="http://www.linkedin.com/company/innokreat" target="_blank" className="footer-social-icon w-inline-block"><img src="../images/website/Icon-google.png" alt="" className="footer-small-social-icon" /></a>
              </div>
            </div>
          </div>
          <div className="simple-bottom-footer">
            <div className="container w-container">
              <p className="bottom-footer-paragraph"><strong data-new-link="true">Copyright © Innokreat Technologies Pvt Ltd 2014-2024</strong>‍</p>
              <p className="bottom-footer-paragraph right" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default WFContact
