import React from 'react'
import ReactDOM from 'react-dom'
import Jumbotron from 'react-bootstrap/Jumbotron'
import ReactGA from 'react-ga';
    class Job extends React.Component {
	constructor(){
		super();
		// Add your tracking ID created from https://analytics.google.com/analytics/web/#home/
		ReactGA.initialize('UA-53038418-2');
		// This just needs to be called once since we have no routes in this case.
		ReactGA.pageview(window.location.pathname);
		this.state = {
                status: false
            };
	}
	
  render(){
    return (
		<div>
          <header className="header" style={{top:"0px"}}>
            <div className="">
              <a href="/" className="logo"><img src="../images/monitor-exam-logo.png" alt="logo" width={120} /></a>
              <input className="menu-btn" type="checkbox" id="menu-btn" />
              <label className="menu-icon" htmlFor="menu-btn"><span className="navicon" /></label>
              <ul className="menu">
                <li><a href="\">Home</a></li>
		<li><a href="\about" >About Us</a></li>
                <li className="hiw"><a href="\howitworks" >How It Works</a></li>
                <li><a href="\faq">FAQ's</a></li>
		<li><a href="\pricing">Pricing</a></li>
                <li><a href="\contact">Contact</a></li>
                <li><a href="\newlogin" className="btn btn-lg btn-outline-primary header-btn login">Login</a></li>
                <li><a href="\newiregister" className="btn btn-lg btn-primary header-btn">Register</a></li>
              </ul>
            </div>
          </header>
		  <meta className="ml-200 mb-50 mr-200" name="viewport" content="width=device-width,initial-scale=1.0" />
		  <section className="bg-light container call_to_action_1">
		    <div className="row px-xl-0 justify-content-center pt-100">
		      <div className=" col-xl-6 col-md-8 col-sd-10" data-aos="fade-up" data-aos-delay={300}>
      <div>
        <title>Internship Opportunity at Monitor Exam</title>
        <h3>Job Title: Student/Professional Intern (Unpaid)</h3>
        <h3>Location: Remote</h3>
        <h3>Duration: 4-6 weeks</h3>
        <h4>Job Description:</h4>
        <p>As an intern at Monitor Exam, your primary responsibility will be to connect with universities and professors in the US region and understand the problem statements provided by our company. You will work closely with our team to gather relevant information and insights, enabling us to develop effective solutions that address the identified challenges. This internship will offer you a unique opportunity to enhance your communication, research, and problem-solving skills while gaining valuable experience in a professional setting.</p>
        <h4>Responsibilities:</h4>
        <ul>
          <li>Conduct extensive research to identify universities and professors relevant to our target field/industry.</li>
          <li>Establish contact with the identified universities and professors to introduce our company and discuss potential collaboration opportunities.</li>
          <li>Communicate with university representatives and professors to understand their problem statements and requirements.</li>
          <li>Collaborate with the Monitor Exam team to analyze the gathered information and propose feasible solutions.</li>
          <li>Maintain accurate and organized records of all interactions and findings.</li>
          <li>Provide regular progress updates to the team, highlighting any challenges or opportunities discovered.</li>
        </ul>
	    <br />
        <h4>Requirements:</h4>
        <ul>
          <li>Strong written and verbal communication skills.</li>
          <li>Excellent interpersonal skills and the ability to build relationships with professionals.</li>
          <li>Proficient in conducting online research and gathering information.</li>
          <li>Highly organized with the ability to manage multiple tasks and meet deadlines.</li>
          <li>Self-motivated and able to work independently with minimal supervision.</li>
          <li>Familiarity with [mention any specific industry/field relevant to the internship].</li>
          <li>Ability to adapt quickly and learn new concepts.</li>
        </ul>
	    <br />
        <h4>Benefits:</h4>
        <ul>
          <li>Gain hands-on experience in connecting with universities and professors.</li>
          <li>Expand your professional network by establishing connections with professionals in your field of interest.</li>
          <li>Enhance your research, communication, and problem-solving skills.</li>
          <li>Obtain a letter of recommendation upon successful completion of the internship.</li>
        </ul>
	    <p>Please fill the form below</p>
<a href="https://forms.gle/t2DReRQ9QX6i2eyU6" className="btn btn-outline-primary mr-15 mb-10">Apply Here</a>
		                    
        <p>We look forward to receiving your application!</p>
	    <br />
	    <br />
      </div>
      </div>
      </div>
	    </section>
      </div>
    );
  }
}
export default Job
