
import React, {Component} from 'react'
import Button from 'react-bootstrap/Button'
import Profile from './Profile'
import ListGroup from 'react-bootstrap/ListGroup'
import Card from 'react-bootstrap/Card'

/* Exam Creation process */
class Wait extends React.Component{
	constructor(){
		super();
		var x=new Date();
		this.state={ time:x,offset:x.getTimezoneOffset(),state:"Unset"};
	}
	componentWillMount(){
		console.log("Wait Component");
                const user = JSON.parse(localStorage.getItem('user'));
                const exam = JSON.parse(localStorage.getItem('exam1'));
		this.state.exam=exam;
		this.state.user=user;

		console.log(this.state.exam);
	        var state=((Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z"))<(Date.now()))?(((Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z")+this.state.exam.duration*60000)>(Date.now()))?"OnTime":"Late"):"Early";
		this.state.state=state;
		console.log(this.state.state);
		this.interval = setInterval(()=>this.setState({time:Date.now()}),1000);
	}
	componentDidUnmount(){
		clearInterval(this.interval);
	}
	toLocal = (utctime) => { var x=new Date(Date.parse("1970-01-01T"+utctime.toString()+"Z"));x+=this.state.user.timezone*60000;return(x.toLocaleString().slice(16,24));}

	render(){
		if(this.state.state=="Early")
	return(
		<div>
		<br />
            <
            nav className = "navbar" >
            <nav className = "navbar-brand">
           	        	<h3><a style={{color:"#fafafa"}} href="/"><img alt="monitorexam" src="/monitorexam_t.png" width="213px" height="120px"></img></a></h3>
            </nav> <
            nav className = "navbar appmenu right" >
            <
            a className = "nav-link"
            style = {
                {
                    color: "#6576ff"
                }
            }
            href = "/examdash" > Exam Dashboard < /a> 
		< a className = "nav-link" style = { { display: this.state.user.role == 'invig' ? 'block' : 'none', color: "#6576ff" } }
            href = "/form" > Create Exam < /a> 
		<
            a className = "nav-link"
            href = "/login"
            style = {
                {
                    backgroundColor: "#6576ff",
                    color: "white",
                    borderRadius: "30px"
                }
            }
            onClick = {
                () => localStorage.clear()
            } > Logout < /a> <
            /nav> <
            /nav> 
		<br />
		<br />
		<br />
		<br />
		<div className="row" style={{backgroundColor:"#f8faf7"}}>
		<div style={{backgroundColor:"white", color:"#6576ff",margin:"16px"}} className="column side">
		</div>
		<div className="column middle">
        <Card style={{ width: '40rem', padding: '2rem',backgroundColor:'#f7f6f8' }}>
		<Card.Title>Checklist before Verification room </Card.Title>
                <ListGroup>
                <ListGroup.Item> <span> WebCam:       Ensure that you have a webcam connected </span> </ListGroup.Item> 
                <ListGroup.Item> <span> Location:       Enable location sharing on your device </span> </ListGroup.Item> 
                <ListGroup.Item> <span> Connectivity:   Ensure that you have connectivity upto 1Mbps  </span> </ListGroup.Item> 
                <ListGroup.Item> <span> <h5> Start Time   : {this.toLocal(this.state.exam.start_time)}</h5></span> </ListGroup.Item>
                <ListGroup.Item> <span> <h5> Current Time : <label for="msg"style={{color:"#101010"}}><b>{new Date().toLocaleTimeString()}</b></label></h5></span> </ListGroup.Item> 
		
		
                
		<Button id="snap" style={{backgroundColor:"#6576ff",paddingTop:"10px", align:"right",border:"none",margin:'2px'}} variant="primary" type='submit' placeholder='Wait' size="md" >
			<a href='/profile' style={{color:"white",display:this.state.user.role=='invig'?"none":"block"}}>
			{((Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z"))<(Date.now()))?(((Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z")+this.state.exam.duration*60000)>(Date.now()))?"Enter Exam":"Sorry You are Late"):"Waiting for Exam to Start"}
			</a></Button>
		</ListGroup>
		</Card>
		</div>
		</div>
		</div>
	);
		else
			return(<Profile user={this.state.user}/>);
        }
}

export default Wait
