import React, { useEffect } from 'react';

const EventReceiver = () => {
  useEffect(() => {
    const source = new EventSource("https://localhost:8081/events/stream1");
    const handleEvent = (event) => {
      console.log(event.data);
      // You can set the data to state or perform any other actions here
    };
    source.onmessage = handleEvent;
    // Cleanup the event source when the component unmounts
    return () => {
      source.close();
    };
  }, []); // Empty dependency array means this effect will run once when the component mounts
  return (
    // Your component JSX
    <div>
      {/* Render your component content here */}
	  <p>Listening to event</p>
    </div>
  );
};
export default EventReceiver;

