import React,{Component} from 'react'
import ReactDOM from 'react-dom'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Http from 'axios'
import StudentCard from './StudentCard'
import '../Auth/Auth.css'
import Card from 'react-bootstrap/Card'
import '../Chat/Chat.css';
import Chat from '../Chat/Chat'



class MonitorDashboard extends Component{
	constructor(){
		super();
		this.state={isLoaded:false, users:[]};
	}
	async componentWillMount() {
	
	//get data from storage
	 const exam = JSON.parse(localStorage.getItem('exam1'));
	 this.state.exam=exam;
	 const user = JSON.parse(localStorage.getItem('user'));
	 this.state.user=user;
	 var tout,minutes_left;
	 
	         //Time passed since exam and left
	    const date = Date.parse(this.state.exam.date);
	    const time = Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z");
	    var   now  = Date.now();
	    var   passed = (now-date-time)/60000;
	    
	    
	    //minutes_left = Math.floor(((Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z"))/60000)) + this.state.exam.duration - Math.floor((Date.now()/60000));
	    minutes_left= Math.floor(this.state.exam.duration - passed);
	    console.log(minutes_left);
	    this.state.minutes_left=minutes_left;
	    
	    this.state.exam_status=(minutes_left>this.state.exam.duration)?"Not Started":((minutes_left<=0)?"Done":"Ongoing");
	    //TODO: Minutes can be calculated from here. Put timeout or time interval?
	    if(this.state.exam_status!="Done")
	    {
     //       tout=setTimeout(()=>{this.state.exam_status=(Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z"))<(Date.now())?(((Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z")+this.state.exam.duration*60000)>(Date.now()))?"Ongoing":"Done"):"Not Started";},60000);
	        tout=setInterval(()=>{this.state.exam_status=(this.state.minutes_left-->this.state.exam.duration)?"Not Started":((minutes_left<0)?"Done":"Ongoing")},60000);
	    }
	    else
	        clearTimeout(tout);

	
	//ask exam data
	 var api;
    Http.defaults.headers.common['Authorization'] = this.state.user.token;
	 if(exam) 
		api='user-api/useru/'+exam.id;
	 else
		api='user-api/userd/' + user.id;
		
		
		const response = await	Http.get(api);
        this.setState({ isLoaded:true, users:response.data });
        
        
	    
	}
	//get the state every 30 seconds
	    async componentDidMount() {
            //regulary monitor candidate states
		     this.timerID = setInterval(() => {
                      this.updateState();
            }, 30000);
	    }
	    componentWillUnmount() {
	      clearInterval(this.timerID);
	      clearInterval(this.timerCap);
	    }
        async updateState (event) {
            const errorMsgElement = document.querySelector('span#errorMsg');
	        //same as componentWillMount
	        const exam = JSON.parse(localStorage.getItem('exam1'));
            this.state.exam=exam;
            
            if(this.state.exam_status!="Done")
            {
            var api;
            if(exam)
                api='user-api/useru/'+exam.id;
            else
                api='user-api/user';
            
            const response = await  Http.get(api);
            this.setState({ isLoaded:true, users:response.data });
            document.getElementById('last_updated').innerHTML=(Date());
            }
            else
            {
                clearInterval(this.timerID);
                clearInterval(this.timerCap);
                console.log("Exam Over. Stopped Requesting status");
                errorMsgElement.innerHTML = "Exam Over. Stopped Requesting status";
            }
	}
	
	//take start time and add offset to it. Display localestring
    toLocal = (utctime) => { 
        var x=new Date(Date.parse("1970-01-01T"+utctime.toString()+"Z"));
        //x+=this.state.user.timezone*60000;
        //return(x.toLocaleString().slice(16,24));
        return(x.toLocaleTimeString());
    }
        toLocalTime = () => {
            console.log(this.props.exam.date);
            var x = new Date(this.props.exam.date + "T" + this.props.exam.start_time + "Z");
            return x.toLocaleTimeString();
        }
        
	handleChange = (event)=>{
		let nam=event.target.name;
		let val=event.target.value;
		this.setState({[nam]:val});
		this.setState({id:0});
	}
	
	AllowUser=(id,token)=>{
	    alert(id+" allowed");
	    //Http.defaults.headers.common['Authorization'] = token;
	    Http.put('user-api/users/' + id, {
                    "status": "Verified",
                }, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then(res => {
                    console.log("User updated Successfully");
                });
	}
	
	DenyUser=(id)=>{
	    alert(id+" denied");
	}
	
	
	updateRemarks = (user)=> {
	        console.log(this.state.remarks);
		    var api="exam-api/examr/" + user.id + "/" + this.state.exam.id + "/" + this.state.remarks ;
	            Http.put(api)
	            .then(res => {
                     console.log(res);
                     console.log("Remarks given successfully");
                   })
                   .catch(function (error) {
                     console.log(error);
                   });
	}
	toLocalLast = (last_login) => {
		       if(last_login=="")
	       return("Exam Not Given");
           var x=new Date(Date.parse(last_login.slice(0, 19).replace(' ', 'T')+'Z'));
           return(x.toLocaleString());
        }
	render() {
	return(
		<div>
<nav className="navbar">
  <nav className="navbar-brand">
	        	<h3><a style={{color:"#fafafa"}} href="/"><img src="/monitorexam_t.png" width="213px" height="120px"></img></a></h3>
  </nav>
  <nav className="navbar appmenu right">
    <a className="nav-link" style={{display:this.state.user.role=='invig'?'block':'none',color:"#00b3b3"}} href="/dashboard">Student Dashboard</a>
    <a className="nav-link" style={{color:"#00b3b3"}} href="/examdash"> Exam Dashboard</a>
    <a className="nav-link" style={{color:"#00b3b3"}} href="/form#createexam">Create Exam</a>
    <a className="nav-link" href="/login" style={{backgroundColor:"#00b3b3",color:"white",borderRadius:"30px"}} onClick={()=>localStorage.clear()}>Logout</a>
  </nav>
</nav>
		<br />
		<br />
		<br />
		<br />
		<h4 style={{backgroundColor:"white",color:"#00b3b3",padding:"8px"}}>Invigilator Monitoring Dashboard for {this.state.exam.name}</h4>

		<div className="row" style={{backgroundColor:"#fff"}}>
		<div style={{backgroundColor:"white", color:"#00b3b3",margin:"16px"}} className="column side">
		</div>
		<div style={{backgroundColor:"white", color:"#00b3b3",margin:"16px"}} className="column middle">
		<p > < span id = "errorMsg" style = { { display: 'block' } } > < /span></p >
		<Card>
		<Card.Title style={{color:"00b3b3"}}> Exam Details</Card.Title>
		<Card.Body>
		<p> <b>Exam Name</b> {this.state.exam.name} </p> 
			  
		<p> <b>Exam Date</b> {Intl.DateTimeFormat().format(Date.parse(this.state.exam.date))} </p> <p> <b>Start Time</b> {this.toLocal(this.state.exam.start_time)} </p>
	    <p> <b>Exam Duration</b> {this.state.exam.duration} minutes </p>
		<p><a href={this.state.exam.link}><b>Exam Link</b></a> </p>
		<p style = {{textAlign: 'right',float:"right"}} >  {(this.state.exam_status=="Ongoing")?(this.state.minutes_left+"min left"):""}  < /p> 
		<label> Test Progress </label><progress value={this.state.exam.duration-this.state.minutes_left} max={this.state.exam.duration} style={{color:"#00b3b3"}} width="250px" height="25px"></progress> 
        <p>Last updated</p><p id="last_updated"></p>
		</Card.Body>
		</Card>
		</div>
		</div>
		<div className="row" style={{backgroundColor:"#fff"}}>
		<div style={{backgroundColor:"white", color:"#00b3b3",margin:"16px"}} className="column side">
		</div>
		<div style={{backgroundColor:"white", color:"#00b3b3",margin:"16px"}} className="column middle">
		<Card>
		<Card.Title style={{color:"00b3b3"}}>Students Registered for Exam</Card.Title>
		<Card.Body>
		<table style={{borderSpacing:"5px",width:"100%"}} class="table-responsive" >

		    <tr style={{paddingLeft:"15px", textAlign:"centre"}}><th> #</th><th>Verification</th><th style={{padding:"15px"}}> Name </th><th style={{display:this.state.exam_status=="Ongoing"?"":"none"}}>Status</th><th style={{display:this.state.exam_status=="Ongoing"?"":"none"}}>cred</th><th style={{display:this.state.exam_status=="Ongoing"?"":"none"}}>Last Seen</th><th>Remarks</th></tr>
		<>
		{this.state.users.map(user=>( <tr style={{padding:"15px",textAlign:"centre"}}><td> {user.id}</td>
		<td>
		    <img src={'http://d1bumj8k6ax755.cloudfront.net/uploads/'+user.reg_pic.split("'")[1]} class="limsthumb" style={{width:"50px",borderRadius:"50%"}} alt="reg pic"/>
		    <img src={'http://d1bumj8k6ax755.cloudfront.net/uploads/'+user.live_pic.split("'")[1]} class="limsthumb" style={{display:user.status=="Verifying"?"block":"none",width:"50px",borderRadius:"50%"}} alt="live pic"/>
		    <p class="btn" onClick={()=>this.AllowUser(user.id,user.token)} style={{display:user.status=="Verifying"?"block":"none",borderRadius:"50%",color:"green",borderStyle:"outset",borderColor:"green"}}>&#10003; </p>
		    <p class="btn" onClick={()=>this.DenyUser(user.id)} style={{display:user.status=="Verifying"?"block":"none",borderRadius:"50%",color:"red",borderStyle:"outset",borderColor:"red"}}> &#10005;</p>
		</td>
		<td> {user.first_name} {user.last_name}</td>
		<td style={{display:"none"}}>{user.email}</td>
		<td style={{display:this.state.exam_status=="Ongoing"?"":"none"}}>{user.status}</td>
		<td style={{display:this.state.exam_status=="Ongoing"?"":"none"}}>{user.cred}</td>
			<td style={{display:this.state.exam_status=="Ongoing"?"":"none"}}>{this.toLocalLast(user.last_login)}</td>
			<td style={{display:"none"}}>{user.lat},{user.lng}</td><td style={{display:"none"}}>{user.ip}</td>
			<td style={{display:"none"}}>{user.camera_state.toString()}</td><td style={{display:"none"}}>{user.network_state.toString()}()</td><td style={{display:"none"}}>{user.battery_state.toString()}</td>
			<td>
			<input name="remarks" type="text" style={{display:this.state.user.role=='invig'?"block":"none"}} placeholder="Remarks" onChange={this.handleChange} /></td><td><a href="#" class="btn" style={{backgroundColor:"#00b3b3",color:"white",borderRadius:"30px"}} onClick={()=>this.updateRemarks(user)}>Send Remarks</a>
			</td>
			</tr>))}
		</>
		</table>
		</Card.Body>
		</Card>
	    </div>
	    </div>
		<div className="row" style={{backgroundColor:"#fff",display:(this.state.exam_status=="Ongoing")?"":"none"}}>
		<div style={{backgroundColor:"white", color:"#00b3b3",margin:"16px"}} className="column side">
		</div>
		<div style={{backgroundColor:"white", color:"#00b3b3",margin:"16px"}} className="column middle">
		<Card>
		<Card.Title style={{color:"00b3b3"}}>Students Needing Attention</Card.Title>
		<Card.Body>
		<>
		    <div className="flex-container">{this.state.users.filter(user=>(user.status.includes("Away")||user.status.includes("Distracted")||user.status.includes("Doubt"))).map(user=>( <StudentCard user={user} />))} </div>
        </>
		</Card.Body>
		</Card>
		</div>
		</div>
		<div className="row" style={{backgroundColor:"#fff"}}>
		<div style={{backgroundColor:"white", color:"#00b3b3",margin:"16px"}} className="column side">
		</div>
		<div style={{backgroundColor:"white", color:"#00b3b3",margin:"16px"}} className="column middle">
		<Chat users={this.state.users} />
	    </div>
	    </div>
	    <br />
	    </div>
	);
	}
}

export default MonitorDashboard
