import React from 'react'
import ReactDOM from 'react-dom'
import Jumbotron from 'react-bootstrap/Jumbotron'
import ReactGA from 'react-ga';
import Menu from '../Menu/Menu';

class Home extends React.Component{
	constructor(){
		super();
		// Add your tracking ID created from https://analytics.google.com/analytics/web/#home/
		ReactGA.initialize('UA-53038418-2');
		// This just needs to be called once since we have no routes in this case.
		ReactGA.pageview(window.location.pathname);
	}
	render() {
	return(
  <div>
	<meta name="viewport" content="width=device-width,initial-scale=1.0" />
  <div id="myCarousel" className="carousel slide py-0" data-ride="carousel" style={{top:"0px"}}>
    <ol className="carousel-indicators" style={{color:"6576ff"}}>
      <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
      <li data-target="#myCarousel" data-slide-to="1"></li>
      <li data-target="#myCarousel" data-slide-to="2"></li>
    </ol>
    <div className="carousel-inner">
      <div className="carousel-item active">
	<img src="/image2.jpg" width="100%" height="100%"></img>
        <div className="container">
          <div className="carousel-caption text-left">
          </div>
        </div>
      </div>
      <div className="carousel-item">
	<img src="/image1.jpg" width="100%" height="100%"></img>
        <div className="container">
          <div className="carousel-caption text-left">
          </div>
        </div>
      </div>
      <div className="carousel-item">
	<img src="/image3.jpg" width="100%" height="100%"></img>
        <div className="container">
          <div className="carousel-caption text-right">
          </div>
        </div>
      </div>
    </div>
    <a className="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      <span className="sr-only">Previous</span>
    </a>
    <a className="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"></span>
      <span className="sr-only">Next</span>
    </a>
  </div>



  <div className="container marketing" style={{position:"relative"}}>

    <div className="row">
      <div className="col-lg-4">
		<img src='/image1.jpg' width="140" height="140" style={{borderRadius:"50%"}} />
        <h4>Step 1</h4>
        <p>Prepare an exam paper in Google forms, Microsoft Forms or Quizizz</p>
      </div>
      <div className="col-lg-4">
		<img src='/image2.jpg' width="140" height="140" style={{borderRadius:"50%"}} />
        <h4>Step 2</h4>
        <p>Create an exam in monitorexam with the link</p>
      </div>
      <div className="col-lg-4">
		<img src='/image3.jpg' width="140" height="140" style={{borderRadius:"50%"}} />
        <h4>Step 3</h4>
        <p>Add students and monitor them during the exam</p>
      </div>
    </div>
		<br />




    <div className="row featurette align-middle">
      <div className="col-md-6">
        <h2 className="featurette-heading">Easy Test Tool Integration</h2><span className="text-muted">Bring your own test preparation tool</span>
        <p className="lead">With LIMS you don't have to rewrite the tests. Just share the link of test in the exam and use it.  </p>
      </div>
      <div className="col-md-6">
	<img src='/image1.jpg' style={{width:'500px', height:'500px'}} />
      </div>
    </div>


    <div className="row featurette">
      <div className="col-md-6 order-md-2">
        <h2 className="featurette-heading">No more fake excuses</h2><span className="text-muted">See for yourself.</span>
        <p className="lead">With LIMS the invigilator can see the state of the examinee all the time. Hence the examinee cannot make fake excuses of network not working, battery failed, camera not working and so on</p>
      </div>
      <div className="col-md-6 order-md-1">
		<img src='/image2.jpg' style={{width:'500px', height:'500px'}} />
      </div>
    </div>


    <div className="row featurette">
      <div className="col-md-6">
        <h2 className="featurette-heading">Give exams from Anywhere </h2>
        <p className="lead">With LIMS you don't have to take the effort and risk of going to the centre. You can give exams from anywhere</p>
      </div>
      <div className="col-md-6">
		<img src='/image3.jpg' style={{width:'500px', height:'500px'}} />
      </div>
    </div>
    </div>
<br />
</div>
	);
}
}

export default Home
