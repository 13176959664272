import React, {
  Component
} from 'react'
import ReactDOM from 'react-dom'
import VerifyStudent from './Verify'
import Test from './Test'
import Jumbotron from 'react-bootstrap/Jumbotron'
import {
  Redirect
} from 'react-router-dom'
import Http from 'axios'
import Button from 'react-bootstrap/Button'
import ScriptTag from 'react-script-tag'
import TimeSeries from '../Util/TimeSeries'
import Chat from '../Chat/Chat'
import Card from 'react-bootstrap/Card'
import Base64js from 'base64-js'

/* User Verification process */
class Confirm extends React.Component {
  constructor() {
    super();
    var x = new Date();
    this.state = {
      time: x,
      offset: x.getTimezoneOffset(),
      state: "Unset",
      first_name: 'Manu',
      last_name: 'Gupta',
      file: null,
      captured: false,
      face: 'visible',
      battery_state: false,
      battery_level: 5,
      userMenu: false,
      menu: false,
      isMirroringOn: false,
      status: "",
      testStarted: false,
      WebAuthnSupport: false,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleUserMenu = this.toggleUserMenu.bind(this);
    this.handleCapture = this.handleCapture.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.updateState = this.updateState.bind(this);
    this.isMirroringOn = this.isMirroringOn.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleAvailabilityChange = (event) => {
    var available = event.target.value;
    var presentBtn = document.getElementById("presentBtn");
    const mMsgElement = document.querySelector('span#mMsg');
    const errorMsgElement = document.querySelector('span#errorMsg');
    //remember if mirroring was ever turned on
    this.state.isMirroringOn = available;

    presentBtn.style.display = available ? "inline" : "none";
    errorMsgElement.innerHTML = available ? "Mirroring" : "not Mirroring";
    if (available)
      alert("Mirroring Detected");
  }

  isMirroringOn() {
    //TODO: These are not valid URLs
    var presUrls = ["https://monitorexam.com/newlogin",
      "https://monitorexam.net/newiregister"];
    const mMsgElement = document.querySelector('span#mMsg');

    var request = new PresentationRequest(presUrls);
    this.state.isMirroringOn = request.getAvailability().then(function (availability) {
      // availability.value may be kept up-to-date by the controlling UA as long
      // as the availability object is alive. It is advised for the web developers
      // to discard the object as soon as it's not needed.
      console.log(availability.value);
      mMsgElement.innerHTML = availability.value ? "Mirroring" : "Not Mirroring";
      availability.onChange = this.handleAvailabilityChange;
      return availability.value;

    }).catch(function () {
      // Availability monitoring is not supported by the platform, so discovery of
      // presentation displays will happen only after request.start() is called.
      // Pretend the devices are available for simplicity; or, one could implement
      // a third state for the button.
      //this.handleAvailabilityChange(true);
      console.log("getAvailability returned error");
    });
  }
  // Encode an ArrayBuffer into a base64 string.
  // TODO: To verify this
  bufferEncode(value) {
    // return Buffer.from(value).toString('base64');
    return Base64js.fromByteArray(value)
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=/g, "");
  }

  componentWillMount() {
    this.state.WebAuthnSupport = (window.PublicKeyCredential === undefined)
    console.log("Webauthn Support is " + !this.state.WebAuthnSupport);
    const user = JSON.parse(localStorage.getItem('user'));
    const exam = JSON.parse(localStorage.getItem('exam1'));
    if (user)
      this.state.user = user;
    else if (this.props.user)
      this.state.user = this.props.user;
    else
      console.log("User not available");

    this.setState({
      status: true,
      testStarted: false,
      camera_state: this.state.user.camera_state,
      network_state: this.state.user.network_state,
      battery_state: this.state.user.battery_state,
      battery_level: this.state.user.battery_level,
      cred: this.state.user.cred,
      ip: this.state.user.ip,
      captured: this.state.user.captured,
      live_pic: this.state.user.live_pic,
      reg_pic: this.state.user.reg_pic
    });


  }
  updateState = (event) => {
    //cred is a credibility score
    const step3 = document.getElementById("step3");
    //const listep3=document.getElementById("listep3");
    //const countstep3=document.getElementById("countstep3");
    var status, cred = 0;
    Http.defaults.headers.common['Authorization'] = this.state.user.token;
    console.log(document.hidden || document.msHidden || document.webkitHidden);
    console.log(document.webkitVisibilityState);
    this.isMirroringOn();
    //this.state.user=this.props.user;
    if (this.state.cred == 0) {
      if (this.state.user.cred !== undefined)
        cred = this.state.user.cred;
      //console.log("Cred not yet defined");
    }
    else
      cred = this.state.cred;
    if (document.hidden || document.webkitVisibilityState != "visible" && this.state.status == "Present") {
      status = "Distracted";
      cred = cred - 1;
    }
    else if (this.state.status == "Away") {
      status = "Away";
      cred = cred - 1;
    }
    else {
      status = "Present";
      cred = cred + 4;
    }
    //  this.environmentCheck();
    //Initialize test variables for user    
    cred = 0;
    status = "Verifying";
    this.state.user.status = status;
    this.state.user.cred = cred;
    this.setState({ cred: cred });
    const last_login = new Date().toISOString().slice(0, 19).replace('T', ' ');
    this.setState({ last_login: last_login });
    this.state.user.last_login = last_login;
    this.state.user.network_state = this.state.network_state;
    this.state.user.battery_state = this.state.battery_state;
    this.state.user.battery_level = this.state.battery_level;
    this.state.user.camera_state = this.state.camera_state;
    this.state.user.ip = this.state.ip;
    this.state.user.live_pic = this.state.live_pic;
    localStorage.setItem('user', JSON.stringify(this.state.user));

    //separete handling for user having a registered pic and not
    console.log(this.state.user.live_pic);
    if (this.state.user.live_pic || this.state.live_pic) {
      Http.put('user-api/user/' + this.state.user.id, {
        "camera_state": this.state.user.camera_state,
        "network_state": this.state.network_state,
        "battery_state": this.state.battery_state,
        "battery_level": this.state.battery_level.toString(),
        "status": status,
        "ip": this.state.ip,
        "lat": this.state.lat,
        "lng": this.state.lng,
        "last_login": last_login,
        "live_pic": this.state.user.live_pic,
        "cred": cred
      }, {
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(res => {
          console.log("User updated Successfully");
        });
    }/* else {
          Http.put('user-api/user/' + this.props.user.id, {
                  "camera_state": this.state.camera_state,
                  "network_state": this.state.network_state,
                  "battery_state": this.state.battery_state,
                  "battery_level": this.state.battery_level.toString(),
                  "status": status,
                  "ip": this.state.ip,
                  "lat": this.state.lat,
                  "lng": this.state.lng,
                  "last_login": last_login,
              }, {
                  headers: {
                      "Content-Type": "application/json"
                  }
              })
              .then(res => {
                  //console.log("User updated Successfully");
              })
  }*/
    //TODO: To append this admit card additon to previous update message. This line is giving error
  }
  preVerify = (event) => {
    this.setState({ status: "Verifying" });
    Http.defaults.headers.common['Authorization'] = this.state.user.token;
    Http.put('user-api/user/' + this.state.user.id, {
      "live_pic": this.state.user.live_pic,
      "status": this.state.status
    }, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        console.log("Pre-Exam Verification Complete");
      })
  }
  showPosition = (position) => {
    this.state.lat = position.coords.latitude;
    this.state.lng = position.coords.longitude;
  }

  //TODO This function is never called
  async environmentCheck() {
    const video = document.getElementById('video');
    const errorMsgElement = document.querySelector('span#errorMsg');
    const cameraMsgElement = document.querySelector('span#cameraMsg');
    const nwMsgElement = document.querySelector('span#nwMsg');
    const baMsgElement = document.querySelector('span#baMsg');
    const ipMsgElement = document.querySelector('span#ipMsg');
    const mMsgElement = document.querySelector('span#mMsg');
    //Location
    navigator.geolocation.getCurrentPosition(this.showPosition);
    //IP
    Http.get('https://api.ipify.org?format=json')
      .then(res => {
        const ipnew = res.data.ip;
        console.log(ipnew);
        this.setState({
          ip: ipnew
        });
        ipMsgElement.innerHTML = ` ` + " " + this.state.ip;
      });
    this.setState({
      network_state: navigator.onLine
    });
    //Network
    if (navigator.onLine)
      nwMsgElement.innerHTML = `  &#10003 Network`;
    else
      nwMsgElement.innerHTML = `  &#10005`;
    var connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    connection.onChange = this.updateState;
    if (connection) {
      var speed = `None`;
      switch (connection.effectiveType) {
        case "4g":
          speed = "good";
          break;
        case "3g":
          speed = "fair";
          break;
        case "2g":
          speed = "slow";
          break;
        case "slow-2g":
          speed = "slow";
          break;
      }
      nwMsgElement.innerHTML = `Network speed is ` + connection.downlink + "Mbps";
    }
    //var battery=navigator.battery||navigator.mozBattery||navigator.webkitBattery;
    //Battery
    if (navigator.getBattery) {
      var level = navigator.getBattery().then(function (battery) {
        baMsgElement.innerHTML = ` &#10003  level ` + battery.level * 100 + '% ' + (battery.charging ? 'Charging' : 'Not Charging')
        //baMsgElement.innerHTML += `<progress value=` + battery.level * 100 + ` max="100" style={{color:"#6576ff"}} width=250px height=25px>%</progress>`;
        return battery.level * 100;
      }).then((result) => this.setState({ battery_state: true, battery_level: result }));
    } else {
      this.setState({
        battery_state: false
      });
      baMsgElement.innerHTML = ` &#10005`
    }

    //Bluetooth
    /*
    navigator.bluetooth.requestDevice({
            acceptAllDevices: true
        })
        .then(function(device) {
            console.log(device.name);
        });
    */
    const constraints = {
      audio: false,
      video: {
        width: 320,
        height: 180
      }
    };

    //Camera
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      window.stream = stream;
      video.srcObject = stream;
      this.setState({
        camera_state: true
      });
      cameraMsgElement.innerHTML = ` &#10003`;
    } catch (e) {
      this.setState({
        camera_state: false
      });
      cameraMsgElement.innerHTML = ` &#10005`;
      errorMsgElement.innerHTML = `navigator.getUserMedia error:${e.toString()}`;
    }
    //Monitor
    this.isMirroringOn();
    //TODO: Desktop
    //Image Capture
    //Voice Capture .slice(0, 19).replace('T', ' ')
  }
  //Will be called post rendering
  async componentDidMount() {
    const video = document.getElementById('video');
    const errorMsgElement = document.querySelector('span#errorMsg');
    const cameraMsgElement = document.querySelector('span#cameraMsg');
    const nwMsgElement = document.querySelector('span#nwMsg');
    const ipMsgElement = document.querySelector('span#ipMsg');
    const baMsgElement = document.querySelector('span#baMsg');

    //data received from profile component
    this.setState({
      status: "",
      testStarted: false,
      camera_state: this.state.user.camera_state,
      network_state: this.state.user.network_state,
      battery_state: this.state.user.battery_state,
      battery_level: this.state.user.battery_level,
      cred: this.state.user.cred,
      ip: this.state.user.ip,
      captured: this.state.user.captured,
      live_pic: this.state.user.live_pic,
      reg_pic: this.state.user.reg_pic
    });
    //camera
    const constraints = {
      audio: false,
      video: {
        width: 320,
        height: 180
      }
    };



    //
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints) || navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia;;
      ;
      window.stream = stream;
      video.srcObject = stream;
      video.play();
      this.setState({
        camera_state: true
      });
      cameraMsgElement.innerHTML = ` &#10003`;
    } catch (e) {
      this.setState({
        camera_state: false
      });
      cameraMsgElement.innerHTML = ` &#10005`;
      errorMsgElement.innerHTML = `Camera error:${e.toString()}`;
    }


    this.isMirroringOn();


    //event connection change
    var connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    connection.onChange = this.updateState;
    //location TODO should there be a position change check?
    navigator.geolocation.getCurrentPosition(this.showPosition);

    this.setState({
      network_state: navigator.onLine
    });

    //connection
    if (navigator.onLine)
      nwMsgElement.innerHTML = `  &#10003 Network`;
    else
      nwMsgElement.innerHTML = `  &#10005`;

    if (connection) {
      var speed = `None`;
      switch (connection.effectiveType) {
        case "4g":
          speed = "good";
          break;
        case "3g":
          speed = "fair";
          break;
        case "2g":
          speed = "slow";
          break;
        case "slow-2g":
          speed = "slow";
          break;
      }
      nwMsgElement.innerHTML = `Network speed is ` + connection.downlink + "Mbps";
    }

    //var battery=navigator.battery||navigator.mozBattery||navigator.webkitBattery;
    if (navigator.getBattery) {
      var level = navigator.getBattery().then(function (battery) {
        baMsgElement.innerHTML = ` &#10003  level ` + battery.level * 100 + '% ' + (battery.charging ? 'Charging' : 'Not Charging')
        //baMsgElement.innerHTML += `<progress value=` + battery.level * 100 + ` max="100" style={{color:"#6576ff"}} width=250px height=25px>%</progress>`;
        if (battery.level * 100 < 15 && battery.charging) alert("Battery level <15%. Please charge your battery");
        return battery.level * 100;
      }).then((result) => this.setState({ battery_state: true, battery_level: result }));
    } else {
      this.setState({
        battery_state: false
      });
      baMsgElement.innerHTML = ` &#10005`
    }
    //bluetooth
    navigator.bluetooth.requestDevice({
      acceptAllDevices: true
    })
      .then(function (device) {
        //TODO: to send device names to backend
        console.log(device.name);
      });
    //click a picture live_pic
    //this.handleCapture();
    //TODO may need to add some delay here

    Http.get('https://api.ipify.org?format=json')
      .then(res => {
        const ipnew = res.data.ip;
        console.log(ipnew);
        this.setState({
          ip: ipnew
        });

        ipMsgElement.innerHTML = ` ` + this.state.ip;
        //if we put this anywhere else ip address will not go
        this.updateState();
      });

    /*
    continuous auto update not needed anymore
      //update the state of the user every 30 seconds
        this.timerID = setInterval(() => {
            this.updateState();
        }, 30000);
        this.timerCap = setInterval(() => {
        this.handleCapture();
        }, 51000);
        */
  }
  //Clear the timerID set in ComponentDidUpdate test whether this works or not
  componentWillUnmount() {
  }
  /*
    async componentDidMount() {
        const video = document.getElementById('video');
        const errorMsgElement = document.querySelector('span#errorMsg');
        

        
        const constraints = {
            audio: false,
            video: {
                width: 320,
                height: 180
            }
        };
        try {
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            window.stream = stream;
            video.srcObject = stream;
      //Will click image once after 5 minutes
    //TODO: May have to remove later
            setTimeout(() => {this.handleCapture() }, 2000);
            this.state.user.camera_state=true;
        } catch (e) {
            console.log(`navigator.getUserMedia error:${e.toString()}`);
            if(`navigator.getUserMedia error:${e.toString()}`.includes("Requested device not found"))
            {
                 errorMsgElement.innerHTML = "Please connect a camera to the computer";
            }
            this.state.user.camera_state=false;
        }
    }
  //Upload the pic of the user
    uploadPic = (e) => {
        const errorMsgElement = document.querySelector('span#errorMsg');

        var data = new FormData();
        this.setState({
            file: URL.createObjectURL(e.target.files[0])
        });
        data.append('file', e.target.files[0]);
        Http.post('user-api/usera', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(res => {
            errorMsgElement.innerHTML = res.data;
        if(res.data.includes('not'))
                          errorMsgElement.innerHTML = "Photo Verification Failed";
        else
                          errorMsgElement.innerHTML = "Photo Verified";
            })
            .catch(function(error) {
                console.log(error);
            });

    }*/

  //upload a file code and save the link in DB
  uploadAdmitCard = (e) => {
    const errorMsgElement = document.querySelector('span#errorMsg');
  }
  /*updateState = (isAway) => {
	
      Http.defaults.headers.common['Authorization'] = this.state.user.token;
      Http.put('user-api/user/' + this.state.user.id, {
              "admit_card": this.state.fileAdmit,
              "status": this.state.status,
          }, {
              headers: {
                  "Content-Type": "application/json"
              }
          })
          .then(res => {
              //console.log("User updated Successfully");
          })
  }*/

  bufferDecode(value) {
    return Uint8Array.from(atob(value), c => c.charCodeAt(0));
  }

  // This should be used to verify the auth data with the server
  // step 5 of the mozilla doc
  registerNewCredential(newCredential) {
    // Move data into Arrays incase it is super long
    let attestationObject = new Uint8Array(newCredential.response.attestationObject);
    let clientDataJSON = new Uint8Array(newCredential.response.clientDataJSON);
    //globally unique identifier of the user
    let rawId = new Uint8Array(newCredential.rawId);
    rawId = this.bufferEncode(rawId);

    Http.post('makeCredential', {
      id: newCredential.id,
      rawId: rawId,
      type: newCredential.type,
      response: {
        attestationObject: this.bufferEncode(attestationObject),
        clientDataJSON: this.bufferEncode(clientDataJSON),
      },
    })
      .then(res => {
        console.log(res);
      })
      .catch(error => {
        console.log(error);
      });
  }
  async handleClick() {
    const step1 = document.getElementById("step1");
    const listep1 = document.getElementById("listep1");
    const countstep1 = document.getElementById("countstep1");
    const step2 = document.getElementById("step2");
    const listep2 = document.getElementById("listep2");
    const countstep2 = document.getElementById("countstep2");
    const step3 = document.getElementById("step3");
    const listep3 = document.getElementById("listep3");
    const countstep3 = document.getElementById("countstep3");
    const errorMsgElement = document.querySelector('span#errorMsg');

    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
      'Access-Control-Allow-Headers': 'Origin,X-Requested-With,Content-Type,Accept,Authorization',
    };
/******** Commenting FIDO start ********/
    const url = 'makeCredential/'
      + this.state.user.email
	+'?attType=direct&authType=&userVerification=required&residentKeyRequirement=false&txAuthExtension=';
    Http.get(url, { headers })
      .then(makeCredentialOptions => {
        makeCredentialOptions.data.publicKey.challenge = this.bufferDecode(makeCredentialOptions.data.publicKey.challenge);
        makeCredentialOptions.data.publicKey.user.id = this.bufferDecode(makeCredentialOptions.data.publicKey.user.id);
        if (makeCredentialOptions.data.publicKey.excludeCredentials) {
          for (var i = 0; i < makeCredentialOptions.data.publicKey.excludeCredentials.length; i++) {
            makeCredentialOptions.data.publicKey.excludeCredentials[i].id = this.bufferDecode(makeCredentialOptions.data.publicKey.excludeCredentials[i].id);
          }
        }
        console.log("Credential Creation Options");
        console.log(makeCredentialOptions.data);
        navigator.credentials.create({
          publicKey: makeCredentialOptions.data.publicKey
        }).then(newCredential => {
          errorMsgElement.innerHTML = "Security Key Added Successfully";
          console.log("PublicKeyCredential Created");
          console.log(newCredential);
          this.state.createResponse = newCredential;
          this.registerNewCredential(newCredential);
          this.state.user.fido_auth_enabled = true;
        }).catch(err => {
          console.log(err);
        });
      });
    Http.defaults.headers.common['Authorization'] = this.state.user.token;
    Http.put('user-api/user/' + this.state.user.id, {
      "fido_auth_enabled": true,
    }, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        console.log("User updated Successfully");
      })
      .catch(function (error) {
        errorMsgElement.innerHTML = "Failed to Add Security Key";
	alert("Failed to add Security Key");
        console.log(error);
      });
      
	  /********* Commenting FIDO End********/
    //TODO: make credential will use the value entered by the user
    /*	         step2.classList.add("noactive");
               listep2.classList.remove("active");
               countstep2.classList.remove("active-num");
               step3.classList.remove("noactive");
               listep3.classList.add("active");
               countstep3.classList.add("active-num");
              console.log("form submitted");*/
  }
  // Encode an ArrayBuffer into a base64 string.
  // TODO: To verify this
  bufferEncode(value) {
    // return Buffer.from(value).toString('base64');
    return Base64js.fromByteArray(value)
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=/g, "");
  }
  handleCapture = (event) => {
    const video = document.getElementById('video');
    const canvas = document.getElementById('canvas');
    const snap = document.getElementById("snap");
    const errorMsgElement = document.querySelector('span#errorMsg');
    const timeMsgElement = document.querySelector('span#timeMsg');
    const filename = document.querySelector('input#filename');
    const fname = document.querySelector('input#fname');
    const status = document.querySelector('input#status');
    const step1 = document.getElementById("step1");
    //const listep1=document.getElementById("listep1");
    //const countstep1=document.getElementById("countstep1");
    const step2 = document.getElementById("step2");
    const envstep2 = document.getElementById("envstep2");
    //const countstep2=document.getElementById("countstep2");
    const step3 = document.getElementById("step3");
    const wait = document.getElementById("wait");
    //const listep3=document.getElementById("listep3");
    //const countstep3=document.getElementById("countstep3");
    this.handleClick();
    this.setState({
      captured: true
    });
    // Draw image
    var context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, 320, 240);
    var blob, url;


    fname.value = "U" + this.state.user.id.toString() + "_entry";
    console.log(fname.value);

    //const url= canvas.toDataURL('image/png').replace('image/png','image/octet-stream');
    canvas.toBlob(function (blob) {
      var newImg = document.getElementById('imageSrc'),
        url = URL.createObjectURL(blob);
      //console.log(url);
      //console.log(blob);
      newImg.src = url;

      var data = new FormData();
      var file = new File([blob], fname.value);
      //var file = new File([blob],filename.value);
      //console.log(file);
      data.append('file', file);
      if (blob) {
        Http.post('user-api/usera', data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(res => {
            errorMsgElement.innerHTML = res.data;
            filename.value = res.data.split(' ', 1);
            if (res.data.includes('not')) {
              //status.value="Away";
              errorMsgElement.innerHTML = "Photo Verification Failed. Click again";
              status.value = "Invalid Photo";
            }
            else {
              //status.value="Present";
              errorMsgElement.innerHTML = "Photo Valid" + String.fromCharCode("10003");
              status.value = "Photo Valid";

            }
            step2.classList.remove("noactive");
            step2.classList.add("active");
            envstep2.classList.add("active");
            envstep2.classList.remove("noactive");
            wait.classList.remove("noactive");
            wait.classList.add("active");
          })
          .catch(function (error) {
            errorMsgElement.innerHTML = "Failed to save Photo";
            status.value = "Failed to save Photo";
            console.log(error);
          });
      }
      //this.setState({profilePic:blob});         
    });
    var count = 6;
    var t30 = setInterval(() => { count = count - 1; timeMsgElement.innerHTML = "Verifying ..." + count + " seconds left"; if (count <= 0) clearInterval(t30); }, 1000);

    //Wait for 5 seconds to open verify screen
    setTimeout(() => {
      //if(status.value==("Photo Valid"+String.fromCharCode("10003")))
      if (filename.value) {
        this.state.file = filename.value;
        this.state.user.live_pic = filename.value;
        this.state.user.status = status.value;
        console.log(this.state.file);
        console.log(this.state.user.status);
        //ReactDOM.render(<VerifyStudent user={this.state.user} />,document.getElementById("verify"));
      }
      else {
        this.state.user.status = "Not Verified";
      }
      //const step3=document.getElementById("step3");
      //step3.classList.remove("noactive");
      //step3.classList.add("active");
    }, 5000);

    //TODO: The code below is currently assumed dead. Need to remove this
    /*if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(canvas.msToBlob(), "pic" + this.state.user.id);
    } else {}*/
  }
  onOpenCvReady() {
    document.getElementById('errorMsg').innerHTML = 'OpenCV.js is ready.';
    console.log("opencv is ready");
  }

  toggleUserMenu() {
    this.setState({ userMenu: !this.state.userMenu });
  }
  toggleMenu() {
    this.setState({ menu: !this.state.menu });
  }
  toLocal = (utctime) => { var x = new Date(Date.parse("1970-01-01T" + utctime.toString() + "Z")); x += this.state.user.timezone * 60000; return (x.toLocaleString().slice(16, 24)); }
  //standard function. Assign value to name
  handleInput = (event) => {
    event.preventDefault();
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({
      [nam]: val
    });
  }
  render() {
    const show = this.state.menu ? "active" : "";
    const showMenu = this.state.menu ? "content-active" : "";
    const usershow = this.state.userMenu ? "show" : "";
    if (this.state.status == "Verifying")
      return (
        <div>
          <meta charSet="utf-8" />
          <meta name="author" content="Softnio" />
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          <meta name="description" content="@@page-discription" />
          {/* Fav Icon  */}
          <link rel="shortcut icon" href="images/favicon.png" />
          {/* Page Title  */}
          <title>Pre Verification Room | Monitor Exam</title>
          {/* StyleSheets  */}
          <link rel="stylesheet" href="assets/css/style.css" />
          <link rel="stylesheet" href="assets/css/custom.css" />
          <link id="skin-default" rel="stylesheet" href="assets/css/theme.css?ver=1.2.0" />
          <div className="nk-app-root">
            {/* main @s */}
            <div className="nk-main ">
              {/* wrap @s */}
              <div className="nk-wrap ">
                <div className="nk-header nk-header-fixed is-light">
                  <div className="container-lg wide-xl">
                    <div className="nk-header-wrap">
                      <div className="nk-header-brand">
                        <a href="/" className="logo-link">
                          <img className="logo-dark logo-img" src="images/monitor-exam-logo.png" srcSet="images/monitor-exam-logo-2x.png 2x" alt="logo-dark" />
                        </a>
                      </div>{/* .nk-header-brand */}
                      <div className="nk-header-tools">
                        <ul className="nk-quick-nav">
                          <li onClick={this.toggleUserMenu} className={"dropdown user-dropdown " + usershow}>
                            <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded={this.state.userMenu}>
                              <div className="user-toggle">
                                <div className="user-avatar sm">
                                <img src={'https://d1bumj8k6ax755.cloudfront.net/uploads/' + this.state.user.reg_pic.split("'")[1]} class="regpic " alt="reg" style={{ transform: "scale(0.5)", left: "6px" }} />
                                  <em className="icon ni ni-user-alt" />
                                </div>
                                <div className="user-name dropdown-indicator d-none d-sm-block">{this.state.user.first_name} {this.state.user.last_name}</div>
                              </div>
                            </a>
                            <div className={"dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 " + usershow}>
                              <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                <div className="user-card">
                                  <div className="user-avatar">
                                    <span>AB</span>
                                  </div>
                                  <div className="user-info">
                                    <span className="lead-text">{this.state.user.first_name} {this.state.user.last_name}</span>
                                    <span className="sub-text">{this.state.user.email}</span>
                                  </div>
                                  <div className="user-action">
                                    <a className="btn btn-icon mr-n2" href="#"><em className="icon ni ni-setting" /></a>
                                  </div>
                                </div>
                              </div>
                              <div className="dropdown-inner">
                                <ul className="link-list">
                                  <li><a href="\viewprofile"><em className="icon ni ni-user-alt" /><span>View Profile</span></a></li>
                                  <li><a href="/security"><em className="icon ni ni-setting-alt" /><span>Security Setting</span></a></li>
                                  <li><a href="#"><em className="icon ni ni-activity-alt" /><span>Login Activity</span></a></li>
                                </ul>
                              </div>
                              <div className="dropdown-inner">
                                <ul className="link-list">
                                  <li><a href="\newlogin"><em className="icon ni ni-signout" /><span onClick={() => localStorage.clear()}>Sign Out</span></a></li>
                                </ul>
                              </div>
                            </div>
                          </li>{/* .dropdown */}
                          <li onClick={this.toggleMenu} className="d-lg-none">
                            <a href="#" className={"toggle nk-quick-nav-icon mr-n1 " + this.state.menu ? "active" : ""} data-target="sideNav"><em className="icon ni ni-menu" /></a>
                          </li>
                        </ul>{/* .nk-quick-nav */}
                      </div>{/* .nk-header-tools */}
                    </div>{/* .nk-header-wrap */}
                  </div>{/* .container-fliud */}
                </div>
                {/* main header @e */}
                {/* content @s */}
                <div className="nk-content">
                  <div className="container wide-xl">
                    <div className="nk-content-inner">
                      <div className={"nk-aside " + showMenu} data-content="sideNav" data-toggle-overlay="true" data-toggle-screen="lg" data-toggle-body="true">
                        <div className="nk-sidebar-menu" data-simplebar>
                          {/* Menu */}
                          <ul className="nk-menu">
                            <li className="nk-menu-heading">
                              <h6 className="overline-title">Menu</h6>
                            </li>
                            <li className={"nk-menu-item " + ((this.state.user.role == "invig") ? "active" : "noactive")}>
                              <a href="/newdashboard" className="nk-menu-link">
                                <span className="nk-menu-icon"><em className="icon ni ni-users" /></span>
                                <span className="nk-menu-text">Student DashBoard</span>
                              </a>
                            <ul className="nk-menu-sub">
                              <li className="nk-menu-item "><a href="\livestudentdash" className="nk-menu-link"><span className="nk-menu-text">Live Students</span></a></li>
                              <li className="nk-menu-item "><a href="\newdashboard" className="nk-menu-link"><span className="nk-menu-text">All Students</span></a></li>
		            </ul>
                            </li>
                            <li className="nk-menu-item active">
                              <a href="/newexamdash" className="nk-menu-link">
                                <span className="nk-menu-icon"><em className="icon ni ni-file-docs" /></span>
                                <span className="nk-menu-text">Exam Dashboard</span>
                              </a>
                              <ul className="nk-menu-sub">
                                <li className="nk-menu-item"><a href="\newexamdash#ongoing" className="nk-menu-link"><span className="nk-menu-text">Ongoing Exams</span></a></li>
                                <li className="nk-menu-item"><a href="\newexamdash#upcoming" className="nk-menu-link"><span className="nk-menu-text">Upcoming Exams</span></a></li>
                                <li className="nk-menu-item"><a href="\newexamdash#unscheduled" className="nk-menu-link"><span className="nk-menu-text">Unscheduled Exams</span></a></li>
                                <li className="nk-menu-item"><a href="\newexamdash#other" className="nk-menu-link"><span className="nk-menu-text">Past Exams </span></a></li>
                              </ul>
                            </li>
                            <li className="nk-menu-item">
                              <a href="\newlogin" className="nk-menu-link">
                                <span className="nk-menu-icon"><em className="icon ni ni-signout" /></span>
                                <span onClick={() => localStorage.clear()} className="nk-menu-text">Log Out</span>
                              </a>
                            </li>
                          </ul>
                        </div>{/* .nk-sidebar-menu */}
                        <div className="nk-aside-close">
                          <a href="#" className="toggle" data-target="sideNav"><em className="icon ni ni-cross" /></a>
                        </div>{/* .nk-aside-close */}
                      </div>{/* .nk-aside */}
                      <div className="nk-content-body">
                        <div className="nk-content-wrap">
                          <div className="nk-block-head nk-block-head-lg">
                            <div className="nk-block-between-md g-4">
                              <div className="nk-block-head-content">
                                <h5 className="nk-block-title pt-100">Thanks for accepting MonitorExam's Privacy Policy. </h5>
                                <a id="wait" href="/newexamdash" className="text-white btn btn-secondary mb-3 mt-3 ">Go to Exam Dashboard</a>
                              </div>
                              {/* content @e */}
                            </div>
                            {/* wrap @e */}
                          </div>
                          {/* main @e */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* app-root @e */}
          {/* JavaScript */}
        </div>);
    else
      return (
        <div>
          <meta charSet="utf-8" />
          <meta name="author" content="Softnio" />
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          <meta name="description" content="@@page-discription" />
          {/* Fav Icon  */}
          <link rel="shortcut icon" href="images/favicon.png" />
          {/* Page Title  */}
          <title>Pre Verification Room | Monitor Exam</title>
          {/* StyleSheets  */}
          <link rel="stylesheet" href="assets/css/style.css" />
          <link rel="stylesheet" href="assets/css/custom.css" />
          <link id="skin-default" rel="stylesheet" href="assets/css/theme.css?ver=1.2.0" />
          <div className="nk-app-root">
            {/* main @s */}
            <div className="nk-main ">
              {/* wrap @s */}
              <div className="nk-wrap ">
                <div className="nk-header nk-header-fixed is-light">
                  <div className="container-lg wide-xl">
                    <div className="nk-header-wrap">
                      <div className="nk-header-brand">
                        <a href="/" className="logo-link">
                          <img className="logo-dark logo-img" src="images/monitor-exam-logo.png" srcSet="images/monitor-exam-logo-2x.png 2x" alt="logo-dark" />
                        </a>
                      </div>{/* .nk-header-brand */}
                      <div className="nk-header-tools">
                        <ul className="nk-quick-nav">
                          <li onClick={this.toggleUserMenu} className={"dropdown user-dropdown " + usershow}>
                            <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded={this.state.userMenu}>
                              <div className="user-toggle">
                                <div className="user-avatar sm">
                                  <em className="icon ni ni-user-alt" />
                                </div>
                                <div className="user-name dropdown-indicator d-none d-sm-block">{this.state.user.first_name} {this.state.user.last_name}</div>
                              </div>
                            </a>
                            <div className={"dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 " + usershow}>
                              <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                <div className="user-card">
                                  <div className="user-avatar">
                                    <span>AB</span>
                                  </div>
                                  <div className="user-info">
                                    <span className="lead-text">{this.state.user.first_name} {this.state.user.last_name}</span>
                                    <span className="sub-text">{this.state.user.email}</span>
                                  </div>
                                  <div className="user-action">
                                    <a className="btn btn-icon mr-n2" href="#"><em className="icon ni ni-setting" /></a>
                                  </div>
                                </div>
                              </div>
                              <div className="dropdown-inner">
                                <ul className="link-list">
                                  <li><a href="\viewprofile"><em className="icon ni ni-user-alt" /><span>View Profile</span></a></li>
                                  <li><a href="/security"><em className="icon ni ni-setting-alt" /><span>Security Setting</span></a></li>
                                  <li><a href="#"><em className="icon ni ni-activity-alt" /><span>Login Activity</span></a></li>
                                </ul>
                              </div>
                              <div className="dropdown-inner">
                                <ul className="link-list">
                                  <li><a href="\newlogin"><em className="icon ni ni-signout" /><span onClick={() => localStorage.clear()}>Sign Out</span></a></li>
                                </ul>
                              </div>
                            </div>
                          </li>{/* .dropdown */}
                          <li onClick={this.toggleMenu} className="d-lg-none">
                            <a href="#" className={"toggle nk-quick-nav-icon mr-n1 " + this.state.menu ? "active" : ""} data-target="sideNav"><em className="icon ni ni-menu" /></a>
                          </li>
                        </ul>{/* .nk-quick-nav */}
                      </div>{/* .nk-header-tools */}
                    </div>{/* .nk-header-wrap */}
                  </div>{/* .container-fliud */}
                </div>
                {/* main header @e */}
                {/* content @s */}
                <div className="nk-content">
                  <div className="container wide-xl">
                    <div className="nk-content-inner">
                      <div className={"nk-aside " + showMenu} data-content="sideNav" data-toggle-overlay="true" data-toggle-screen="lg" data-toggle-body="true">
                        <div className="nk-sidebar-menu" data-simplebar>
                          {/* Menu */}
                          <ul className="nk-menu">
                            <li className="nk-menu-heading">
                              <h6 className="overline-title">Menu</h6>
                            </li>
                            <li className={"nk-menu-item " + ((this.state.user.role == "invig") ? "active" : "noactive")}>
                              <a href="/newdashboard" className="nk-menu-link">
                                <span className="nk-menu-icon"><em className="icon ni ni-users" /></span>
                                <span className="nk-menu-text">Student DashBoard</span>
                              </a>
                            </li>
                            <li className="nk-menu-item active">
                              <a href="/newexamdash" className="nk-menu-link">
                                <span className="nk-menu-icon"><em className="icon ni ni-file-docs" /></span>
                                <span className="nk-menu-text">Exam Dashboard</span>
                              </a>
                              <ul className="nk-menu-sub">
                                <li className="nk-menu-item"><a href="\newexamdash#ongoing" className="nk-menu-link"><span className="nk-menu-text">Ongoing Exams</span></a></li>
                                <li className="nk-menu-item"><a href="\newexamdash#upcoming" className="nk-menu-link"><span className="nk-menu-text">Upcoming Exams</span></a></li>
                                <li className="nk-menu-item"><a href="\newexamdash#unscheduled" className="nk-menu-link"><span className="nk-menu-text">Unscheduled Exams</span></a></li>
                                <li className="nk-menu-item"><a href="\newexamdash#other" className="nk-menu-link"><span className="nk-menu-text">Past Exams </span></a></li>
                              </ul>
                            </li>
                            <li className="nk-menu-item">
                              <a href="\newlogin" className="nk-menu-link">
                                <span className="nk-menu-icon"><em className="icon ni ni-signout" /></span>
                                <span onClick={() => localStorage.clear()} className="nk-menu-text">Log Out</span>
                              </a>
                            </li>
                          </ul>
                        </div>{/* .nk-sidebar-menu */}
                        <div className="nk-aside-close">
                          <a href="#" className="toggle" data-target="sideNav"><em className="icon ni ni-cross" /></a>
                        </div>{/* .nk-aside-close */}
                      </div>{/* .nk-aside */}
                      <div className="nk-content-body">
                        <div className="nk-content-wrap">
                          <div className="nk-block-head nk-block-head-lg">
                            <div className="nk-block-between-md g-4">
                              <div className="nk-block-head-content">
                                <h2 className="nk-block-title">Pre-Exam Verification</h2>
                                <div className="nk-block-des">
                                  <p>Complete your Pre-Exam Verification in 2 simple steps</p>
                                </div>
                              </div>
                              <div className="nk-block-head-content">
                                <ul className="nk-block-tools gx-3">
                                  <li />
                                </ul>
                              </div>
                            </div>
                          </div>{/* .nk-block-head */}
                          <div className="nk-block">
                            <div className="card card-bordered verification-box">
                              <h4 className="text-primary text-center mb-4">Pre-Exam Verification Room</h4>
                              <div className="row step-card">
                                <div id="step1" className="col-sm-6 step-1 active">
                                  <div className="card card-bordered p-2 mb-2 ">
                                    <span className="text-center mb-2">Step 1: Check your Camera</span>
                                    {/*<div><button id="startbutton" class="btn btn-lg btn-primary btn-block">Capture</button></div>*/}
                                    <input type="hidden" name="filename" id="filename" />
                                    <input type="hidden" name="fname" id="fname" />
                                    <img src={this.state.file} id='imageSrc' style={{ height: '320px', width: '240px', border: 'none', draggable: 'true', backgroundColor: 'lightGray', display: 'none' }}></img >
                                    <div className="video-wrap" >
                                      <video id="video" width="320px" height="240px" style={{ display: this.state.captured ? "none" : "block" }} playsInline autoPlay > < /video>
                                        <canvas id="canvas" width="320px" height="240px" style={{ display: this.state.captured ? "block" : "none" }} > < /canvas>
                                          <input type="text" name="status" id="status" readonly />
                                        </div>
                                        <Button id="snap" className="btn-block" style={{ backgroundColor: "#6576ff", paddingTop: "10px", border: "none", width: "-webkit-fill-available", boundary: "none", margin: '2px' }} variant="primary" type='submit' placeholder='Capture' size="md" required onClick={this.handleCapture} > Register Device < /Button>
                                          <p> Please note you will be able to login using only this device</p>
                                          <span className="badge badge-dim badge-outline-success auth-animated-alert">Please wait, It takes 5 seconds to verify</span>
                                          {/*<a href="#" class="btn btn-primary btn-block mb-2">Capture</a>
                                                        <img src="images/avatar/b-sm.jpg" class="mb-2">
                                                        <span class="badge badge-simple text-center">Photo Valid</span>*/}
                                        </div>
                                    </div>
                                    <div id="step2" className="col-sm-6 step-2 verify-envireonment noactive">
                                      <div className="card card-bordered p-2 mb-2">
                                        <span className="text-center mb-2">Step 2: Verify Enviroment</span>
                                        <p> <span id="timeMsg"> </span></p >
                                        <iframe src={
                                          "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14033.35944807659!2d" + this.state.lng + "!3d" + this.state.lat + "!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1606743989630!5m2!1sen!2sin"
                                        } width="320px" height="240px" frameborder="0" style={{ border: "0" }}>
                                        </iframe>
                                        <div id="envstep2" className="environment-info noactive">
                                          <p><em className="icon ni ni-camera text-primary" /> .... <span id="cameraMsg" className="ok status"><em className="icon ni ni-check" /></span> </p>
                                          <p><em className="icon ni ni-spark text-primary" /> .... <span id="baMsg" className="ok status"><em className="icon ni ni-check" /></span> <span className="status"></span></p>
                                          <p><em className="icon ni ni-speed text-primary" /> .... <span id="nwMsg" className="ok status"><em className="icon ni ni-check" /></span> <span className="status"></span></p>
                                          <p><em className="icon ni ni-location text-primary" /> .... <span id="ipMsg" className="ok status"><em className="icon ni ni-check" /></span> <span className="status"></span></p>
                                          <p><em className="icon ni ni-monitor text-primary" /> .... <span id="mMsg" className="ok status"><em className="icon ni ni-check" /></span> <span className="status"></span></p>

                                        </div>
                                        <a href="#envstep2" onClick={this.preVerify} className="btn btn-primary btn-block mb-2" id="update-state">Update State</a>
                                        <span id="errorMsg" className="badge badge-dim badge-outline-success auth-animated-alert-state">Please wait, It takes 5 seconds to verify</span>
                                      </div>
                                    </div>
                                    <div id="" className="col-sm-4 step-3 noactive">
                                      <div className="card card-bordered p-2">
                                        <span className="text-center mb-2">Step 3: Upload Admit Card</span>
                                        <div className="upload-zone noactive">
                                          <div className="dz-message" data-dz-message>
                                            <span className="dz-message-text">Drag and drop file</span>
                                            <span className="dz-message-or">or</span>
                                            <a className="btn btn-primary">SELECT</a>
                                          </div>
                                        </div>
                                        <img src={this.state.fileAdmit} accept="image/ * " id='imageAdmit' style={{ height: '240px', width: '320px', border: 'none', draggable: 'true', display: this.state.fileAdmit ? "block" : "none", backgroundColor: 'lightGray' }}></img>
                                        < hr />
                                        < object data={this.state.fileAdmit} type="application/pdf" width="320px" height="0px" >
                                          < p style={{ display: "none" }} > Alternative text - include a link < a href="myfile.pdf" > to the PDF! < /a></p >
                                          < /object>
                                          < label className="btn btn-secondary btn-md" id="snap" for="upload"
                                            style={
                                              {
                                                textAlign: "center",
                                                backgroundColor: "#6576ff",
                                                color: "white",
                                                align: "right",
                                                border: "none",
                                                borderRadius: "4px"
                                              }
                                            }
                                            size="md"
                                            required > Upload Admit Card < /label>
                                            <input id="upload" type='file' name='fileAdmit' disabled={!this.state.captured} accept="image/*" onChange={this.uploadAdmitCard} />
                                            <span className="badge badge-dim badge-outline-success auth-animated-alert-admit-card">Please wait, It takes 5 seconds to verify</span>
                                          </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-2">
                                        <input type="checkbox" name="consent" id="consent" required />
                                      </div>
                                      <div className="col-7">
                                        <label for="consent">I agree to be proctored by MonitorExam as per their <a href="/privacy" target="_blank"> Privacy Policy</a></label>
                                      </div>
                                      <div className="col-2">
                                        <a id="wait" onClick={this.preVerify} className="text-white btn btn-secondary mb-3 mt-3 ">Submit</a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* footer @e */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* content @e */}
                    </div>
                    {/* wrap @e */}
                  </div>
                  {/* main @e */}
                </div>
                {/* app-root @e */}
                {/* JavaScript */}
              </div>
              );
  }
}

              export default Confirm








