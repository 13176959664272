import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Http from 'axios'
import Profile from './Profile'
import {Redirect} from 'react-router-dom'
import Adapter from 'webrtc-adapter'
import NewExamDashboard from '../Dashboard/NewExamDashboard'
import Card from 'react-bootstrap/Card'
import mixpanel from 'mixpanel-browser'
import Base64js from 'base64-js'
class AisctAuth extends React.Component{
	constructor(){
		super();
		this.state={
			WebAuthnSupport:false,
		        first_name:'rajiv',
			file:"",captured:false,
			last_name:"",
			email:"she@email.com",
			mobile:"1111122222",
			id:1,
			password:"password",
			rePassword:"password",
			status:true,
			captured:false,
			otp:123,
			displayToast:true,
			button_state:"Capture"
		};
		this.handleCapture=this.handleCapture.bind(this);
		this.handleSubmit=this.handleSubmit.bind(this);
	        this.handleClick = this.handleClick.bind(this);

	}
	bufferDecode(value) {
	    return Uint8Array.from(atob(value), c => c.charCodeAt(0));
	}
    componentWillMount() {
        this.state.WebAuthnSupport=(window.PublicKeyCredential===undefined)
		console.log("Webauthn Support is "+ !this.state.WebAuthnSupport);
	}
	// This should be used to verify the auth data with the server
	// step 5 of the mozilla doc
	registerNewCredential(newCredential) {
	         // Move data into Arrays incase it is super long
	         let attestationObject = new Uint8Array(newCredential.response.attestationObject);
	         let clientDataJSON = new Uint8Array(newCredential.response.clientDataJSON);
		 //globally unique identifier of the user
	         let rawId = new Uint8Array(newCredential.rawId);
		 rawId = this.bufferEncode(rawId);
	
	         Http.post('makeCredential', { 
		     id: newCredential.id,
	             rawId: rawId,
	             type: newCredential.type,
		     response: {
		        attestationObject: this.bufferEncode(attestationObject),
		        clientDataJSON: this.bufferEncode(clientDataJSON),
		     },
		 })
		 .then(res=>{
			 console.log(res);
		 })
	         .catch(error=> {
			 console.log(error);
		 });
	 } 
	// Encode an ArrayBuffer into a base64 string.
	// TODO: To verify this
	 bufferEncode(value) {
           // return Buffer.from(value).toString('base64');
	     return Base64js.fromByteArray(value)
	        .replace(/\+/g, "-")
	        .replace(/\//g, "_")
	        .replace(/=/g, "");
	 }
	checkUserExists() {
		    Http.get('/user/' + this.state.email + '/exists', {}, null, 'json')
		        .then(response=> {
				console.log("User Exists");
				            return true;
				        })
			.catch(error=> {
				console.log("User does not Exist");
				            return false;
				       });
	}
	async handleClick(){
		const step1=document.getElementById("step1");
		const listep1=document.getElementById("listep1");
		const countstep1=document.getElementById("countstep1");
		const step2=document.getElementById("step2");
		const listep2=document.getElementById("listep2");
		const countstep2=document.getElementById("countstep2");
		const step3=document.getElementById("step3");
		const listep3=document.getElementById("listep3");
		const countstep3=document.getElementById("countstep3");
                
	    const headers = {
		      'Access-Control-Allow-Origin': '*',
		      'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
		      'Access-Control-Allow-Headers': 'Origin,X-Requested-With,Content-Type,Accept,Authorization',
		};
            const url='makeCredential/'
			+ this.state.email
			+'?attType=direct&authType=&userVerification=discouraged&residentKeyRequirement=false&txAuthExtension=';

            Http.get( url,{headers})
		.then(makeCredentialOptions=> {            
	            makeCredentialOptions.data.publicKey.challenge = this.bufferDecode(makeCredentialOptions.data.publicKey.challenge);
	            makeCredentialOptions.data.publicKey.user.id = this.bufferDecode(makeCredentialOptions.data.publicKey.user.id);
	            if (makeCredentialOptions.data.publicKey.excludeCredentials) {
		       for (var i = 0; i < makeCredentialOptions.data.publicKey.excludeCredentials.length; i++) {
		           makeCredentialOptions.data.publicKey.excludeCredentials[i].id = this.bufferDecode(makeCredentialOptions.data.publicKey.excludeCredentials[i].id);
		       }
		    }
		    console.log("Credential Creation Options");
		    console.log(makeCredentialOptions.data);
		    navigator.credentials.create({
		         publicKey: makeCredentialOptions.data.publicKey
		    }).then(newCredential=> {
		         console.log("PublicKeyCredential Created");
		         console.log(newCredential);
		         this.state.createResponse = newCredential;
		         this.registerNewCredential(newCredential);
		    }).catch(err=> {
		         console.log(err);
		    });
		});
		//TODO: make credential will use the value entered by the user
		         step2.classList.add("noactive");
		         listep2.classList.remove("active");
		         countstep2.classList.remove("active-num");
		         step3.classList.remove("noactive");
		         listep3.classList.add("active");
		         countstep3.classList.add("active-num");
            console.log("form submitted");
	}

	handleChange = (event)=>{
		let nam=event.target.name;
		let val=event.target.value;
		this.setState({[nam]:val});
	}
	handleSubmit = (event)=>{
		const x=new Date();
		event.preventDefault();
		const errorMsgElement = document.querySelector('span#errorMsg');
        const filename = document.querySelector('input#filename');
		if(filename.value)
		{
			this.state.file=filename.value;
			console.log(this.state.file);
		}
		if(this.state.password===this.state.rePassword)
		{
			Http.post('user-api/user',{
				"first_name":this.state.first_name,
				"last_name":this.state.last_name,
				"email":this.state.email,
				"mobile":this.state.mobile,
				"password":this.state.password,
				"timezone":x.getTimezoneOffset(),
				"last_login":x.toISOString().slice(0,19).replace('T','  '),
				"class":1,
				"school":1,
				"lat":this.state.lat,
				"lng":this.state.lng,
				"invig_id":Number(this.state.otp),
				"reg_pic":this.state.file
			}, {
				headers: {
				"Content-Type": "application/json"
				},
			//timeout:100
			})
			.then(res => {
				const response = res.data;
			    if(response.id===undefined)
				{
					errorMsgElement.innerHTML="User Exists";
					errorMsgElement.classList.remove("badge-outline-success");
					errorMsgElement.classList.add("badge-outline-warning");	  
					return;
				}
				else
				{
     				this.setState({ user:response,status:false});
					errorMsgElement.innerHTML="New User Created Successfully";
					errorMsgElement.classList.remove("badge-outline-info");
					errorMsgElement.classList.add("badge-outline-success");	  
					localStorage.setItem('user',JSON.stringify(response));
					//Practice Test Creation Starts
					//Auto assign a test to the user
					//test number is 217 invig id is me.... on live
					var api="exam-api/exame/" + this.state.user.id + "/" + 217  + "/" + "me08112021@life24.fit" + "/" + this.state.user.email;
					//TODO Add function to  invite user here if he does not exist
					Http.post(api)
					.then(res => {
					  console.log(res);
					  //errorMsgElement.innerHTML=this.state.email + " added";
					})
					.catch(function (error) {
					  console.log(error);
					  //errorMsgElement.innerHTML="Could not add " + this.state.email;
					});
					//Practice Test Creation Ends			

	                return <Redirect to="/newprofile" />
                }
		      }).catch(error=>{console.log(error);});
		}
		else alert("The passwords did not match");
	}
	showPosition = (position) => {
		this.state.lat=position.coords.latitude;
		this.state.lng=position.coords.longitude;
	}
                
	// Access webcam
	async componentDidMount() {
		const video = document.getElementById('video');
		const desktop = document.getElementById('desktop');
		const canvas = document.getElementById('canvas');
		const errorMsgElement = document.querySelector('span#errorMsg');
		const constraints = {
			audio: false,
			video: {
			width: 480, height: 360,frameRate:1
			}
		};
		errorMsgElement.innerHTML="Thank You for your Payment. Please Register now. <br />We will then send you your practice test";
			try {
				const stream = await navigator.mediaDevices.getUserMedia(constraints)||navigator.webkitGetUserMedia ||
				navigator.mozGetUserMedia;;
				window.stream = stream;
				video.srcObject = stream;
				/*const deskstream = await navigator.mediaDevices.getDisplayMedia(constraints)||navigator.webkitGetDesktopMedia ||
				navigator.mozGetDesktopMedia;;
				window.stream = deskstream;
				console.log(deskstream);
				desktop.srcObject = deskstream;*/
				} catch (e) {
					errorMsgElement.innerHTML = `navigator.mediaDevices.getUserMedia error:${e.toString()}`;
				}
				navigator.geolocation.getCurrentPosition(this.showPosition);
				mixpanel.init('e47a0808c01226a8e3bfad76601ea193',{debug:true});
				mixpanel.track('Sign Up');
	}
/*	handleSetup = (event) => {
		// handles JSON.stringify/parse
		 //const signaling = new SignalingChannel();
		 const constraints = {audio: true, video: true};
		 const configuration = {iceServers: [{urls: 'stuns:stun.example.org'}]};
		 const pc = new RTCPeerConnection(configuration);
		
		 // send any ice candidates to the other peer
		// pc.onicecandidate = ({candidate}) => signaling.send({candidate});

		
		 // let the "negotiationneeded" event trigger offer generation
		 pc.onnegotiationneeded = async () => {
		   try {
		       await pc.setLocalDescription(await pc.createOffer());
		     // send the offer to the other peer
		       //signaling.send({desc: pc.localDescription});
		       } catch (err) {
		       console.error(err);
		     }
		 };
	
           // once remote track media arrives, show it in remote video element
		  pc.ontrack = (event) => {
		                         // don't set srcObject again if it is already set.
		                           /*if (remoteView.srcObject) return;
		                             remoteView.srcObject = event.streams[0];
		                             };
		                             // call start() to initiate
		                             async function start() {
		                             try {
		                               // get local stream, show it in self-view and add it to be sent
		                                 const stream =
		                                 await navigator.mediaDevices.getUserMedia(constraints);
		                                 stream.getTracks().forEach((track) =>
		                                 pc.addTrack(track, stream));
		                                 selfView.srcObject = stream;
		                               } catch (err) {
		                                   console.error(err);
		                               }
		                             }

		                         //signaling.onmessage = async ({desc, candidate}) => {
		                         try {
		                           if (desc) {
		                           // if we get an offer, we need to reply with an answer
		                             if (desc.type === 'offer') {
		                               await pc.setRemoteDescription(desc);
		                               const stream =
		                               await navigator.mediaDevices.getUserMedia(constraints);
		                               stream.getTracks().forEach((track) =>
		                               pc.addTrack(track, stream));
		                               await pc.setLocalDescription(await pc.createAnswer());
		                               //signaling.send({desc: pc.localDescription});
		                           } else if (desc.type === 'answer') {
		                           await pc.setRemoteDescription(desc);
		                           } else {
		                           console.log('Unsupported SDP type.');
		                           }
		                         } else if (candidate) {
		                         await pc.addIceCandidate(candidate);
		                         }
		                       } catch (err) {
		                         console.error(err);
		                       }
		                       };
	}
	async saveFile=()=> {

		  // create a new handle
	   const newHandle = await window.showSaveFilePicker();
	
           //create a FileSystemWritableFileStream to write to
	   const writableStream = await newHandle.createWritable();
	         // write our file
	   await writableStream.write(imgBlob);
		
	         // close the file and write the contents to disk.
	   await writableStream.close();
        }
	 let saveImage = (img_name) => {
		         }
		
	*/
	handleCapture = (event) => {
	        const video = document.getElementById('video');
                const canvas = document.getElementById('canvas');
                const snap = document.getElementById("snap");
                const errorMsgElement = document.querySelector('span#errorMsg');//error display
                const filename = document.querySelector('input#filename');//hidder var
		const step1=document.getElementById("step1");
		const listep1=document.getElementById("listep1");
		const countstep1=document.getElementById("countstep1");
		const step2=document.getElementById("step2");
		const listep2=document.getElementById("listep2");
		const countstep2=document.getElementById("countstep2");
		const step3=document.getElementById("step3");
		const listep3=document.getElementById("listep3");
		const countstep3=document.getElementById("countstep3");
         {
		 errorMsgElement.innerHTML="Please wait, We are validating your picture";
    //document.querySelector('#canvas').css('display', 'block');
    //document.querySelector('.camera').css('display', 'none');
    document.querySelector('#startbutton').classList.add('save-img');
    //document.querySelector('.auth-animated-alert').css('display', 'block');
    
    //var btntxt = document.querySelector('#startbutton').text();
    //alert(btntxt);
    if( this.state.button_state == "Next") {
       // document.querySelector('.auth-animated-alert').css('display', 'none');
       //alert('clicked');
        document.querySelector('.step1').classList.add('noactive'); 
        document.querySelector('.step2').classList.remove('noactive');
        document.querySelector('.pro-num.count-step1').classList.remove('active-num');
        document.querySelector('.pro-num.count-step2').classList.add('active-num');
        document.querySelector('.progressbar .count-step1').classList.remove('active');
        document.querySelector('.progressbar .count-step2').classList.add('active');
    }
    
    
    
    /*document.getElementById("startbutton").id = "save-img";
    document.querySelector('.save-img').removeAttr('id');
    document.querySelector('.save-img').setAttribute('id','save-img');*/
}       
		//this.setState({captured:true});
                var context = canvas.getContext('2d');
                context.drawImage(video, 0, 0, 480, 360);
                canvas.toBlob(function(blob) {
                var newImg = document.getElementById('imageSrc'),
                
                url = URL.createObjectURL(blob);
                newImg.src = url;
            
                var data = new FormData();
                var file = new File([blob],"selfie");//name the file
                data.append('file', file);//append file to form data
                if (blob) {
                    errorMsgElement.innerHTML = "Wait as we find whether you are a human ....";
                   
                Http.post('user-api/usera', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
		        //timeout:500 TODO to add timeout
                })
                .then(res => {
                    errorMsgElement.innerHTML = res.data;
		            if(res.data.includes('not'))
		            {
				        setTimeout(() => {errorMsgElement.innerHTML = "Ensure that your face is visible on camera";
						errorMsgElement.classList.remove("badge-outline-info");
						errorMsgElement.classList.add("badge-outline-warning");
					}, 2000);
		            }
		            else
		            {
		                filename.value = res.data.split(' ',1);//TODO store this filename in db
		                setTimeout(() => {errorMsgElement.innerHTML = "Face Detected. Fill the form to Register";
						errorMsgElement.classList.remove("badge-outline-info");
						errorMsgElement.classList.add("badge-outline-success"); }, 2000);          
						step1.classList.add("noactive");
						listep1.classList.remove("active");
						countstep1.classList.remove("active-num");
						step2.classList.remove("noactive");
						listep2.classList.add("active");
						countstep2.classList.add("active-num");
		            }
                })
                .catch(function(error) {
                    errorMsgElement.innerHTML = error;
                    console.log(error);
                });
            }   
            //this.setState({profilePic:blob});
         });
		if(errorMsgElement.innerHTML.includes("Ensure"))
		{
		    this.setState({captured:false});
		    console.log("Face detection test failed");
		    this.setState({button_state:"Verification Failed"});
		    document.getElementById("startbutton").innerHTML(this.state.button_state);
		}
		else if(errorMsgElement.innerHTML.includes("Please"))
		{
		    this.setState({captured:false});
		    this.setState({button_state:"Capture"});
		}
		else
        {
		    this.setState({captured:true});
		    this.setState({button_state:"Next"});
		    document.getElementById("startbutton").innerHTML(this.state.button_state);
		    //window.location.reload();
		}
		if(filename.value)
		{
			this.setState({file:filename.value});
			console.log(this.state.file);
		}
        //TODO to be removed
        if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(canvas.msToBlob(), "pic" + this.state.user.id);
        } else {}
                //saveFile(imageData);
	/*var data =new FormData();
		data.append('file',"selfie.png");
		console.log(data);
		this.setState({fileLivePic:"selfie.png"});
		console.log(this.state.fileLivePic);
	        Http.post('user-api/usera',data,{
		        headers: {
		          'Content-Type': 'multipart/form-data'
		        }
		    })
	        .then(res => {
			errorMsgElement.innerHTML=res.data;
		})
                .catch(function (error) {
                     console.log(error);
                 });

		dataURL.replace(/^data:image\/(png|jpg);base64,/, "")
		var imageData=btoa(dataURL);
                //saveFile(imageData);

		let src = window.cv.imread('canvas');
		let dst = new window.cv.Mat();
		// You can try more different parameters
		window.cv.cvtColor(src, dst, window.cv.COLOR_RGBA2GRAY, 0);
		window.cv.imshow('canvas', dst);
		src.delete(); dst.delete();
		//
*/
	
	}

  render() {
	if(this.state.status)
	{
    return (
      <div>
        <meta charSet="utf-8" />
        <meta name="author" content="Softnio" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="description" content="@@page-discription" />
        {/* Fav Icon  */}
        <link rel="shortcut icon" href="../images/favicon.png" />
        {/* StyleSheets  */}
        <link rel="stylesheet" href="../assets/css/style.css?ver=1.2.0" />
        <link rel="stylesheet" href="../assets/css/custom.css" />
        <link id="skin-default" rel="stylesheet" href="../assets/css/theme.css?ver=1.2.0" />
        {/* Page Title  */}
        <title>Registration | Monitor Exam</title>
        {/*Header*/}
        <header className="header">
          <div className="container">
            <a href className="logo"><img src="../images/monitor-exam-logo.png" alt="logo" width={180} /></a>
            <input className="menu-btn" type="checkbox" id="menu-btn" />
            <label className="menu-icon" For="menu-btn"><span className="navicon" /></label>
            <ul className="menu">
              <li><a href="\">Home</a></li>
              <li><a href="\pricing">Pricing</a></li>
              <li><a href="\contact">Contact</a></li>
              <li><a href="\faq">FAQ's</a></li>
              <li><a href="\HowItWorks">How It Works</a></li>
              <li><a href="\newlogin" className="btn btn-lg btn-outline-primary header-btn login">Login</a></li>
              <li><a href="\newiregister" className="btn btn-lg btn-primary header-btn">Register</a></li>
            </ul>
          </div>
        </header>
        {/* app body @s */}
        <div className="nk-app-root">
          <div className="nk-split nk-split-page nk-split-md">
            <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container w-lg-45 bg-lighter">
              <div className="nk-block nk-block-middle nk-auth-body">
                <div className="nk-block-head">
                  <div className="nk-block-head-content">
                    <h5 className="nk-block-title">Register</h5>
                    <div className="nk-block-des">
                      <p>Proctor your online exams with <strong><em>Monitor Exam.</em></strong> Start in 3 easy steps</p>
                    </div>
                  </div>
                </div>{/* .nk-block-head */}
                <div className="nk-block register-process">
                  <div className="row">
                    <div className="col-lg-2">
                      <span id="countstep1" className="pro-num count-step1 active-num">1</span>
                    </div>
                    <div className="col-lg-10">
                      <span className="num-heading">Capture your image</span>
                      <p>Capture your image. The same image will be used as reference for verifying you in the exam.</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <span id="countstep2" className="pro-num count-step2">2</span>
                    </div>
                    <div className="col-lg-10">
                      <span className="num-heading">Fill the form</span>
                      <p>Enter your name email and mobile.</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2">
                      <span id="countstep3" className="pro-num count-step3">3</span>
                    </div>
                    <div className="col-lg-10">
                      <span className="num-heading">Set Password</span>
                      <p>Set a Password</p>
                    </div>
                  </div>
                </div>                    
              </div>{/* .nk-block */}
              {/* nk-block */}
            </div>{/* nk-split-content */}
            <div className="nk-split-content nk-split-stretch bg-lighter d-flex" data-content="athPromo" data-toggle-screen="lg" data-toggle-overlay="true">
              <div className="reg-form w-100 w-max-550px m-auto">
                <ul className="progressbar">
                  <li id="listep1" className="count-step1 active">Step 1</li>
                  <li id="listep2" className="count-step2 ">Step 2</li>
                  <li id="listep3" className="count-step3 ">Step 3</li>
                </ul>
                <form onSubmit={this.handleSubmit}>
				<span id="errorMsg" className="badge badge-dim badge-outline-info "></span>
                  <div id="step1" className="row step1">
                    <div className="col-sm-12 form-group">
                      <span className="badge badge-dim badge-outline-info "></span>
                      {/*<div><button id="startbutton" class="btn btn-lg btn-primary btn-block">Capture</button></div>*/}
                      <div className="camera video-wrap">
                        <video id="video" width="480px" height="360px" style={{display:this.state.captured?"none":"block"}} playsInline autoPlay>Video stream not availble</video>
		                <img id="imageSrc"  width="480px" height="360px" style={{display:this.state.captured?"block":"none"}}/>
                      </div>
                      <canvas id="canvas" width="480px" height="360px" style={{display:this.state.captured?"none":"none"}}></canvas>
		                <input type="hidden" name="filename" id="filename" />
                    </div>
                    <div className="col-sm-6"><a href="#" className="btn btn-outline-primary btn-block" onClick={()=>window.location.reload()} id="retake"><em className="icon ni ni-redo" /> Retake</a></div>
                    <div className="col-sm-6"><a href="#" className="btn btn-primary btn-block" id="startbutton" onClick={this.handleCapture}>{this.state.button_state}</a></div>
                  </div>
                  <div id="step2" className="row step2 noactive">
                    <div className="col-sm-6 form-group">
                      <span className="form-icon"><em className="icon ni ni-user" /></span>
                      <input type="text" className="form-control form-control-lg" id="first_name" placeholder="First Name*" name='first_name' onChange={this.handleChange} required/>
                    </div>
                    <div className="col-sm-6 form-group">
                      <span className="form-icon"><em className="icon ni ni-user" /></span>
                      <input type="text" className="form-control form-control-lg" id="last_name" placeholder="Last Name*" name='last_name' onChange={this.handleChange} required/>
                    </div>
                    <div className="col-sm-12 form-group">
                      <span className="form-icon"><em className="icon ni ni-mail" /></span>
                      <input type="email" className="form-control form-control-lg" id="email" placeholder="Email*" name='email' onChange={this.handleChange} required/>
                    </div>
                    <div className="col-sm-6 form-group">
                      <span className="form-icon"><em className="icon ni ni-call" /></span>
                      <input type="tel" className="form-control form-control-lg" id="mobile" placeholder="Mobile" name='mobile' onChange={this.handleChange} required/>
                    </div>
                    <div className="col-sm-6 form-group">
                      <span className="form-icon"><em className="icon ni ni-user" /></span>
                      <input type="text" className="form-control form-control-lg" id="otp" placeholder="Invigilator Id" name='otp' value="123" readonly onChange={this.handleChange} required/>
                    </div>
					<div className="col-sm-12 form-group">
                      <button onClick={this.handleClick} className="btn btn-lg btn-primary btn-block">Register</button>
                    </div>
                  </div>
                  <div id="step3" className="row step3 noactive">
                    <div className="col-sm-12 form-group">
                      <div className="form-control-wrap">
                        <span className="form-icon"><em className="icon ni ni-lock" /></span>
                        <a href="#" className="form-icon form-icon-right passcode-switch" data-target="password">
                          <em className="passcode-icon icon-show icon ni ni-eye" />
                          <em className="passcode-icon icon-hide icon ni ni-eye-off" />
                        </a>
                        <input type="password" className="form-control form-control-lg" name='password' id="password" placeholder="Password" onChange={this.handleChange} required/>
                      </div>
                    </div>
                    <div className="col-sm-12 form-group">
                      <div className="form-control-wrap">
                        <span className="form-icon"><em className="icon ni ni-lock" /></span>
                        <a href="#" className="form-icon form-icon-right passcode-switch" data-target="password">
                          <em className="passcode-icon icon-show icon ni ni-eye" />
                          <em className="passcode-icon icon-hide icon ni ni-eye-off" />
                        </a>
                        <input type="password" className="form-control form-control-lg" name='rePassword' id="rePassword" placeholder="Confirm Password" onChange={this.handleChange} required/>
                      </div>
                    </div>
                    <div className="col-sm-12 form-group">
                      <button type="submit" className="btn btn-lg btn-primary btn-block">Set Password</button>
                    </div>
                  </div>
                </form>{/* form */}
              </div>
            </div>{/* nk-split-content */}
          </div>{/* nk-split */}
        </div>{/* app body @e */}
        {/* JavaScript */}
      </div>
    );
	} else{
		return (<NewExamDashboard user={this.state.user} />);
	}
  }
}
export default AisctAuth
