import Card from 'react-bootstrap/Card'
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button'
import React,{Component} from 'react'
import VerifyStudentE from '../Auth/VerifyStudentE'
import ReactDom from 'react-dom'

class StudentCard extends Component {
constructor(){
	super();
	this.state=({overall:"Initial"});
}

	async componentDidMount(){
	if (this.props.user.network_state) {
		this.setState({overall:"fair"});
	        if (this.props.user.camera_state) {
		      this.setState({overall:"good"});
		      if (this.props.user.battery_state) {
		           this.setState({overall:"very good"});
		      if (this.props.user.ip) {
		           this.setState({overall:"excellent"});
		      }
		      }
	        }         
	}
	else {
		this.setState({overall:"bad"});
	}
}

handleClick = () => {
        ReactDom.render(<VerifyStudentE user={this.props.user} />,document.getElementById("student"+this.props.user.id));
}

render() {
	return(
       <Card style={{ width: '25rem', padding: '2rem',backgroundColor:'#f7f6f8' }}>
	  <Card.Img variant="top" src={'RP/'+this.props.user.live_pic.split("'")[1]} style={{width:"15rem",borderRadius:"50%",margin:"auto"}} />
	    <Card.Title>{this.props.user.first_name} is <strong>{this.props.user.status}</strong></Card.Title>
	  <Card.Body>
	    <Card.Text>
		<p>Environment is <strong>{this.state.overall}</strong></p>
	       <div id={"student"+this.props.user.id}>
	       </div>
	    </Card.Text>
	      <Accordion.Toggle as={Button} variant="primary" eventKey="0" style={{backgroundColor:"#00b3b3"}}  onClick={this.handleClick}>Student Details</Accordion.Toggle>
	    <Accordion.Collapse eventKey="0">
	       <div id={"studentCard"+this.props.user.id}>
	          <Card.Text>{this.props.user.status}</Card.Text>
	       </div>
	    </Accordion.Collapse>
	  </Card.Body>
	</Card>
	);
}
}
export default StudentCard
