
import React from 'react'
import ReactDOM from 'react-dom'
import Jumbotron from 'react-bootstrap/Jumbotron'
import ReactGA from 'react-ga';

function Cancel(){
	return(
<div>
<meta name="viewport" content="width=device-width,initial-scale=1.0" />
  <div id="myCarousel" className="carousel slide" data-ride="carousel">
    <ol className="carousel-indicators" style={{color:"6576ff"}}>
      <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
      <li data-target="#myCarousel" data-slide-to="1"></li>
      <li data-target="#myCarousel" data-slide-to="2"></li>
    </ol>
    <div className="carousel-inner">
      <div className="carousel-item active">
	<img src="/image2.jpg" width="100%" height="100%"></img>
        <div className="container">
          <div className="carousel-caption text-left">
            <p>Make exams free of cheating</p>
            <p><a className="btn btn-primary" style={{backgroundColor:"#6576ff",padding:"15px"}}  href="/newiregister" role="button">Sign up</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
	);
}

export default Cancel
