import React from 'react'
import ReactDOM from 'react-dom'
import Jumbotron from 'react-bootstrap/Jumbotron'
import ReactGA from 'react-ga';
import Menu from '../Menu/Menu';
import Button from 'react-bootstrap/Button'

class Product extends React.Component {
constructor() {
		super();
		// Add your tracking ID created from https://analytics.google.com/analytics/web/#home/
		ReactGA.initialize('UA-53038418-2');
		// This just needs to be called once since we have no routes in this case.
		ReactGA.pageview(window.location.pathname);
		this.state = {
                status: false,
		open:false
            };
	}  render() {
    return (
      <div>
        {/*  Last Published: Mon Feb 19 2024 04:04:46 GMT+0000 (Coordinated Universal Time)  */}
        <meta charSet="utf-8" />
        <title>Products</title>
        <meta content="MonitorExam A Calm Exam Platform" name="description" />
        <meta content="Products" property="og:title" />
        <meta content="Products" property="twitter:title" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <link href="../assets/css/normalize.css" rel="stylesheet" type="text/css" />
        <link href="../assets/css/webflow.css" rel="stylesheet" type="text/css" />
        <link href="../assets/css/monitorexam-4f64b7a40832b476405c7c490d4.webflow.css" rel="stylesheet" type="text/css" />
        <link href="https://fonts.googleapis.com" rel="preconnect" />
        <link href="https://fonts.gstatic.com" rel="preconnect" crossOrigin="anonymous" />
        <link href="../images/website/favicon.png" rel="shortcut icon" type="image/x-icon" />
        <link href="../images/website/webclip.png" rel="apple-touch-icon" />
        <div className="subpage-header about-1">
          <div className="container w-container">
            <div className="w-row">
              <div data-ix="fade-in-on-load" className="about-column-left w-col w-col-6">
                <h2 className="subpage-title smaller">Create instant tests in just a matter of minutes</h2>
                <div className="page-subtitle full">Use MonitorExam to have greater flexibility in test-administration</div>
                <a href="#" className="button w-button">Learn more</a>
              </div>
              <div className="header-video-column-right w-col w-col-6">
                <a href="#" data-ix="fade-in-on-load-3" className="overall-video-lightbox in-page-header w-inline-block w-lightbox">
                  <div className="video-lightbox-image-block small-hero">
	          <iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FYVdYFyiTbnY%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DYVdYFyiTbnY&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FYVdYFyiTbnY%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube&autoplay=1&mute=1" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe>
                    <div className="video-lightbox-button link-2 noactive"><img src="../images/website/Icon-play-white.png" width={25} alt="" className="video-lightbox-icon" /></div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div data-animation="default" className="navlink white link-2 w-nav" data-easing2="ease-in" data-easing="ease-out" data-collapse="all" role="banner" data-duration={400} data-doc-height={1} sr>
            <div className="w-container">
              <a href="/home" className="logo-block w-nav-brand"><img src="../images/website/monitor-exam-logox2.png" alt="" className="logo fixed link-2" /></a>
              <address role="navigation" className="nav-menu w-nav-menu">
                <a href="/product" aria-current="page" className="product w-nav-link w--current">PRODUCT</a>
                <a href="/about" className="about w-nav-link">ABOUT</a>
                <a href="/pricing" className="pricing w-nav-link">PRICING</a>
                <a href="https://talk.monitorexam.com" className="blog w-nav-link">BLOG</a>
                <a href="/contact" className="contact w-nav-link">CONTACT</a><button className="user-log-in-log-out" data-wf-user-logout="LOGOUT" data-wf-user-login="LOGIN" onClick={()=>window.location.href='/newLogin'} type="button">LOGIN</button>
              </address>
            </div>
            <div className="fixed-nav-menu-button w-nav-button" onClick={()=>this.setState({open:!this.state.open})}>
              <div className="icon w-icon-nav-menu" />
            </div>
          </div>
	  <div class="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0" style={{height:this.state.open?"2846px": "0px", display:this.state.open?"block": "none"}}>
	      <address role="navigation" class="nav-menu w-nav-menu" style={{transform: "translateY(0px) translateX(0px)", transition: "transform 400ms ease-out 0s;"}} data-nav-menu-open="">
                <a href="/product" className="product w-nav-link w--nav-link-open">PRODUCT</a>
                <a href="/about" className="about w-nav-link w--nav-link-open">ABOUT</a>
                <a href="/pricing" className="pricing w-nav-link w--nav-link-open">PRICING</a>
                <a href="https://talk.monitorexam.com" className="blog w-nav-link w--nav-link-open">BLOG</a>
                <a href="/contact" className="contact w-nav-link w--nav-link-open">CONTACT</a>
	      <button className="user-log-in-log-out" data-wf-user-logout="LOGOUT" data-wf-user-login="LOGIN" onClick={()=>window.location.href='/newLogin'}type="button">LOGIN</button>
        </address></div>
        </div>
        <section className="hero-stack-2">
          <div className="container-8">
            <div className="hero-wrapper-two-2">
              <h1>5 Layers for Comfort and Flexibility </h1>
              <div className="w-layout-blockcontainer container-7 w-container">
                <p className="paragraph-3">MonitorExam has five layers. Each one is mentioned below</p>
                <ol role="list" className="list">
                  <li className="list-item">Easy calm-proctoring <br /></li>
                  <li className="list-item-2">Automated Proctoring<br /></li>
                  <li className="list-item-3">Better UX<br /></li>
                  <li className="list-item-4">Customer Recommended Improvements<br /></li>
                  <li className="list-item-5">Biometric Security and Continuous Authentication</li>
                </ol>
                <div className="cf-testimonial-right-wrap" />
              </div>
              <a href="https://monitorexam.com/newiregister" className="button-primary-4 w-button">Get Started</a><img src="../images/website/Layerslayer.png" loading="lazy" alt="" className="hero-image-2 shadow-two" />
            </div>
          </div>
        </section>
        <div className="section">
          <div className="container above-image-section w-container">
            <div className="feature-info-row w-row">
              <div className="calm-experience w-col w-col-4">
                <div className="feature-info-block w-clearfix"><img src="../images/website/Icon-megaphone-blue.png" alt="" className="feature-info-icon" />
                  <div className="control-of-exams">Calm Experience</div>
                  <p className="feature-info-paragraph">MonitorExam Test Creation and Test Taking is based on paradigms of Calm Technology. It ensures that the student is not disturbed during the exam, minimum tech is used.</p>
                </div>
              </div>
              <div className="calm-experience w-col w-col-4">
                <div className="feature-info-block w-clearfix"><img src="../images/website/Icon-monitor-blue.png" alt="" className="feature-info-icon" />
                  <div className="control-of-exams"> Control on Exams</div>
                  <p className="feature-info-paragraph">MonitorExam at anytime can support several test taking platforms. This ensures that the teachers have flexibility how they want to control the exams.</p>
                </div>
              </div>
              <div className="calm-experience w-col w-col-4">
                <div className="feature-info-block w-clearfix"><img src="../images/website/Icon-pencil-blue.png" alt="" className="feature-info-icon" />
                  <div className="control-of-exams">Student Friendly</div>
                  <p className="feature-info-paragraph">Since the team involved in building MonitorExam are also parents there is an empathy for student and commitment to excellence for the teacher</p>
                </div>
              </div>
            </div>
            <div className="feature-info-row w-row">
              <div className="calm-experience w-col w-col-4">
                <div className="feature-info-block w-clearfix"><img src="../images/website/Icon-responsive.png" alt="" className="feature-info-icon" />
                  <div className="control-of-exams">Automation</div>
                  <p className="feature-info-paragraph">The automation layer available in MonitorExam ensures that the energy of test adminstrator and test-taker goes in content creation rather than technology</p>
                </div>
              </div>
              <div className="calm-experience w-col w-col-4">
                <div className="feature-info-block w-clearfix"><img src="../images/website/Icon-camera-blue.png" alt="" className="feature-info-icon" />
                  <div className="control-of-exams">Cares about Privacy</div>
                  <p className="feature-info-paragraph">MonitorExam has used the state of the art technology to ensure that the system is secure and at the same time ensure privacy to the student. We delete student data within 30 days of exam.</p>
                </div>
              </div>
              <div className="calm-experience w-col w-col-4">
                <div className="feature-info-block w-clearfix"><img src="../images/website/Icon-plane-blue.png" alt="" className="feature-info-icon" />
                  <div className="control-of-exams">Authentic</div>
                  <p className="feature-info-paragraph">The state of the art, continuously improving, technology enables greater authenticity of the exam</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cf-faq-section-1">
          <div className="cf-faq-main-wrapper">
            <h2 className="cf-h2-heading left">Any Questions? I’ve got the answers.</h2>
            <div className="cf-faq-contain">
              <div className="cf-faq-left-contain">
                <div data-w-id="f8b75916-6abb-d81e-fd85-1249bd26d928" className="cf-faq-wrapper">
                  <div className="cf-faq-question-wrapper">
                    <div className="cf-faq-question">How does it ensure exams are authentic?</div><img src="../images/website/caret-down-1.svg" loading="lazy" alt="" className="cf-down-arrow" />
                  </div>
                  <div className="cf-faq-answer-wrapper">
                    <div style={{display: 'none', opacity: 0}} className="cf-faq-answer">MonitorExam has won the Dev Challenge 2022 for using security. It uses a proprietory <strong>7-angle credibility score </strong>detection algorithm to ensure that the exams are conducted in an authentic manner</div>
                  </div>
                </div>
                <div data-w-id="f8b75916-6abb-d81e-fd85-1249bd26d918" className="cf-faq-wrapper">
                  <div className="cf-faq-question-wrapper">
                    <div className="cf-faq-question">Does MonitorExam Support Google Forms</div><img src="../images/website/caret-down-1.svg" loading="lazy" alt="" className="cf-down-arrow" />
                  </div>
                  <div className="cf-faq-answer-wrapper">
                    <div style={{display: 'none', opacity: 0}} className="cf-faq-answer">Yes, MonitorExam does support Google Forms, Microsoft Forms, TypeForm and even JotForms. </div>
                  </div>
                </div>
                <div data-w-id="f8b75916-6abb-d81e-fd85-1249bd26d920" className="cf-faq-wrapper">
                  <div className="cf-faq-question-wrapper">
                    <div className="cf-faq-question">Do I need to buy a fingerprint Sensor</div><img src="../images/website/caret-down-1.svg" loading="lazy" alt="" className="cf-down-arrow" />
                  </div>
                  <div className="cf-faq-answer-wrapper">
                    <div style={{display: 'none', opacity: 0}} className="cf-faq-answer">You can use a device which already has a fingerprint sensor</div>
                  </div>
                </div>
              </div>
              <div className="faq-right-contain">
                <div data-w-id="f8b75916-6abb-d81e-fd85-1249bd26d941" className="cf-faq-wrapper">
                  <div className="cf-faq-question-wrapper">
                    <div className="cf-faq-question">How do you take care of Student Experience?</div><img src="../images/website/caret-down-1.svg" loading="lazy" alt="" className="cf-down-arrow" />
                  </div>
                  <div className="cf-faq-answer-wrapper">
                    <div style={{display: 'none', opacity: 0}} className="cf-faq-answer">We take care of student experience by listening to their genuine issues and creating elements in the solution, which enable the solution to stay credible, but see if the inconvenience can be reduced. For example, when we came to know that students sometimes are not able to join exams, we provided OTP&nbsp;based entry via chat. </div>
                  </div>
                </div>
                <div data-w-id="f8b75916-6abb-d81e-fd85-1249bd26d931" className="cf-faq-wrapper">
                  <div className="cf-faq-question-wrapper">
                    <div className="cf-faq-question">What will happen if a student is caught cheating?</div><img src="../images/website/caret-down-1.svg" loading="lazy" alt="" className="cf-down-arrow" />
                  </div>
                  <div className="cf-faq-answer-wrapper">
                    <div style={{display: 'none', opacity: 0}} className="cf-faq-answer">The outcome of cheating will be defined with the college. </div>
                  </div>
                </div>
                <div data-w-id="f8b75916-6abb-d81e-fd85-1249bd26d939" className="cf-faq-wrapper">
                  <div className="cf-faq-question-wrapper">
                    <div className="cf-faq-question">Do I&nbsp;need to install something</div><img src="../images/website/caret-down-1.svg" loading="lazy" alt="" className="cf-down-arrow" />
                  </div>
                  <div className="cf-faq-answer-wrapper">
                    <div style={{display: 'none', opacity: 0}} className="cf-faq-answer">For Low Stake exams it acts just like a web application. For high stake exams installation is required.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="image-section about-us-3">
          <div className="image-section-overlay white-gradient">
            <div className="container centered w-container">
              <div className="section-title-wrapper">
                <h2 className="section-title">Want to learn more?</h2>
                <div className="section-description">If we are still not able to answer any of your specific questions. <br /> Mail us by clicking the link below</div>
              </div>
              <a href="mailto:care@monitorexam.com?subject=I'd%20Like%20to%20learn%20more" className="button w-button">Get in touch with us</a>
            </div>
          </div>
        </div>
        <section className="footer-dark">
          <div className="container-4">
            <div className="footer-wrapper">
              <a href="#" className="footer-brand w-inline-block"><img src="../images/website/monitor-exam-logox2.png" loading="lazy" alt="" height={50} /></a>
              <div className="footer-content">
                <div id="w-node-_6816a091-cecc-b581-5bc8-159758af5401-2aadaa8a" className="footer-block">
                  <div className="title-small">Company</div>
                  <a href="https://monitorexam.com/pricing" className="footer-link-2">Pricing</a>
                  <a href="#" className="footer-link-2">Docs</a>
                  <a href="https://monitorexam.com/howitworks" className="footer-link-2">How it works</a>
                </div>
                <div id="w-node-_6816a091-cecc-b581-5bc8-159758af540a-2aadaa8a" className="footer-block">
                  <div className="title-small">Resources</div>
                  <a href="https://talk.monitorexam.com" className="footer-link-2">Auto-Proctoring</a>
                  <a href="https://talk.monitorexam.com" className="footer-link-2">See all resources &gt;</a>
                  <a href="https://talk.monitorexam.com" className="footer-link-2">Live Proctoring</a>
                  <a href="https://talk.monitorexam.com" className="footer-link-2">Security with Privacy</a>
                </div>
                <div id="w-node-_6816a091-cecc-b581-5bc8-159758af5415-2aadaa8a" className="footer-block">
                  <div className="title-small">About</div>
                  <a href="https://monitorexam.com/terms" className="footer-link-2">Terms &amp; Conditions</a>
                  <a href="https://monitorexam.com/privacy" className="footer-link-2">Privacy policy</a>
                  <div className="footer-social-block">
                    <a href="https://twitter.com/monitorexam" target="_blank" className="footer-social-link w-inline-block"><img src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124ac15112aad5_twitter%20small.svg" loading="lazy" alt="" className="link" /></a>
                    <a href="https://linkedin.com/company/monitorexam" target="_blank" className="footer-social-link w-inline-block"><img src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a389912aad8_linkedin%20small.svg" loading="lazy" alt="" /></a>
                    <a href="https://instagram.com/monitorexam" target="_blank" className="footer-social-link w-inline-block"><img src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a51bf12aae9_facebook%20small.svg" loading="lazy" alt="" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-divider" />
          <div className="footer-copyright-center">Copyright © 2014-2024 Innokreat Technologies Pvt Ltd. All Rights Reserved</div>
        </section>
      </div>
    );
  }
}
  export default Product
