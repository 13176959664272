import React,{Component} from 'react'
import ReactDOM from 'react-dom'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Http from 'axios'
import StudentCard from './StudentCard'
import '../Auth/Auth.css'
import Card from 'react-bootstrap/Card'



class AdminDashboard extends Component{
	constructor(){
		super();
		this.state={isLoaded:false, users:[]};
	}
	async componentWillMount() {
	 //const exam = JSON.parse(localStorage.getItem('exam1'));
	 //const user = JSON.parse(localStorage.getItem('user'));
	 //this.state.user=user;
	 var api;
	 //if(exam) 
	//	api='user-api/useru/'+exam.id;
	 //else
                Http.defaults.headers.common['Authorization'] = this.state.user.token;
		api='user-api/user';
		const response = await	Http.get(api);
                this.setState({ isLoaded:true, users:response.data });
		if(this.props.user)
	           localStorage.setItem('user',JSON.stringify(this.props.user));
		console.log(this.props.user);
	}
	render() {
	return(
		<div>
		<h4 style={{backgroundColor:"#00b3b3",color:"white",padding:"8px"}}>Admin Dashboard</h4>
<nav className="navbar">
  <nav className="navbar-brand">
           	        	<h3><a style={{color:"#fafafa"}} href="/"><img alt="monitorexam" src="/monitorexam_t.png" width="213px" height="120px"></img></a></h3>
  </nav>
  <nav className="navbar appmenu right">
    <a className="nav-link" href="/login" style={{backgroundColor:"#00b3b3",color:"white",borderRadius:"30px"}} onClick={()=>localStorage.clear()}>Logout</a>
  </nav>
</nav>
		<br />
		<br />
		<div className="row">
		<div className="column side">
		</div>
		<div className="column middle">
		<Card>
		<Card.Title>All Students</Card.Title>
		<Card.Body>
		<table style={{borderSpacing:"5px",width:"100%"}} class="table-responsive table" >

		    <tr style={{paddingLeft:"15px", textAlign:"centre"}}><th> #</th><th style={{padding:"15px"}}> Name </th><th style={{padding:"15px"}}> Class </th><th style={{padding:"15px"}}> Mobile</th><th>Email</th><th>Role</th><th>Last Seen</th></tr>
		<>
		{this.state.users.map(user=>( <tr style={{padding:"15px",textAlign:"left"}}><td> {user.id}</td><td> {user.first_name+" "+ user.last_name}</td><td>{user.class}</td><td>{user.mobile}</td>
			<td>{user.email}</td><td>{user.role?user.role:'Student'}</td><td>{user.last_login.slice(0, 19).replace('T', ' ')}</td>
			</tr>))}
		</>
		</table>
		</Card.Body>
		</Card>
		</div>
		</div>
		</div>
	);
	}
}

export default AdminDashboard
