
import React,{Component} from 'react'
import ReactDOM from 'react-dom'
import Jumbotron from 'react-bootstrap/Jumbotron'
import ReactGA from 'react-ga';
import InvigAuth from '../Auth/InvigAuth';
import HowItWorks from './HowItWorks';
import Http from 'axios'
import '../Auth/Auth.css'
import Card from 'react-bootstrap/Card'

class Failure extends React.Component {
    constructor() {
        super();
		// Add your tracking ID created from https://analytics.google.com/analytics/web/#home/
		ReactGA.initialize('UA-53038418-2');
		// This just needs to be called once since we have no routes in this case.
		ReactGA.pageview(window.location.pathname);
    }

    componentDidMount() {
            /*Http.defaults.headers.common['Authorization'] = this.state.user.token;
            Http.put('user-api/userp/' + this.props.user.id, {
                    "status": "paid",
                }, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then(res => {
                    console.log("User payment updated Failurefully");
                })*/
	    console.log("Payment Failure");
        }
    render() {

        return ( 
<div>
<meta name="viewport" content="width=device-width,initial-scale=1.0" />
			    		<div className="row" style={{backgroundColor:"#f8faf7"}}>
			    		<div className="column side">
			    		</div>
			    		<div className="column middle">
			    		<p><span id="errorMsg"></span></p>
			    		<h5>Payment Failed</h5>
			    		<Card>
			    		<Card.Title></Card.Title>
			    		<Card.Body>
	<div>
<h5>Your Payment attempt failed</h5>
<p>Please try again. One of our executives will call you to help within 48 hours. If you do not receive a call from us please email us at care@monitorexam.com 
</p>
		
	</div>
			    		</Card.Body>
			    		</Card>
		</div>
		</div>
		<br />
</div>
	);
}
}
export default Failure
