import React, { useState } from 'react';

function MCQTest() {
  const [questions, setQuestions] = useState([{ question: '', options: [''] }]);

  const handleQuestionChange = (index, event) => {
    const newQuestions = [...questions];
    newQuestions[index].question = event.target.value;
    setQuestions(newQuestions);
  };

  const handleOptionChange = (questionIndex, optionIndex, event) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options[optionIndex] = event.target.value;
    setQuestions(newQuestions);
  };

  const addQuestion = () => {
    setQuestions([...questions, { question: '', options: [''] }]);
  };

  const addOption = (questionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options.push('');
    setQuestions(newQuestions);
  };

  const submitTest = async () => {
    try {
      const response = await fetch('your_backend_url/createTest', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ questions }),
      });
      if (!response.ok) {
        throw new Error('Failed to create test');
      }
      const data = await response.json();
      console.log('Test created:', data);

      // Alternatively, you can use the PUT request:
      // const response = await fetch(`your_backend_url/tests/${test_id}`, {
      //   method: 'PUT',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({ questions }),
      // });
      // if (!response.ok) {
      //   throw new Error('Failed to update test');
      // }
      // const data = await response.json();
      // console.log('Test updated:', data);
    } catch (error) {
      console.error('Error creating/updating test:', error);
    }
  };

  return (
    <div>
      <h1>MCQ Test Creator</h1>
      {questions.map((question, index) => (
        <div key={index}>
          <h3>Question {index + 1}</h3>
          <input
            type="text"
            placeholder="Enter question"
            value={question.question}
            onChange={(e) => handleQuestionChange(index, e)}
          />
          <br />
          {question.options.map((option, optionIndex) => (
            <div key={optionIndex}>
              <input
                type="text"
                placeholder={`Option ${optionIndex + 1}`}
                value={option}
                onChange={(e) => handleOptionChange(index, optionIndex, e)}
              />
            </div>
          ))}
          <button onClick={() => addOption(index)}>Add Option</button>
          <br />
        </div>
      ))}
      <button onClick={addQuestion}>Add Question</button>
      <br />
      <button onClick={submitTest}>Submit Test</button>
    </div>
  );
}

export default MCQTest;

