import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Http from 'axios'
import {Redirect} from 'react-router-dom'
import '../Auth/Auth.css'
import Adapter from 'webrtc-adapter'
import ExamDashboard from '../Dashboard/ExamDashboard'
import Home from '../Home/Home'
import Menu from '../Menu/Menu'
import Card from 'react-bootstrap/Card'
import ReactGA from 'react-ga';

class Support extends React.Component{
	constructor(){
		super();
		//cv=require('./opencv.js');
		this.state={name:"She",title:"Tutor",email:"she@email.com",mobile:"1111122222",status:true};
		// Add your tracking ID created from https://analytics.google.com/analytics/web/#home/
		ReactGA.initialize('UA-53038418-2');
		// This just needs to be called once since we have no routes in this case.
		ReactGA.pageview(window.location.pathname);
	}

	handleChange = (event)=>{
		let nam=event.target.name;
		let val=event.target.value;
		this.setState({[nam]:val});
	}
	handleSubmit = (event)=>{
		const x=new Date();
		event.preventDefault();
		        Http.post('contact-api/contact',{
		          "role":this.state.title,
		          "name":this.state.name,
		          "email":this.state.email,
		          "mobile":this.state.mobile,
		          "message1":this.state.message1,
			  "timezone":x.getTimezoneOffset(),
		        }, {
		          headers: {
		            "Content-Type": "application/json"
		          }
		        })
		      .then(res => {
                      const response = res.data;
                      this.setState({ user:response,status:false});
	              return <Redirect to="/" />
                       })
	}
                
	render(){
		if(this.state.status){
	return(
		<div>
		<meta name="viewport" content="width=device-width,initial-scale=1.0" />
	    <Menu  dark="true" />
	    <br />
	    <br />
		<img src="/support.png" width="100%" height="100%" ></img>
		<h1 style={{backgroundImage:"/support.png"}}>Support</h1>
		<p><span id="errorMsg"></span></p>
		<div className="flex-container">
		<Card style={{padding:"10px",margin:"10px",backgroundColor:"#f5f5f5"}} >
		<Card.Title>Email/Phone</Card.Title>
		<Card.Body>
		<div className="flex-container" style={{flexDirection:"column" }}>
		<p><a href="mailto:care@monitorexam.com" style={{color:"#6576ff"}}>email: care@monitorexam.com</a></p>
		<p><a href="tel:=+917982151592" style={{color:"#6576ff"}}>M: +917982151592</a></p>
		</div>
		<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14033.360761298783!2d77.09096965!3d28.439159699999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1614759715611!5m2!1sen!2sin" width="352" height="264" style={{border:"0"}} allowfullscreen="" loading="lazy"></iframe>
		</Card.Body>
		</Card>
		<Card style={{padding:"10px",margin:"10px",backgroundColor:"#f5f5f5"}} >
		<Card.Title>Email Us</Card.Title>
		<Card.Body>
		<form onSubmit={this.handleSubmit} style={{width:'100%',margin:'2px'}} >
		<div className="flex-container" style={{flexDirection:"column" }}>
		<div className="flex-container" style={{flexDirection:"row" }}>
		    <label for="title">Are you a school, tutor or parent? </label>
		    <input type='text' placeholder='Problem Description in one line' id="title" name='title' onChange={this.handleChange} className="" required style={{margin:'2px',width:"90%"}}></input>
		</div>
		<div className="flex-container" style={{flexDirection:"column" }}>
		    <input type='text' placeholder='name' name='name' onChange={this.handleChange}  className="flex-item" required style={{margin:'4px',width:'90%'}}></input>
		    <input type='text' placeholder='email' name='email' onChange={this.handleChange}  className="flex-item" required style={{margin:'4px',width:'90%'}}></input>
		    <input type='text' placeholder='mobile' name='mobile' onChange={this.handleChange} required  className="flex-item" style={{margin:'4px',width:'90%'}}></input>
		    <textarea placeholder='Enter Problem Description Here' name='message1' rows="5" onChange={this.handleChange} required  className="flex-item" style={{margin:'4px',width:'90%'}}></textarea>
		</div>
		<Button style={{backgroundColor:"#6576ff",paddingTop:"10px", align:"right",border:"none",margin:'2px',width:"70%"}}  className="" variant="primary" type='submit' placeholder='Register' size="lg" required>Contact Support</Button>
		</div>
		</form>
		</Card.Body>
		</Card>
		</div>
		</div>
	);
	}
		else {
			return(<Home />);
		}
	}
}

export default Support
