import React, {
    Component
} from 'react'
import ReactDOM from 'react-dom'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Button from 'react-bootstrap/Button'
import Http from 'axios'
import {
    Map,
    GoogleApiWrapper,
    Marker
} from 'google-maps-react'
import Profile from './Profile'
import Test from './Test'
import TimeSeries from '../Util/TimeSeries'
import {
    Redirect
} from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'

const mapStyles = {
    width: '40%',
    height: '40%'
};

//The card which is visible in the MonitorDashboard comes from here
class VerifyStudentE extends React.Component {
        constructor() {
            super();
            this.state = {
                status: true,
                testStarted: false,
                camera_state: false,
                network_state: false,
                battery_state: false,
                ip: '0.0.0.0',
                captured: false
            };
        }
        refreshState = (event) => {
            event.preventDefault();
            const email = this.props.user.email;
            Http.get('user-api/usere/' + email + '/' + this.props.user.password)
                .then(res => {
                    const response = res.data;
                    this.setState({
                        user: response
                    });
                })
                .catch(function(error) {
                    console.log(error);
                });
		//.slice(0, 19).replace('T', ' ') Unused code
        }
        toLocalTime = () => {
            console.log(this.props.exam.date);
            var x = new Date(this.props.exam.date + "T" + this.props.exam.start_time + "Z");
            return x.toLocaleTimeString();
        }
        componentWillMount() {
            this.setState({
                user: this.props.user
            });
        }
	toLocal = (utctime) => { var x=new Date(utctime);return(x.toLocaleString());}

    toLocalLast = (last_login) => {
		   if(last_login=="")
	           return("Exam Not Given");
           var x=new Date(Date.parse(last_login.slice(0, 19).replace(' ', 'T')+'Z'));
               return(x.toLocaleString());
        }


        render() {
            return ( <
                div >
                <
                section style = {
                    {
                        textAlign: 'left'
                    }
                } >
                <TimeSeries />
                <
                p > < span id = "errorMsg"
                style = {
                    {
                        display: 'none'
                    }
                } > < /span></p >
                <
                p > < span id = "cameraMsg" > < /span></p >
                <ListGroup >
                <ListGroup.Item> <span><img alt="camera" src="/i/camera.png" width="15%"></img> </span> <strong>{this.state.user.camera_state?'\u2713':'\u2715'}</strong ><p>   </p> 
                </ListGroup.Item>   <ListGroup.Item>   
		        < span > <img alt="battery" src="/i/battery.png" width="15%"></img> < /span> <strong>{this.state.user.battery_state?('\u2713'+" "+this.state.user.battery_level+"%"):'\u2715'}</strong > <p>   </p>  
                </ListGroup.Item>   <ListGroup.Item>< span > <img alt="network" src="/i/network.png" width="15%"></img> < /span> <strong>{this.state.user.network_state?'\u2713':'\u2715'}</strong > <p>   </p> < /ListGroup.Item> <
                ListGroup.Item> < span > <img alt="ip" src="/i/ip.png" width="15%"></img>....< /span> <strong>{this.state.user.ip}</strong > < /ListGroup.Item> 
                <ListGroup.Item> < span > Last Seen..... < /span> <strong>{this.toLocalLast(this.state.user.last_login) }</strong > < /ListGroup.Item> 
                <ListGroup.Item> < span > Bluetooth..... < /span> <strong>OK</strong > < /ListGroup.Item> <
		        ListGroup.Item> < span > Background noise..... < /span> <strong>OK</strong > < /ListGroup.Item> <
                ListGroup.Item> < span > Bytes Sent / Received..... < /span> <strong>OK</strong > < /ListGroup.Item> <
                /ListGroup>
                   	   
		           	   
		           	   
		           	  
                
                
                 <
                Button style = {
                    {
                        backgroundColor: '#6576ff',
                        align: 'center',
                        boundary: 'none'
                    }
                }
                onClick = {
                    this.refreshState
                } > Refresh < /Button> 
                
                <img alt="location" src="/i/location.png" width="15%"></img><p>  </p>		           	   
                <
                iframe src = {
                    "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14033.35944807659!2d" + this.state.user.lng + "!3d" + this.state.user.lat + "!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1606743989630!5m2!1sen!2sin"
                }
                frameborder = "0"
                style = {
                    {
                        border: "0"
                    }
                } >
                <
                /iframe> <
                /section> <
                /div>);
            }
        }

        export default GoogleApiWrapper({
            apiKey: 'AIzaSyAiUW4509YK1F7J5eZ34hOimVVpgV9NrF4'
        })(VerifyStudentE)
