import React, {Component} from 'react'
/* Exam Creation process */
class TimeComponent extends React.Component{
	constructor(){
		super();
		var x=new Date();
		this.state={ time:x,offset:x.getTimezoneOffset()};
	}
	componentDidMount(){
		console.log("Time Component");
		this.interval = setInterval(()=>this.setState({time:Date.now()}),1000);
	}
	componentDidUnmount(){
		clearInterval(this.interval);
	}

	render(){
	return(
		<div>
		<br />
                <label for="msg"style={{color:'white'}}><b>{new Date().toLocaleTimeString()}</b></label>
		</div>
	);
        }
}

export default TimeComponent
