import React, { Component } from 'react';

// SpeechRecognition component
class SpeechRecognition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transcript: '',
      isListening: false,
      isFirefox: false
    };
  }

  startListening = () => {
    this.setState({ isListening: true });
    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = true;
    
    recognition.onresult = event => {
      let interimTranscript = '';
      let finalTranscript = '';

      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          finalTranscript += transcript + ' ';
        } else {
          interimTranscript += transcript;
        }
      }

      this.setState({ transcript: finalTranscript });
      this.props.onTranscriptChange(finalTranscript); // Pass transcript to parent
    };

    recognition.onend = () => {
      this.setState({ isListening: false });
    };

    recognition.start();
  };

  stopListening = () => {
    window.webkitSpeechRecognition.stop();
    this.setState({ isListening: false });
  };

  componentDidMount() {
    this.state.isFirefox=navigator.userAgent.toLowerCase().includes('firefox')

    if(!this.state.isFirefox){
    this.startListening(); // Start listening when component mountso
   }
    else
    {
      this.setState({transcript: " Browser not Supported"});
      this.setState({ isListening: false });
    }
  }

  componentWillUnmount() {
    this.state.isFirefox=navigator.userAgent.toLowerCase().includes('firefox')

    if(!this.state.isFirefox){
    this.stopListening(); // Stop listening when component unmounts
    }
  }

  render() {
    const { isListening, transcript } = this.state;
    return (
      <div>
      <a href="#step2" id="capture snap verification-start" className="btn btn-outline-primary mt-2" variant="primary" placeholder='Capture' required onClick={isListening ? this.stopListening : this.startListening}> 
	  {isListening ? 'Stop Listening' : 'Say Mike Testing'} 
      < /a>
      <p>{transcript}</p>
      </div>
    );
  }
}

// ParentComponent

export default SpeechRecognition;

