import React,{Component} from 'react'
import ReactDOM from 'react-dom'
import Jumbotron from 'react-bootstrap/Jumbotron'
import ReactGA from 'react-ga';
import InvigAuth from '../Auth/InvigAuth';
import HowItWorks from './HowItWorks';
import Http from 'axios'
import '../Auth/Auth.css'
import Card from 'react-bootstrap/Card'

class Success extends React.Component {
    constructor() {
        super();
		// Add your tracking ID created from https://analytics.google.com/analytics/web/#home/
		ReactGA.initialize('UA-53038418-2');
		// This just needs to be called once since we have no routes in this case.
		ReactGA.pageview(window.location.pathname);
    }

    componentDidMount() {
            /*Http.defaults.headers.common['Authorization'] = this.state.user.token;
            Http.put('user-api/userp/' + this.props.user.id, {
                    "status": "paid",
                }, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then(res => {
                    console.log("User payment updated Successfully");
                })*/
	    console.log("Payment Successful");
        }
    handleCapture = (event) => {
        const video = document.getElementById('video');
        const canvas = document.getElementById('canvas');
        const snap = document.getElementById("snap");
        const errorMsgElement = document.querySelector('span#errorMsg');
        this.setState({
            captured: true
        });
        // Draw image
        var context = canvas.getContext('2d');
        context.drawImage(video, 0, 0, 320, 240);
        var blob, url;
        //const url= canvas.toDataURL('image/png').replace('image/png','image/octet-stream');
        canvas.toBlob(function(blob) {
            var newImg = document.getElementById('imageSrc'),
                url = URL.createObjectURL(blob);
            console.log(url);
            console.log(blob);
            newImg.src = url;
            
            var data = new FormData();
            var file = new File([blob],"selfie.png");
            console.log(file);
            data.append('file', file);
            if (blob) {
                Http.post('user-api/usera', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    errorMsgElement.innerHTML = res.data;
		    if(res.data.includes('not')) { this.updateState("away");}
                })
                .catch(function(error) {
                    console.log(error);
                });
            }   
            this.setState({profilePic:blob});

            
            
         });
        if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(canvas.msToBlob(), "pic" + this.state.user.id);
        } else {}
    }
    onOpenCvReady() {
        document.getElementById('errorMsg').innerHTML = 'OpenCV.js is ready.';
        console.log("opencv is ready");
    }
    render() {

        return ( 
<div>
<meta name="viewport" content="width=device-width,initial-scale=1.0" />
			    		<div className="row" style={{backgroundColor:"#f8faf7"}}>
			    		<div className="column side">
			    		</div>
			    		<div className="column middle">
			    		<p><span id="errorMsg"></span></p>
			    		<h5>Payment Successful</h5>
			    		<Card>
			    		<Card.Title></Card.Title>
			    		<Card.Body>
	<div>
<h5>Thank You for your Payment</h5>
<p>It will take us a day to process the payment. 
Meanwhile you can spend time on HowItWorks</p>
		
	</div>
			    		</Card.Body>
			    		</Card>
		</div>
		</div>
		<br />
<HowItWorks />
</div>
	);
}
}
export default Success
