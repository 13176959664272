import React, {
    Component
} from 'react'
import ReactDOM from 'react-dom'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Button from 'react-bootstrap/Button'
import Http from 'axios'
import {
    Map,
    GoogleApiWrapper,
    Marker
} from 'google-maps-react'
import NewProfile from './NewProfile'
import NewTest from './NewTest'
import TimeSeries from '../Util/TimeSeries'
import {
    Redirect
} from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup'

const mapStyles = {
    width: '40%',
    height: '40%'
};

//The card which is visible in the MonitorDashboard comes from here
class NewVerifyStudentE extends React.Component {
        constructor() {
            super();
            this.state = {
                status: true,
                testStarted: false,
                camera_state: false,
                network_state: false,
                battery_state: false,
                mirror_state:false,
                ip: '0.0.0.0',
		overall:"Initial",
                captured: false
            };
        }
        refreshState = (event) => {
            event.preventDefault();
            const email = this.props.user.email;
            Http.get('user-api/usere/' + email + '/' + this.props.user.password)
                .then(res => {
                    const response = res.data;
                    this.setState({
                        user: response
                    });
                })
                .catch(function(error) {
                    console.log(error);
                });
		//.slice(0, 19).replace('T', ' ') Unused code
        }
        toLocalTime = () => {
            console.log(this.props.exam.date);
            var x = new Date(this.props.exam.date + "T" + this.props.exam.start_time + "Z");
            return x.toLocaleTimeString();
        }
        componentWillMount() {
            this.setState({
                user: this.props.user
            });
        }
async componentDidMount(){
	if (this.props.user.network_state) {
		this.setState({overall:"Fair"});
			if (this.props.user.camera_state) {
				this.setState({overall:"Good"});
				if (this.props.user.battery_state) {
					this.setState({overall:"Very Good"});
					if (this.props.user.ip) {
						this.setState({overall:"Excellent"});
					}
				}
			}         
	}
	else {
		this.setState({overall:"Bad"});
	}
}
    	toLocal = (utctime) => { var x=new Date(utctime);return(x.toLocaleString());}

        toLocalLast = (last_login) => {
		   if(last_login=="")
	           return("Exam Not Given");
           var x=new Date(Date.parse(last_login.slice(0, 19).replace(' ', 'T')+'Z'));
               return(x.toLocaleString());
        }
        render() {
            return ( 
                <div>
                    <div id="step2" className="step-2 verify-envireonment ">
                        <div className="card ">
                            <p> <span id = "timeMsg"> </span></p >
                                      <p className="card-text d-block"><em className="icon ni ni-mail" /> {this.state.user.email}</p>
                                      <p className="card-text d-block"><em className="icon ni ni-call" /> {this.state.user.status}</p>
                                      <p className="card-text d-block"><em className="icon ni ni-call" /> {this.state.user.cred}</p>
                                      <p className="card-text d-block"><em className="icon ni ni-calendar" /> {this.toLocalLast(this.state.user.last_login)}</p>
                                      <span className="text-center mb-2">Environment is {this.state.overall}</span>
                            <iframe src = {
                                    "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14033.35944807659!2d" + this.state.user.lng + "!3d" + this.state.user.lat + "!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1606743989630!5m2!1sen!2sin"
                                } frameborder = "0" style = {{ border: "0" }}>
                            </iframe> 
                            <div id="envstep2" className="environment-info">
                                <p><em className="icon ni ni-camera text-primary" /> .... <span id="cameraMsg" className="ok"><em className={this.state.user.camera_state?"icon ni ni-check":"icon ni ni-cross"} /></span> </p>
                                <p><em className="icon ni ni-spark text-primary" /> .... <span id="baMsg" className="ok"><em className={this.state.user.battery_state?("icon ni ni-check" +" "+this.state.user.battery_level+"%"):"icon ni ni-cross"} /></span></p>
                                <p><em className="icon ni ni-speed text-primary" /> .... <span id="nwMsg" className="ok"><em className="icon ni ni-check" /></span> <span className="status">{this.state.user.network_state?'\u2713':'\u2715'}</span></p>
                                <p><em className="icon ni ni-location text-primary" /> .... <span id="ipMsg" className="ok"><em className="icon ni ni-check" /></span> {this.state.user.ip}<span className="status"></span></p>
                            </div>
                            <a href="#errorMsg" onClick={this.refreshState} className="btn btn-primary btn-block mb-2" id="update-state">Refresh</a>
                            <span id="errorMsg" className="badge badge-dim badge-outline-success auth-animated-alert-state">Please wait, It takes 5 seconds to verify</span>
                        </div>
                    </div>
                </div>
            );
        }
    }

    export default GoogleApiWrapper({
        apiKey: 'AIzaSyAiUW4509YK1F7J5eZ34hOimVVpgV9NrF4'
    })(NewVerifyStudentE)
