import React, { useState, useEffect } from 'react';
import PubNub from 'pubnub';
import { PubNubProvider, usePubNub } from 'pubnub-react';

//Chat handling for invigilators
function PubInvigChat(props) {
	const pubnub = usePubNub();
	const [messages, addMessage] = useState([]);
	const [message, setMessage] = useState('');
	const [user_id,setUserId]=useState('');
	const invig_id=JSON.parse(localStorage.getItem('user')).id;
	
	//channel per user
	const [channels]= useState(['U_monitorexam_com'+'.'+invig_id+'.*']);
	
	//channel per invigilator for all users
	const [achannel]= useState(['A_monitorexam_com'+'.'+invig_id]);
	var flag=true;


	//When an invig receives message appends messages in the window
	const handleMessage = event => {
		const message = event.message;
		if (typeof message === 'string' || message.hasOwnProperty('text')) {
				var text = message.text || message;
        const sender_id=event.publisher.slice(0,event.publisher.length-15);
				text = event.publisher.slice(0,event.publisher.length-15) + ": "+text;
				addMessage(messages => [...messages, text]);
		}
	}; 

	      //send message to a channel and clean the text box
	const handleChange = (event)=>{
		let nam=event.target.name;
		let val=event.target.value;
		setUserId(val);
	}
	const sendMessage = message => {
		if (message) {
			if((user_id===undefined) || (user_id=='')) {
				pubnub
				.publish({ channel: achannel[0], message })
				.then(() => setMessage(''));
			} else {
				pubnub
				.publish({ channel: "U_monitorexam_com."+invig_id+"."+user_id, message })
				.then(() => setMessage(''));
			}
		}
	};
			
	//working fine as per pubnub debug space link. Messages are coming on channel but not getting released
	const announce = message => {
	
	/*if(message=="Invigilator Joined" && flag==true)
	{
		flag=false;
	}
	else
	{
		return;
	}*/
	if (message) {
		pubnub
			.publish({ channel: achannel[0], message })
			.then(() => setMessage(''));
		}
	};

	useEffect(() => {
		pubnub.addListener({ message: handleMessage });
		pubnub.subscribe({ channels });
		pubnub.subscribe({ channels: achannel });
	},[channels]);
	
	let usersList = props.users.length > 0 &&
	props.users.filter(user => (user.email!=null && user.invig_id==invig_id)).map((user,i) => {
	return (
		<option key={i} value={user.id}>{user.first_name+"("+user.id+")"}</option>
	)
	},this);
//	console.log(usersList);

	return (
		<div id="chat-bot"> {/*style={pageStyles}*/}
          {/* StyleSheets  */}
			<div className="messenger br0 expanded" >{/*style={chatStyles}*/}
				<div className="chatroom" id="chat"> {/*style={headerStyles}*/}
          <span className="text-center mb-2">
            <em class="icon ni ni-chat-circle" style={{fontSize:"20px",color:"#6576ff"}}></em>
            <p>Chat with Student</p>
          </span>
		    </div>
		    <div class="timestamp">{new Date().toLocaleTimeString()}</div>
			  {messages.map((message, index) => {
			    return (
					<div className={(message.split(":")[0]==invig_id)?"msg msg-right":"msg msg-left"}>
						<div className="bubble" >{/*style={listStyles}*/}
								<div key={`message-${index}`} >
		             <em>{(message.split(":")[0]==invig_id)?"Proctor":"Student"}</em>{message}
								</div>
						</div>
					</div>
					);
				})}
					<div class="type-area d-flex">{/*style={footerStyles}*/}
						<input
							type="text"
							placeholder="Type Here..."
							value={message}
							className="typing"
							onKeyPress={e => {
							if (e.key !== 'Enter') return;
							{
									sendMessage(message);
							}
							}}
							onChange={e => setMessage(e.target.value)}
						/>
							{/*style={inputStyles}*/}
              <span class="send " onClick={e => {
                e.preventDefault();
                sendMessage(message);
              }} 
              className="icon ni ni-send" style={{background:"#6576ff"}}> 
              </span>
					 </div>
           <div class="" style={{overflow:"hidden"}}>
							<select onChange={handleChange} className="form-select" name='user_id' className="form-control form-control-lg" id="user_id" placeholder="Student Email" >
							<option value="">All Students</option>
								{usersList}
							</select>
					 </div>
					</div>
					</div>
				);
	}

    const pageStyles = {
	          alignItems: 'center',
	          background: '#fff',
	          display: 'flex',
	          justifyContent: 'center',
	          minHeight: '40vh',
	        };

    const chatStyles = {
	          display: 'flex',
	          flexDirection: 'column',
	          height: '25vh',
	          borderRadius: '5px',
	        };

    const headerStyles = {
	          background: '#00b3b3',
	          color: 'white',
	          fontSize: '1.4rem',
	          padding: '10px 15px',
	        };

    const listStyles = {
	          alignItems: 'flex-start',
	          backgroundColor: '#eee',
	          display: 'flex',
	          flexDirection: 'column',
	          flexGrow: 1,
	          overflow: 'auto',
	          padding: '10px',
	        };

    const messageStyles = {
	          backgroundColor: '#ddd',
	          borderRadius: '5px',
	          color: '#222',
	          fontSize: '1.1rem',
	          margin: '5px',
	          padding: '8px 15px',
	        };

    const footerStyles = {
	          display: 'flex',
	        };

    const inputStyles = {
	          flexGrow: 1,
	          fontSize: '1.1rem',
	          padding: '10px 15px',
	        };

    const buttonStyles = {
	          fontSize: '1.1rem',
	          borderRadius: '5px',
	          padding: '10px 15px',
	          margin: '12px',
	          background: '#00b3b3',
	          color:"white",
	          border:"none"
	        };

    export default PubInvigChat
