import React from 'react'
import ReactGA from 'react-ga';

class AISCT extends React.Component {
    constructor(){
		super();
		// Add your tracking ID created from https://analytics.google.com/analytics/web/#home/
		ReactGA.initialize('UA-53038418-2');
		// This just needs to be called once since we have no routes in this case.
		ReactGA.pageview(window.location.pathname);
		this.state = {
                status: false
            };
	}
	componentWillMount() {
        const user = JSON.parse(localStorage.getItem('user'));
        if(user) {
        this.state.user = user;
        console.log(this.state.user);
        }
        if (user) this.state.status = true;
        //<img src={'/RP/'+this.state.user.reg_pic.split("'")[1]} style={{width:"3rem",borderRadius:"50%",margin:"auto"}}
    }
    render() {
	    return (
            <div> 
                <div>
		            <nav className="pt-10 pb-30 mb-30 bg-light text-center navigation_1">
		              <div className="container px-xl-0">
		                <div className="row justify-content-center align-items-center f-20">
		                  <div className="mb-40 mb-lg-0 col-lg-3 text-lg-left">
		                    <a href="#" className="link img_link">
		                      <img src="../images/monitor-exam-logo.png" alt="Logo" className="img-fluid" data-aos="fade-up" data-aos-delay={150} />
		                    </a>
		                  </div>
		                  <div className="col-lg-6" data-aos="fade-up" data-aos-delay={0}>
		                    <a href="/" className="mx-15 color-main active">
		                      Home
		                    </a>
		                    <a href="/pricing" className="mx-15 color-main">
		                      Pricing
		                    </a>
		                    <a href="/contact" className="mx-15 color-main">
		                      Contact
		                    </a>
		                  </div>
		                  <div className="mt-20 mt-lg-0 col-lg-3 flex-wrap justify-content-center justify-content-lg-end align-items-center" data-aos="fade-up" data-aos-delay={150} style={{display:this.state.user?"none":"flex"}}>
		                    <a href="/newLogin" className="mr-15 color-main">
		                      Login
		                    </a>
		                    <a href="/newiregister" className="btn mr-15 sm action-1 ef-18" style={{borderRadius:"9px"}}>
		                      Register
		                    </a>
		                  </div>
		                  <div style={{display:this.state.user?"flex":"none"}} className="mt-20 mt-lg-0 col-lg-3 flex-wrap justify-content-center justify-content-lg-end align-items-center"  data-aos="fade-up" data-aos-delay={150}>

		                  <a href="/newexamdash" className="link mr-15 color-main">
		                      Dashboard
		                  </a>
		                  <a className ="btn mr-15 sm action-1 f-18" href = "/newlogin"style = {{
                            backgroundColor: "#000d79",
                            color: "white",
                            borderRadius: "9px"
                        }} onClick = {
                            () => localStorage.clear()
                        } > Logout </a>
		                  </div>
		                </div>
		              </div>
		            </nav>
		            {/* Navigation Mobile type 2 */}
		            <a className="open_menu color-main bg-light radius_full">
		              <i className="fas fa-bars lh-40">
		              </i>
		            </a>
		            <div className="navigation_mobile bg-light type2">
		              <a className="close_menu color-main">
		                <i className="fas fa-times">
		                </i>
		              </a>
		              <div className="px-40 pt-50 pb-50 text-center inner">
		                <div>
		                  <a href="/" className="f-heading f-22 link color-main mb-20">
		                    Home
		                  </a>
		                </div>
		                <div>
		                  <a href="/contact" className="f-heading f-22 link color-main mb-20">
		                    Contact
		                  </a>
		                </div>
		                <div>
		                  <a href="/howitworks" className="f-heading f-22 link color-main mb-20">
		                    HowItWorks
		                  </a>
		                </div>
		                <div>
		                  <a href="/faq" className="f-12 link color-main op-7 text-uppercase sp-20 mb-20">
		                    F.A.Q.
		                  </a>
		                </div>
		                <div>
		                  <a href="#" className="f-12 link color-main op-7 text-uppercase sp-20 mb-20">
		                    About Us
		                  </a>
		                </div>
		                <div>
		                  <a href="#" className="f-12 link color-main op-7 text-uppercase sp-20 mb-20">
		                    Blog
		                  </a>
		                </div>
		                <div className="socials mt-60">
		                  <a href="#" target="_blank" className="link color-main f-18 mx-10">
		                    <i className="fab fa-twitter">
		                    </i>
		                  </a>
		                  <a href="#" target="_blank" className="link color-main f-18 mx-10">
		                    <i className="fab fa-facebook">
		                    </i>
		                  </a>
		                  <a href="#" target="_blank" className="link color-main f-18 mx-10">
		                    <i className="fab fa-instagram">
		                    </i>
		                  </a>
		                </div>
		              </div>
		            </div>
                    <div className="col-xl-7 col-lg-9 col-md-10">
                        <div className="mt-20 ml-40 mr-40 mb-20 f-22 color-heading text-adaptive text-left description" data-aos="fade-up" data-aos-delay={150}>
                            <h4><strong>Objective of the AISCT Test</strong></h4>     <br />
                            <p>The objective of this test is to push for Agenda 2030. The intent is to create <em>Sustainability Champions</em> across the nation, who will have a ripple effect on all sections of the society. This initiative will encourage schools to take definitive steps towards <em>Education for Sustainable Development</em>, enabling the capacity building of teachers. It will also provide the students a platform to showcase their knowledge and efforts towards a sustainable future. At the same time, this will spread awareness about the SDGs in the parent community, who will take this knowledge to different sections of the society.
                            </p>
                            <br />
                            <br />
                            <h4><strong>Practice Test</strong></h4>
                            <br />
                            <p>Students can take the practice test <a href='https://pmny.in/LIHo7awqOkiS' style={{color:"blue"}} >here</a> to prepare for the main test.</p>
                            <div data-aos="fade-up" data-aos-delay={300}>
                                <a className="btn mt-40 mb-40  action-2 border-gray color-main" href='https://pmny.in/LIHo7awqOkiS' > 
                                    Buy Practice Test Now 
                                </a>
                            </div>

                            <br />
							<p>1. Buy practice Test in presence of student</p>
							<p>2. On Successful payment link to register on monitorexam portal will appear</p>
							<p>3. Once you register the practice test will be visible. </p>
							<p>4. You cannot download the test.</p>
							<p>5. You can only attempt the test once.</p>
							<p>6. The verification room visible when you attempt the test will be same as in main AISCT exam.</p>
							<p>In case of any issue please feel free to connect with us.</p>
							<br /><p>To know more about AISCT, visit <a href="https://www.arcedtech.com/aisct" style={{color:"blue"}}>https://www.arcedtech.com/aisct</a></p>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default AISCT
