import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Http from 'axios'
import Profile from './Profile'
import {Redirect} from 'react-router-dom'
import './Auth.css'
import Adapter from 'webrtc-adapter'
import ExamDashboard from '../Dashboard/ExamDashboard'
import Card from 'react-bootstrap/Card'
import mixpanel from 'mixpanel-browser'
import Base64js from 'base64-js'


/* Student Registration Module 
 * Takes Student Registration Photo, location, IP
 * Needs to be invited by an invigilator
 */
class Auth extends React.Component{
	constructor(){
		super();
		//cv=require('./opencv.js');
		this.state={
			WebAuthnSupport:false,
		        first_name:'rajiv',
			file:"",captured:false,
			last_name:"",
			email:"she@email.com",
			mobile:"1111122222",
			id:1,
			password:"password",
			rePassword:"password",
			status:true,
			captured:false,
			otp:123356,
			displayToast:true
		};
		this.handleCapture=this.handleCapture.bind(this);
		this.handleSubmit=this.handleSubmit.bind(this);
	        this.handleClick = this.handleClick.bind(this);

		/*, {
		'source':"somewhere",
		'Opted out of email': true,}
		*/
	}
        componentWillMount() {
		this.state.WebAuthnSupport=(window.PublicKeyCredential===undefined)
		console.log("Webauthn Support is "+ !this.state.WebAuthnSupport);
	}
	bufferDecode(value) {
	    return Uint8Array.from(atob(value), c => c.charCodeAt(0));
	}

	// This should be used to verify the auth data with the server
	// step 5 of the mozilla doc
	registerNewCredential(newCredential) {
	         // Move data into Arrays incase it is super long
	         let attestationObject = new Uint8Array(newCredential.response.attestationObject);
	         let clientDataJSON = new Uint8Array(newCredential.response.clientDataJSON);
		 //globally unique identifier of the user
	         let rawId = new Uint8Array(newCredential.rawId);
		 rawId = this.bufferEncode(rawId);
	
	         Http.post('makeCredential', { 
		     id: newCredential.id,
	             rawId: rawId,
	             type: newCredential.type,
		     response: {
		        attestationObject: this.bufferEncode(attestationObject),
		        clientDataJSON: this.bufferEncode(clientDataJSON),
		     },
		 })
		 .then(res=>{
			 console.log(res);
		 })
	         .catch(error=> {
			 console.log(error);
		 });
	 } 
	// Encode an ArrayBuffer into a base64 string.
	// TODO: To verify this
	 bufferEncode(value) {
           // return Buffer.from(value).toString('base64');
	     return Base64js.fromByteArray(value)
	        .replace(/\+/g, "-")
	        .replace(/\//g, "_")
	        .replace(/=/g, "");
	 }
	checkUserExists() {
		    Http.get('/user/' + this.state.email + '/exists', {}, null, 'json')
		        .then(response=> {
				console.log("User Exists");
				            return true;
				        })
			.catch(error=> {
				console.log("User does not Exist");
				            return false;
				       });
	}
	async handleClick(){
	    const headers = {
		      'Access-Control-Allow-Origin': '*',
		      'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
		      'Access-Control-Allow-Headers': 'Origin,X-Requested-With,Content-Type,Accept,Authorization',
		};
            const url='makeCredential/'
			+ this.state.email
			+'?attType=direct&authType=&userVerification=discouraged&residentKeyRequirement=false&txAuthExtension=';

            Http.get( url,{headers})
		.then(makeCredentialOptions=> {            
	            makeCredentialOptions.data.publicKey.challenge = this.bufferDecode(makeCredentialOptions.data.publicKey.challenge);
	            makeCredentialOptions.data.publicKey.user.id = this.bufferDecode(makeCredentialOptions.data.publicKey.user.id);
	            if (makeCredentialOptions.data.publicKey.excludeCredentials) {
		       for (var i = 0; i < makeCredentialOptions.data.publicKey.excludeCredentials.length; i++) {
		           makeCredentialOptions.data.publicKey.excludeCredentials[i].id = this.bufferDecode(makeCredentialOptions.data.publicKey.excludeCredentials[i].id);
		       }
		    }
		    console.log("Credential Creation Options");
		    console.log(makeCredentialOptions.data);
		    navigator.credentials.create({
		         publicKey: makeCredentialOptions.data.publicKey
		    }).then(newCredential=> {
		         console.log("PublicKeyCredential Created");
		         console.log(newCredential);
		         this.state.createResponse = newCredential;
		         this.registerNewCredential(newCredential);
		    }).catch(err=> {
		         console.log(err);
		    });
		});
		//TODO: make credential will use the value entered by the user
            console.log("form submitted");
	}

	handleChange = (event)=>{
		let nam=event.target.name;
		let val=event.target.value;
		this.setState({[nam]:val});
	}
	handleSubmit = (event)=>{
		const x=new Date();
		event.preventDefault();
                const filename = document.querySelector('input#filename');
		if(filename.value)
		{
			this.state.file=filename.value;
			console.log(this.state.file);
		}
		if(this.state.password===this.state.rePassword)
		{
		        Http.post('user-api/user',{
		          "first_name":this.state.first_name,
		          "last_name":this.state.last_name,
		          "email":this.state.email,
		          "mobile":this.state.mobile,
		          "password":this.state.password,
			  "timezone":x.getTimezoneOffset(),
			  "last_login":x.toISOString().slice(0,19).replace('T','  '),
			  "class":1,
			  "school":1,
			  "lat":this.state.lat,
			  "lng":this.state.lng,
			  "invig_id":Number(this.state.otp),
			  "reg_pic":this.state.file
		        }, {
		          headers: {
		            "Content-Type": "application/json"
		          },
			  //timeout:100
		        })
		      .then(res => {
                          const response = res.data;
                          this.setState({ user:response,status:false});
		          localStorage.setItem('user',JSON.stringify(response)); 
	                  return <Redirect to="/profile" />
                       })
		}
		else alert("The passwords did not match");
	}
        showPosition = (position) => {
		this.state.lat=position.coords.latitude;
		this.state.lng=position.coords.longitude;
	}
                
        // Access webcam
        async componentDidMount() {
		const video = document.getElementById('video');
		const desktop = document.getElementById('desktop');
                const canvas = document.getElementById('canvas');
                const errorMsgElement = document.querySelector('span#errorMsg');
                const constraints = {
                    audio: false,
                    video: {
			    width: 320, height: 180,frameRate:1
                    }
                };
                    try {
                        const stream = await navigator.mediaDevices.getUserMedia(constraints)||navigator.webkitGetUserMedia ||
				    navigator.mozGetUserMedia;;
                        window.stream = stream;
                        video.srcObject = stream;
                        /*const deskstream = await navigator.mediaDevices.getDisplayMedia(constraints)||navigator.webkitGetDesktopMedia ||
				    navigator.mozGetDesktopMedia;;
                        window.stream = deskstream;
			console.log(deskstream);
                        desktop.srcObject = deskstream;*/
                    } catch (e) {
                        errorMsgElement.innerHTML = `navigator.mediaDevices.getUserMedia error:${e.toString()}`;
                    }
                    navigator.geolocation.getCurrentPosition(this.showPosition);
                    		mixpanel.init('e47a0808c01226a8e3bfad76601ea193',{debug:true});
		mixpanel.track('Sign Up');
        }
/*	handleSetup = (event) => {
		// handles JSON.stringify/parse
		 //const signaling = new SignalingChannel();
		 const constraints = {audio: true, video: true};
		 const configuration = {iceServers: [{urls: 'stuns:stun.example.org'}]};
		 const pc = new RTCPeerConnection(configuration);
		
		 // send any ice candidates to the other peer
		// pc.onicecandidate = ({candidate}) => signaling.send({candidate});

		
		 // let the "negotiationneeded" event trigger offer generation
		 pc.onnegotiationneeded = async () => {
		   try {
		       await pc.setLocalDescription(await pc.createOffer());
		     // send the offer to the other peer
		       //signaling.send({desc: pc.localDescription});
		       } catch (err) {
		       console.error(err);
		     }
		 };
	
           // once remote track media arrives, show it in remote video element
		  pc.ontrack = (event) => {
		                         // don't set srcObject again if it is already set.
		                           /*if (remoteView.srcObject) return;
		                             remoteView.srcObject = event.streams[0];
		                             };
		                             // call start() to initiate
		                             async function start() {
		                             try {
		                               // get local stream, show it in self-view and add it to be sent
		                                 const stream =
		                                 await navigator.mediaDevices.getUserMedia(constraints);
		                                 stream.getTracks().forEach((track) =>
		                                 pc.addTrack(track, stream));
		                                 selfView.srcObject = stream;
		                               } catch (err) {
		                                   console.error(err);
		                               }
		                             }

		                         //signaling.onmessage = async ({desc, candidate}) => {
		                         try {
		                           if (desc) {
		                           // if we get an offer, we need to reply with an answer
		                             if (desc.type === 'offer') {
		                               await pc.setRemoteDescription(desc);
		                               const stream =
		                               await navigator.mediaDevices.getUserMedia(constraints);
		                               stream.getTracks().forEach((track) =>
		                               pc.addTrack(track, stream));
		                               await pc.setLocalDescription(await pc.createAnswer());
		                               //signaling.send({desc: pc.localDescription});
		                           } else if (desc.type === 'answer') {
		                           await pc.setRemoteDescription(desc);
		                           } else {
		                           console.log('Unsupported SDP type.');
		                           }
		                         } else if (candidate) {
		                         await pc.addIceCandidate(candidate);
		                         }
		                       } catch (err) {
		                         console.error(err);
		                       }
		                       };
	}
	async saveFile=()=> {

		  // create a new handle
	   const newHandle = await window.showSaveFilePicker();
	
           //create a FileSystemWritableFileStream to write to
	   const writableStream = await newHandle.createWritable();
	         // write our file
	   await writableStream.write(imgBlob);
		
	         // close the file and write the contents to disk.
	   await writableStream.close();
        }
	 let saveImage = (img_name) => {
		         }
		
	*/
	handleCapture = (event) => {
		        const video = document.getElementById('video');
                const canvas = document.getElementById('canvas');
                const snap = document.getElementById("snap");
                const errorMsgElement = document.querySelector('span#errorMsg');//error display
                const filename = document.querySelector('input#filename');//hidder var
                
		        //this.setState({captured:true});
                var context = canvas.getContext('2d');
                context.drawImage(video, 0, 0, 320, 240);
                canvas.toBlob(function(blob) {
                var newImg = document.getElementById('imageSrc'),
                
                url = URL.createObjectURL(blob);
                newImg.src = url;
            
                var data = new FormData();
                var file = new File([blob],"selfie");//name the file
                data.append('file', file);//append file to form data
                if (blob) {
                    errorMsgElement.innerHTML = "Wait as we find whether you are a human ....";
                   
                Http.post('user-api/usera', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
		        //timeout:500 TODO to add timeout
                })
                .then(res => {
                    errorMsgElement.innerHTML = res.data;
		            if(res.data.includes('not'))
		            {
				        setTimeout(() => {errorMsgElement.innerHTML = "Ensure that your face is visible on camera"; }, 2000);
		            }
		            else
		            {
		                filename.value = res.data.split(' ',1);//TODO store this filename in db
		                setTimeout(() => {errorMsgElement.innerHTML = "Face Detected. Fill the form to Register"; }, 2000);          
		            }
                })
                .catch(function(error) {
                    errorMsgElement.innerHTML = error;
                    console.log(error);
                });
            }   
            //this.setState({profilePic:blob});
         });
		if(errorMsgElement.innerHTML.includes("Ensure"))
		{
		    this.setState({captured:false});
		    console.log("Face detection test failed");
		}
		else
		{
		    this.setState({captured:true});
		}
		if(filename.value)
		{
			this.setState({file:filename.value});
			console.log(this.state.file);
		}
        //TODO to be removed
        if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(canvas.msToBlob(), "pic" + this.state.user.id);
        } else {}
                //saveFile(imageData);
	/*var data =new FormData();
		data.append('file',"selfie.png");
		console.log(data);
		this.setState({fileLivePic:"selfie.png"});
		console.log(this.state.fileLivePic);
	        Http.post('user-api/usera',data,{
		        headers: {
		          'Content-Type': 'multipart/form-data'
		        }
		    })
	        .then(res => {
			errorMsgElement.innerHTML=res.data;
		})
                .catch(function (error) {
                     console.log(error);
                 });

		dataURL.replace(/^data:image\/(png|jpg);base64,/, "")
		var imageData=btoa(dataURL);
                //saveFile(imageData);

		let src = window.cv.imread('canvas');
		let dst = new window.cv.Mat();
		// You can try more different parameters
		window.cv.cvtColor(src, dst, window.cv.COLOR_RGBA2GRAY, 0);
		window.cv.imshow('canvas', dst);
		src.delete(); dst.delete();
		//
*/
	
	}


	render(){
		if(this.state.status)
		{
	return(
		<div>
		<meta name="viewport" content="width=device-width,initial-scale=1.0" />
		<h1><b>Sign Up</b></h1>
		<div className="row invite" style={{backgroundColor:"#f8faf7"}}>
		<div className="column side">
		</div>
		<div className="column middle">
		<div class="toast show" role="alert" aria-live="assertive" aria-atomic="true" style={{display:this.state.displayToast?"block":"none"}}>
		<div class="toast-header">
		<strong class="me-auto">Face Detection</strong>
		<small>0 mins ago</small>
		<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={()=>this.setState({displayToast:false})}></button>
		</div>
		<div class="toast-body">
		  <p><span id="errorMsg">Click a picture</span></p>
		</div>
		</div>
		<div className="flex-container">
		<Card style={{padding:"10px",margin:"10px",backgroundColor:"#f5f5f5"}} >
		<Card.Title  style={{textAlign:"center"}}>Capture Image</Card.Title>
		<Card.Body>
            <div className="video-wrap">
            <video id="video" width="320px" height="240px" style={{display:this.state.captured?"none":"block"}} playsInline autoPlay></video>
		    <img id="imageSrc"  width="320px" height="240px" style={{display:this.state.captured?"block":"none"}}/>
            <canvas id="canvas" width="320px" height="240px" style={{display:this.state.captured?"none":"none"}}></canvas>
		    <input type="hidden" name="filename" id="filename" />
            </div>
            <div className="controller">
		    <Button id="snap" autoFocus style={{backgroundColor:"#6576ff",paddingTop:"10px", align:"right",border:"none",margin:'2px'}} variant="primary" type='submit' placeholder='Register' size="md" required onClick={this.handleCapture}>Capture</Button>
            </div>
		</Card.Body>
		</Card>
		<Card style={{padding:"10px",margin:"10px",backgroundColor:"#f5f5f5",display:this.state.captured?"block":"none"}} >
		<Card.Title  style={{textAlign:"center"}}>Sign Up</Card.Title>
		<Card.Body>
		<form onSubmit={this.handleSubmit} style={{width:'100%',margin:'2px'}} >
		<div className="flex-container" style={{flexDirection:"column" }}>
		<div className="flex-container" style={{flexDirection:"row" }}>
		    <input type='text' placeholder='First Name' name='first_name' onChange={this.handleChange} className="" required style={{margin:'2px',width:"45%"}} autoFocus></input>
		    <input type='text' placeholder='Last Name' name='last_name' onChange={this.handleChange} className="" required style={{margin:'2px',width:"45%"}}></input>
		</div>
		<div className="flex-container" style={{flexDirection:"column" }}>
		    <input type='email' placeholder='email' name='email' onChange={this.handleChange}  className="flex-item" required style={{margin:'4px',width:'90%'}}></input>
		    <div className="flex-container" style={{flexDirection:"row" }}>
		        <input type='tel' placeholder='mobile' name='mobile' onChange={this.handleChange} required  className="" style={{margin:'2px',width:'45%'}}></input>
		        <input type='text' placeholder='Invigilator ID' name='otp' onChange={this.handleChange} className="" required style={{margin:'2px',width:"45%"}}></input>
		    </div>
		</div>
		<div className="flex-container" style={{flexDirection:"row" }}>
		    <input type='password' placeholder='password' name='password' onChange={this.handleChange} required  className="" style={{margin:'2px',width:'45%'}}></input>
		    <input type='password' placeholder='Re-enter password' name='rePassword' onChange={this.handleChange}  className="" required style={{margin:'2px',width:'45%'}}></input>
		</div>
		<div className="flex-container" style={{flexDirection:"row" }}>
		    <input type='checkbox'  className="" placeholder='' id="savePassword" name='savePassword' onChange={this.handleChecked} defaultChecked={this.state.checked} style={{margin:'2px',width:'20%'}}></input><label for="savePassword" style={{color:'#444',fontSize:"12px"}}>I agree to the <a href="/terms" style={{color:'#444'}}>Terms </a> and <a href="/privacy" style={{color:'#444'}}>Privacy Policy</a></label>
		</div>
		<Button disabled={!this.state.captured} style={{backgroundColor:"#6576ff",paddingTop:"10px", align:"right",border:"none",margin:'2px',width:"70%"}}  className="" variant="primary" type='submit' placeholder='Register' size="lg" onClick={this.handleClick} required>Register</Button>
		</div>
		</form>
		</Card.Body>
		</Card>
		</div>
		</div>
		</div>
		</div>
	);
	} else{
		return (<ExamDashboard user={this.state.user} />);
	}
	}
}

export default Auth
