

import React, {
    Component,
    useState,
    useEffect
} from 'react'
import ReactDOM from 'react-dom'
import Http from 'axios'
import Button from 'react-bootstrap/Button'
import Base64js from 'base64-js'

class WebAuthnLogin extends React.Component {
        constructor() {
            super();
	    this.state={
			WebAuthnSupport:false,
		        first_name:'rajiv'
		};
        }

	checkUserExists=()=> {
		    Http.get('/user/' + this.state.first_name + '/exists', {}, null, 'json')
		        .then(response=> {
				alert("User Exists");
				console.log("User Exists");
				this.getCredentials();
				            return true;
				        })
			.catch(error=> {
				alert("User does not Exist");
				console.log("User does not Exist");
				            return false;
				       });
	}

	getCredentials=()=> {
		    Http.get('/credential/' + this.state.first_name, {userVer:'discouraged',txAuthExtension:''}, null, 'json')
		        .then(response=> {
				            console.log(response)
				        })
			.catch(error=> {
				alert("Getting Credentials Failed");
				console.log("Getting Credentials Failed");
				       });
	}


        getAssertion=()=> {
	    Http.get('/user/' + this.state.first_name + '/exists', {}, null, 'json').then(response=> {
            console.log(response);
	     })
	     .then(()=> {
	
            //var user_verification = $('#select-verification').find(':selected').val();            
//            //var txAuthSimple_extension = $('#extension-input').val();
//
                        Http.get('/assertion/' + this.state.first_name + '?userVer=discouraged&txAuthExtension=', {
                                        userVer: 'discouraged',
                                        txAuthExtension: ''
                        }, null, 'json')
                        .then(makeAssertionOptions=> {
                              console.log("Assertion Options:");
                              console.log(makeAssertionOptions);
                              makeAssertionOptions.data.publicKey.challenge = this.bufferDecode(makeAssertionOptions.data.publicKey.challenge);
                              makeAssertionOptions.data.publicKey.allowCredentials.forEach(listItem=> {
                                                                                                              listItem.id = this.bufferDecode(listItem.id)
                                                                                                          });
                          console.log(makeAssertionOptions.data);
                          navigator.credentials.get({
                                   publicKey: makeAssertionOptions.data.publicKey
                                                                                                          })
                          .then(credential=> {
                          console.log(credential);
                          this.verifyAssertion(credential);
                        }).catch(err=> {
                           console.log(err.name);
		        });
	              });
	})
	.catch(error=> {
	if (!error.exists) {
	     alert("User not found, try registering one first!");
	}
	return;
	});
	}
        verifyAssertion=(assertedCredential)=> {
				    //       // Move data into Arrays incase it is super long
	    console.log('calling verify')
	    let authData = new Uint8Array(assertedCredential.response.authenticatorData);
	    let clientDataJSON = new Uint8Array(assertedCredential.response.clientDataJSON);
	    let rawId = new Uint8Array(assertedCredential.rawId);
	    let sig = new Uint8Array(assertedCredential.response.signature);
	    let userHandle = new Uint8Array(assertedCredential.response.userHandle);
	    Http.post( '/assertion',
		 {
		 id: assertedCredential.id,
		 rawId: this.bufferEncode(rawId),
		 type: assertedCredential.type,
		 response: {
		    authenticatorData: this.bufferEncode(authData),
		    clientDataJSON: this.bufferEncode(clientDataJSON),
		    signature: this.bufferEncode(sig),
		    userHandle: this.bufferEncode(userHandle),
		},
		})
		.then(response=>{
			console.log(response);
		})
		.catch(error => {
			console.log(error);
		});
		}
		

	 bufferEncode(value) {
           // return Buffer.from(value).toString('base64');
	     return Base64js.fromByteArray(value)
	        .replace(/\+/g, "-")
	        .replace(/\//g, "_")
	        .replace(/=/g, "");
	 }
	bufferDecode(value) {
	    return Uint8Array.from(atob(value), c => c.charCodeAt(0));
	}

        handleChange = (event) => {
            let nam = event.target.name;
            let val = event.target.value;
            this.setState({
                [nam]: val
            });
        }
        
        render() {
		return  (<div>
			<h5>Hello Webauthn Login</h5>
			<input type='text' placeholder='Enter name' name='first_name' onChange={this.handleChange} style={{marginBottom:'4px'}} required autoFocus></input>
		        <br></br>
			<Button style={{backgroundColor:"#6576ff",paddingTop:"10px", alignSelf:"right",border:"none"}} variant="primary" type='submit' placeholder='Login Device' size="lg"  onClick={this.getAssertion} required>Login Device</Button><br />
			</div>
		);
	}
}
export default WebAuthnLogin
