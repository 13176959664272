import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga';
class Menu extends React.Component{
	constructor(){
		super();
		//by default the burger meu is closed
		this.state={open:false};
		// Add your tracking ID created from https://analytics.google.com/analytics/web/#home/
		ReactGA.initialize('UA-53038418-2');
		// This just needs to be called once since we have no routes in this case.
		ReactGA.pageview(window.location.pathname);
		this.state = {
                status: false
            };
	}
	//toggle menu options
	openMenu=()=>{
		if(this.state.open) 			this.setState({open:false});
		else this.setState({open:true});
	}
	
	componentWillMount() {
            const user = JSON.parse(localStorage.getItem('user'));
            if(user) {
            //this.state.user = user;
	       this.setState({user:user});
            console.log(this.state.user);
            }
            if (user) this.state.status = true;
    }
	//Display Menu
	render() { 
		if(!this.state.open)
		{
         	return (
<nav className="navbar">
  <nav className="navbar-brand" style={{position:"absolute",top:"5px",left:"25px",zIndex:"12"}}>
		                    <a href="/" className="link img_link">
		                      <img src="/images/monitor-exam-logo.png" alt="Logo" className="img-fluid" width="200px" data-aos="fade-up" data-aos-delay={150} />
		                    </a>
  </nav>
  <ul className="navbar right webmenu" style={{position:"absolute",top:"25px",zIndex:"12"}}>
			<li>
    <a className="nav-link" href="/HowItWorks" style={{color:this.props.dark?"#6576ff":"#fafafa"}}>How It Works</a>
			</li>
			<li>
    <a className="nav-link" href="/about" style={{color:this.props.dark?"#6576ff":"#fafafa"}}>About Us</a>
			</li>
			<li>
    <a className="nav-link" href="/Contact" style={{color:this.props.dark?"#6576ff":"#fafafa"}}>Contact</a>
			</li>
			<li>
    <a className="nav-link" href="http://talk.monitorexam.com" target="_blank" style={{color:this.props.dark?"#6576ff":"#fafafa"}}>Blog</a>
			</li>
			<li>
    <a className="nav-link" href="/newlogin" style={{color:this.props.dark?"#6576ff":"#fafafa",display:this.state.user?"none":"flex"}}>Login</a>
			</li>
			<li>
    <a className="nav-link" href="/newiregister#signup" style={{backgroundColor:"#6576ff",color:"#fafafa",borderRadius:"4px",display:this.state.user?"none":"flex"}}>Register</a>
			</li>
   <li style={{display:this.state.user?"":"none"}} >
        <a className ="nav-link" href = "/newexamdash" > Dashboard 
        </a>
	</li>
   <li style={{display:this.state.user?"":"none"}} >
        <a className ="btn btn-primary nav-link" href = "/newlogin"style = {{
                        }} onClick = {
                            () => localStorage.clear()
                        } > Logout 
        </a>
	</li>
  </ul>
  <div style={{position: "absolute", top: "5px",right:"5px",zIndex:"11"}}>
		<div id="burger" onClick={this.openMenu}>
			<div id="burger1" ></div> 
			<div id="burger2" ></div> 
			<div id="burger3" ></div>
		</div>
  </div>
</nav>);
		}
		else
		{
			return(
<nav className="navbar">
  <nav className="navbar-brand" style={{position:"absolute",top:"25px",left:"25px",zIndex:"12"}}>
		<h3><a style={{color:"#fafafa"}} href="/"><img src="/monitorexam_t.png" width="160px" ></img></a></h3>
  </nav>
  <nav className="navbar right webmenu" style={{position:"absolute",top:"25px",zIndex:"12"}}>
    <a className="nav-link" href="/HowItWorks" style={{color:this.props.dark?"#6576ff":"#fafafa"}}>How It Works</a>
    <a className="nav-link" href="/About" style={{color:this.props.dark?"#6576ff":"#fafafa"}}>About Us</a>
    <a className="nav-link" href="/Contact" style={{color:this.props.dark?"#6576ff":"#fafafa"}}>Contact</a>
    <a className="nav-link" href="http://talk.monitorexam.com" style={{color:this.props.dark?"#6576ff":"#fafafa"}}>Blog</a>
    <a style={{display:this.state.user?"none":"flex",color:this.props.dark?"#6576ff":"#fafafa"}} className="btn btn-outline-primary" href="/newlogin">Login</a>
    <a style={{display:this.state.user?"none":"flex",backgroundColor:"#6576ff",color:"#fafafa",borderRadius:"4px"}}   href="/newiregister#signup" >Register</a>
    <div style={{display:this.state.user?"flex":"none"}} className="mt-20 mt-lg-0 col-lg-3 flex-wrap justify-content-center justify-content-lg-end align-items-center" data-aos="fade-up" data-aos-delay={150}>
        <a className ="btn mr-20 sm action-2 f-16" href = "/login"style = {{
                            backgroundColor: "#6576ff",
                            color: "white",
                            borderRadius: "30px"
                        }} onClick = {
                            () => localStorage.clear()
                        } > Logout 
        </a>
	</div>
  </nav>
  <div style={{position: "absolute", top: "5px",right:"5px",zIndex:"11"}}>
			<div id="burger" onClick={this.openMenu}>
			<div style={{fontSize:"35px",color:"#6576ff",textAlign:"right"}}><strong>X</strong></div> 
			<sidebar style={{backgroundColor:"#6576ff",color:"#fafafa",textAlign:"right"}}>
				<ul>
				<li><a href='/'>Home</a></li>
				<li><a style={{display:this.state.user?"none":"flex"}} href='/newiregister#signup'>Register</a></li>
				<li><a style={{display:this.state.user?"none":"flex"}} className="btn btn-outline-primary" href='/newlogin'>Login</a></li>
				<li><a href='/howitworks'>How It Works</a></li>
				<li><a href='/about'>About Us</a></li>
				<li><a href='/contact'>Contact Us</a></li>
				 <li style={{display:this.state.user?"flex":"none"}} className="mt-20 mt-lg-0 col-lg-3 flex-wrap justify-content-center justify-content-lg-end align-items-center" data-aos="fade-up" data-aos-delay={150}>
        <a href = "/newexamdash"style = {{
                        }}  > Dashboard 
        </a>
	</li>
	 <li style={{display:this.state.user?"flex":"none"}} data-aos="fade-up" data-aos-delay={150}>
        <a className ="btn btn-primary" href = "/newlogin"style = {{
                        }} onClick = {
                            () => localStorage.clear()
                        } > Logout 
        </a>
	</li>
				</ul>
			</sidebar>
			</div>
  </div>
</nav>);
		}
	}
}

export default Menu
