import React, {
    Component
} from 'react'
import ReactDOM from 'react-dom'
import './Auth.css'


class Privacy extends React.Component {
        constructor() {
            super();
            this.state = {
                camera_state: true
            };
        }
        render() {
            return ( <
                div >
          <header className="header" style={{top:"0px"}}>
            <div className="">
              <a href="/" className="logo"><img src="../images/monitor-exam-logo.png" alt="logo" width={120} /></a>
              <input className="menu-btn" type="checkbox" id="menu-btn" />
              <label className="menu-icon" htmlFor="menu-btn"><span className="navicon" /></label>
              <ul className="menu">
                <li><a href="\">Home</a></li>
                <li><a href="\contact">Contact</a></li>
                <li><a href="\faq">FAQ's</a></li>
                <li className="hiw"><a href="\howitworks" >How It Works</a></li>
                <li><a href="\newlogin" className="btn btn-lg btn-outline-primary header-btn login">Login</a></li>
                <li><a href="\newiregister" className="btn btn-lg btn-primary header-btn">Register</a></li>
              </ul>
            </div>
          </header>
                <
                meta name = "viewport"
                content = "width=device-width,initial-scale=1.0" / >
                < div className = "row invite mt-100" style = { { backgroundColor: "white" } } >
                < div className = "column side" > 
                </div>
                < div className = "column middle" > 
            
            
                <
                h5 > < strong > Privacy Policy < /strong></h5 >

                <
                section >
                <
                p > < span id = "errorMsg"
                style = {
                    {
                        display: 'none'
                    }
                } > < /span></p >
                <p> 
                        <h5>Innokreat Technologies Pvt Ltd Privacy Policy</h5>

		    This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from <a href="/">https://monitorexam.com</a> (the “Site”).

		    <h5>PERSONAL INFORMATION WE COLLECT</h5><br />

		    When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.”

		    <br />
		    We collect Device Information using the following technologies:<br />

		        - “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit <a href="http://www.allaboutcookies.org">http://www.allaboutcookies.org</a>.<br />
		        - “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.<br />
		        - “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.<br />
		        - "Location" while signup<br />
		        - "Picture" while signup<br />
		        - During exams we track your IP, battery usage etc for that particular duration.<br />
		        Additionally when you taken an exam or attempt to taken an exam through the Site, we collect certain information from you, including your name, picture, payment information (including mobile number, email address, and phone number.  We refer to this information as “Exam Information.”<br />
<br />
<br />
		    When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Exam Information.<br />
<br />
		    HOW DO WE USE YOUR PERSONAL INFORMATION?<br />
<br />
		    We use the Exam Information that we collect generally to fulfill any orders placed through the Site (including processing your payment information, and providing you with invoices and/or order confirmations).  Additionally, we use this Exam Information to:<br />
		    Communicate with you;Screen our orders for potential risk or fraud; and<br />
		    When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.<br />
		    We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).<br />
		    SHARING YOUR PERSONAL INFORMATION<br />
<br />
		    We share your Personal Information with third parties to help us use your Personal Information, as described above.  We use Google Analytics and Microsoft Clarity to help us understand how our customers use the Site--you can read more about how Google uses your Personal Information here:  <a href="https://www.google.com/intl/en/policies/privacy/">https://www.google.com/intl/en/policies/privacy/</a>.  You can also opt-out of Google Analytics here:  <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.<br />
<br />
		    Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.<br />

		    DO NOT TRACK<br />
		    Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.<br />
<br />
<br />
		    YOUR RIGHTS<br />
		    If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above.  Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.<br />
<br />
<br />
		    DATA RETENTION<br />
		    When you taken an exam through the Site, we will maintain your Information for our records for 30 days unless you ask us to keep this information for longer.<br />
<br />
		    MINORS<br />
		    The Site is not intended for individuals under the age of 13.<br />
<br />
		    CHANGES<br />
		    We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.CONTACT US<br />
		    For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at <a href="mailto:care@monitorexam.com">care@monitorexam.com</a> or by mail using the details provided below:<br />
<br />
		      GURGAON, HR, 122003, India<br />
		    < /p> 
		  </section> 
	</div>
		</div>
	</div>
	);
            }
        }

        export default Privacy;
