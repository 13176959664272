import React from 'react'
import ReactDOM from 'react-dom'
import Jumbotron from 'react-bootstrap/Jumbotron'
import ReactGA from 'react-ga';
import Button from 'react-bootstrap/Button'

class WFHome extends React.Component {

    constructor(){
		super();
		// Add your tracking ID created from https://analytics.google.com/analytics/web/#home/
		ReactGA.initialize('UA-53038418-2');
		// This just needs to be called once since we have no routes in this case.
		ReactGA.pageview(window.location.pathname);
		this.state = {
                status: false,
		open: false
            };
	}
	
	
	componentWillMount() {
            const user = JSON.parse(localStorage.getItem('user'));
            if(user) {
            this.state.user = user;
            console.log(this.state.user);
            }
            if (user) this.state.status = true;
            //		                  <img src={'/RP/'+this.state.user.reg_pic.split("'")[1]} style={{width:"3rem",borderRadius:"50%",margin:"auto"}}
    }
    render() {
      return (
        <div>
          {/*  Last Published: Mon Feb 19 2024 04:04:46 GMT+0000 (Coordinated Universal Time)  */}
          <meta charSet="utf-8" />
          <title>MonitorExam: A Calm Exam Platform</title>
          <meta content="MonitorExam is a remote proctoring solution which incorporates AI/ML and computer vision technology in a Calm and secure way, providing indications to the proctor without disrupting the flow of the candidates and invading their privacy." name="description" />
          <meta content="Home" property="og:title" />
          <meta content="Homepage for MoniorExam" property="og:description" />
          <meta content="Home" property="twitter:title" />
          <meta content="Homepage for MoniorExam" property="twitter:description" />
          <meta property="og:type" content="website" />
          <meta content="summary_large_image" name="twitter:card" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <link href="../assets/css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="../assets/css/webflow.css" rel="stylesheet" type="text/css" />
          <link href="../assets/css/monitorexam-4f64b7a40832b476405c7c490d4.webflow.css" rel="stylesheet" type="text/css" />
          <link href="https://fonts.googleapis.com" rel="preconnect" />
          <link href="https://fonts.gstatic.com" rel="preconnect" crossOrigin="anonymous" />
          <link href="../images/website/favicon.png" rel="shortcut icon" type="image/x-icon" />
          <link href="../images/website/webclip.png" rel="apple-touch-icon" />
          <div data-animation="default" className="navlink white link-2 w-nav" data-easing2="ease-in" data-easing="ease-out" data-collapse="all" role="banner" data-duration={400} data-doc-height={1} sr>
            <div className="w-container">
              <a href="/home" aria-current="page" className="logo-block w-nav-brand w--current"><img src="../images/website/monitor-exam-logox2.png" alt="" className="logo fixed link-2" /></a>
              <address role="navigation" className="nav-menu w-nav-menu">
                <a href="/product" className="product w-nav-link">PRODUCT</a>
                <a href="/about" className="about w-nav-link">ABOUT</a>
                <a href="/pricing" className="pricing w-nav-link">PRICING</a>
                <a href="https://talk.monitorexam.com" className="blog w-nav-link">BLOG</a>
                <a href="/contact" className="contact w-nav-link">CONTACT</a>
	      <button className="user-log-in-log-out pt-1" data-wf-user-logout="LOGOUT" data-wf-user-login="LOGIN" onClick={()=>window.location.href='/newLogin'}type="button">LOGIN</button>
              </address>
            </div>
            <div className="fixed-nav-menu-button w-nav-button" onClick={()=>this.setState({open:!this.state.open})}>
              <div className="icon w-icon-nav-menu" />
            </div>
          </div>
	  <div class="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0" style={{height:this.state.open?"2846px": "0px", display:this.state.open?"block": "none"}}>
	      <address role="navigation" class="nav-menu w-nav-menu" style={{transform: "translateY(0px) translateX(0px)", transition: "transform 400ms ease-out 0s;"}} data-nav-menu-open="">
                <a href="/product" className="product w-nav-link w--nav-link-open">PRODUCT</a>
                <a href="/about" className="about w-nav-link w--nav-link-open">ABOUT</a>
                <a href="/pricing" className="pricing w-nav-link w--nav-link-open">PRICING</a>
                <a href="https://talk.monitorexam.com" className="blog w-nav-link w--nav-link-open">BLOG</a>
                <a href="/contact" className="contact w-nav-link w--nav-link-open">CONTACT</a>
	      <button className="user-log-in-log-out" data-wf-user-logout="LOGOUT" data-wf-user-login="LOGIN" onClick={()=>window.location.href='/newLogin'}type="button">LOGIN</button>
        </address></div>
          <div className="image-hero busines-2">
            <div className="hero-block-overlay business-2">
              <div className="video-hero-container w-container">
                <div className="full-hero-content-block">
                  <h1 data-ix="fade-in-on-load" className="overall-hero-title">A Calm Exam Platform</h1>
                  <p data-ix="fade-in-on-load-2" className="overall-hero-description"><em>Don't let the Stress of Test Administration Drain your Teachers <br />Conduct Secure and Stress-free Exams with MonitorExam.</em></p>
                  <a href="https://bit.ly/monitorexam" target="_blank" data-ix="fade-in-on-load-3" className="button outline w-button">schedule demo</a>
                </div>
              </div>
            </div>
          </div>
          <div className="section tint">
            <div className="container w-container">
              <div className="section-title-wrapper small-title">
                <div className="small-section-title">We're supported by</div>
              </div>
              <div className="logos-row w-row">
                <div className="logo-column w-col w-col-2 w-col-small-4 w-col-tiny-4"><img src="../images/website/12770285-nvidia-inception-program.png" alt="" className="client-logo" /></div>
                <div className="logo-column w-col w-col-2 w-col-small-4 w-col-tiny-4"><img src="../images/website/logo-salesforce.svg" alt="" className="client-logo" /></div>
                <div className="logo-column w-col w-col-2 w-col-small-4 w-col-tiny-4"><img src="../images/website/microsoftforstartupslogo.png" alt="" className="client-logo" /></div>
                <div className="logo-column w-col w-col-2 w-col-small-4 w-col-tiny-4"><img src="../images/website/fido.jpg" alt="" className="client-logo" /></div>
                <div className="logo-column w-col w-col-2 w-col-small-4 w-col-tiny-4"><img src="../images/website/uplogo.png" alt="" className="client-logo" /></div>
                <div className="logo-column w-col w-col-2 w-col-small-4 w-col-tiny-4"><img src="../images/website/PB_AWS_logo.png" alt="" className="client-logo" /></div>
              </div>
            </div>
          </div>
          <div id="intro" className="section feature-section">
            <div className="container w-container"><img src="../images/website/Screenshot-2023-04-17-210642.png" alt="" width="953.5" className="browser-mockup-right" />
              <div className="feature-text-block">
                <h2 className="main-feature-title">Our Platform</h2>
                <p className="paragraph">With state of the art technology and robust security measures, MonitorExam ensures fair and credible remote proctoring for both test-administrators and test-takers. <br />Experience stress-free exams with MonitorExam's user-friendly interface, seamless authentication process, and extensive monitoring features. &nbsp;</p>
                <ul role="list">
                  <li className="list-item-6">Modern AI Powered Remote Proctoring</li>
                  <li className="list-item-7">Fair, Credible, and Stress-free Exams</li>
                  <li className="list-item-8">Award-winning Technology</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="section tint">
            <div className="container w-container">
              <div className="section-title-wrapper">
                <h2 className="section-title">How it Works</h2>
                <div className="section-description">An Overview of Steps Test Administrators Take to Administer Exams</div>
              </div>
              <div className="process-tabs-line w-hidden-tiny" />
              <div data-duration-in={300} data-duration-out={100} data-current="Tab 1" data-easing="ease" className="process-tabs w-tabs">
                <div className="process-tab-menu w-tab-menu">
                  <a data-w-tab="Tab 1" className="process-tab-button w-inline-block w-tab-link w--current"><img src="../images/website/exam.png" alt="" className="process-tab-icon" />
                    <div>CREATE EXAM</div>
                  </a>
                  <a data-w-tab="Tab 2" className="process-tab-button w-inline-block w-tab-link"><img src="../images/website/logintimestamp.png" alt="" className="process-tab-icon" />
                    <div className="text-block-3">Verify</div>
                  </a>
                  <a data-w-tab="Tab 3" className="process-tab-button w-inline-block w-tab-link"><img src="../images/website/Icon-monitor-blue.png" alt="" className="process-tab-icon" />
                    <div className="text-block-4">monitor</div>
                  </a>
                  <a data-w-tab="Tab 4" className="process-tab-button w-inline-block w-tab-link"><img src="../images/website/Icon-thumb-blue.png" alt="" className="process-tab-icon" />
                    <div className="text-block-5">report cred</div>
                  </a>
                </div>
                <div className="w-tab-content">
                  <div data-w-tab="Tab 1" className="process-tab-pane w-tab-pane w--tab-active">
                    <div className="process-tab-row w-row">
                      <div className="process-tab-col-left w-col w-col-6">
                        <blockquote>"A test score can tell you where a student is, but it cannot tell where the student can be"</blockquote>
                      </div>
                      <div className="process-tab-col-right w-col w-col-6">
                        <p>Create Exam is a simple functionality using which a Test Administrator can use his/her test platform of choice(Google Form, JotForm) to create an exam. To know whether your test administration platform of choice is supported by MonitorExam, please contact us.</p>
                      </div>
                    </div>
                  </div>
                  <div data-w-tab="Tab 2" className="process-tab-pane w-tab-pane">
                    <div className="process-tab-row w-row">
                      <div className="process-tab-col-left w-col w-col-6">
                        <blockquote>"Proctoring is a responsible but monotonous job. I can't read anything, talk to anyone. I just have to look around"<br />A Student Proctor</blockquote>
                      </div>
                      <div className="process-tab-col-right w-col w-col-6">
                        <p>MonitorExam simplifies the job of proctoring by digitally monitoring the student's context and activities using its proprietary algorithm. The system highlights students who need closer monitoring and the proctor can simply focus on them.</p>
                      </div>
                    </div>
                  </div>
                  <div data-w-tab="Tab 3" className="process-tab-pane w-tab-pane">
                    <div className="process-tab-row w-row">
                      <div className="process-tab-col-left w-col w-col-6">
                        <blockquote>"As proctor, I&nbsp;noticed that most of the students follow the honor code. We do not want to add stress or disturb students unnecessarily. That is why we sometimes do ID&nbsp;verification also at the time of submission"</blockquote>
                      </div>
                      <div className="process-tab-col-right w-col w-col-6">
                        <p>MonitorExam maintains the cumulative behaviour score called the cred score. The cred score is a quick look at how honestly student is giving the exam. In case of Live Proctoring, a quick in-built chat is good enough to solve the issue right there. In auto-proctoring it is visible much later, with the cred score and the manual verification of images.</p>
                      </div>
                    </div>
                  </div>
                  <div data-w-tab="Tab 4" className="process-tab-pane w-tab-pane">
                    <div className="process-tab-row w-row">
                      <div className="process-tab-col-left w-col w-col-6">
                        <blockquote>"MonitorExam's report is particularly useful in case of Auto Proctored tests. They have a summary of suspicious students and the proof why they think so"</blockquote>
                      </div>
                      <div className="process-tab-col-right w-col w-col-6">
                        <p>MonitorExam sends a report within 48 hours of conducting the exam. If it is an auto-evaluated test, then we get the test scores and cred-scores all in one go. In some cases like in olympiads we deep dive into the cred score and cred data of only the top 5 students. In cases where each student has to be ranked all the cred scores are analysed</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="top-line-block">
                <div className="text-row w-row">
                  <div className="text-column w-col w-col-3">
                    <div className="text-column-title number">1</div>
                    <div className="text-column-title">create exam</div>
                    <p className="small-paragraph">Test Administrator provides the question paper in a document format with solutions. MonitorExam Team Creates an Exam and adds students to it. The exam can be a simple quiz using a form solution or an LMS. We even support scanned question papers</p>
                  </div>
                  <div className="text-column w-col w-col-3">
                    <div className="text-column-title number">2</div>
                    <div className="text-column-title">verify</div>
                    <p className="small-paragraph">At the time of Exam the Test Proctor will login and verify all the students. In case one method of verification fails, there are alternative paths also.<br />We have enhanced Camera Troubleshooting to solve common camera issues.</p>
                  </div>
                  <div className="text-column w-col w-col-3">
                    <div className="text-column-title number">3</div>
                    <div className="text-column-title">monitor</div>
                    <p className="small-paragraph">Besides Verification the Test Proctor will login and monitor the exam online. He can chat with each student or broadcast announcements to all</p>
                  </div>
                  <div className="text-column w-col w-col-3">
                    <div className="text-column-title number">4</div>
                    <div className="text-column-title">send report</div>
                    <p className="small-paragraph">Once the Exam is over, a report will be sent by MonitorExam to Test Administrator to provide detailed insights on all student. The key elements of the report are an automated Cred Score, Remarks by proctors, grades ( in case of MCQs)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section feature-section even w-clearfix">
            <div className="container w-container"><img src="../images/website/screenshot.png" alt="" className="browser-mockup-left" />
              <div className="feature-text-block right">
                <h2 className="main-feature-title">Our Unique Value Proposition</h2>
                <p>Here is what we offer to ensure that your test-administrators and test-takers win</p>
                <div className="small-features-wrapper">
                  <div data-ix="fade-in-on-scroll" className="long-feature-block title-only">
                    <div className="long-feature-title uppercase">Zero Burden on Student</div>
                    <div className="long-feature-icon-wrapper"><img src="../images/website/exam.png" alt="" className="long-feature-icon" /></div>
                    <div className="small-paragraph">Calm Technology, Empathetic Proctors, Multi-Path Entry</div>
                  </div>
                  <div data-ix="fade-in-on-scroll" className="long-feature-block title-only">
                    <div className="long-feature-icon-wrapper"><img src="../images/website/Icon-thumb-blue.png" alt="" className="long-feature-icon" /></div>
                    <div className="long-feature-title uppercase">7-angle Verified Cred Score</div>
                    <div className="small-paragraph">AI Auto-Proctoring, context awareness, continuous MFA, Manual Review and more to generate Cred Score</div>
                  </div>
                  <div data-ix="fade-in-on-scroll" className="long-feature-block title-only">
                    <div className="long-feature-icon-wrapper"><img src="../images/website/Icon-pencil-blue.png" alt="" className="long-feature-icon" /></div>
                    <div className="long-feature-title uppercase">Customized Assessments</div>
                    <div className="small-paragraph">Support large variety of assessments like Google Forms to LMS, pdf and customization for what is not available</div>
                  </div>
                </div>
              </div>
            </div>
            <a href="/product" className="button-primary w-button">Learn more</a>
          </div>
          <div className="section blue-cta-section">
            <div className="container w-container">
              <a href="https://monitorexam.com/newiregister" className="button cta-button white w-button"><strong>Get Started now</strong></a>
              <h4 className="dark-cta-title">Join us on our mission to enable 1 million students to take exams from the location of their choice, device of their choice in a stress-free manner by 2025</h4>
            </div>
          </div>
          <section className="footer-dark">
            <div className="container-4">
              <div className="footer-wrapper">
                <a href="#" className="footer-brand w-inline-block"><img src="../images/website/monitor-exam-logox2.png" loading="lazy" alt="" height={50} /></a>
                <div className="footer-content">
                  <div id="w-node-_095d0c6f-4b80-3daa-4501-e11b7e0e4dc6-2aadaa24" className="footer-block">
                    <div className="title-small">Company</div>
                    <a href="/pricing" className="footer-link-2">Pricing</a>
                    <a href="/product" className="footer-link-2">Docs</a>
                    <a href="https://monitorexam.com/howitworks" className="footer-link-2">How it works</a>
                  </div>
                  <div id="w-node-_095d0c6f-4b80-3daa-4501-e11b7e0e4dcf-2aadaa24" className="footer-block">
                    <div className="title-small">Resources</div>
                    <a href="https://talk.monitorexam.com" className="footer-link-2">Auto-Proctoring</a>
                    <a href="https://talk.monitorexam.com" className="footer-link-2">Live Proctoring</a>
                    <a href="https://talk.monitorexam.com/untitled-3/" className="footer-link-2">How MonitorExam Ensures Privacy and Security</a>
                    <a href="https://talk.monitorexam.com" className="footer-link-2">See all resources &gt;</a>
                  </div>
                  <div id="w-node-_095d0c6f-4b80-3daa-4501-e11b7e0e4dda-2aadaa24" className="footer-block">
                    <div className="title-small">About</div>
                    <a href="https://monitorexam.com/terms" className="footer-link-2">Terms &amp; Conditions</a>
                    <a href="https://monitorexam.com/privacy" className="footer-link-2">Privacy policy</a>
                    <div className="footer-social-block">
                      <a href="https://twitter.com/monitorexam" target="_blank" className="footer-social-link w-inline-block"><img src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124ac15112aad5_twitter%20small.svg" loading="lazy" alt="" className="link" /></a>
                      <a href="https://linkedin.com/company/monitorexam" target="_blank" className="footer-social-link w-inline-block"><img src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a389912aad8_linkedin%20small.svg" loading="lazy" alt="" /></a>
                      <a href="https://instagram.com/monitorexam" target="_blank" className="footer-social-link w-inline-block"><img src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a51bf12aae9_facebook%20small.svg" loading="lazy" alt="" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-divider" />
            <div className="footer-copyright-center">Copyright © 2014-2024 Innokreat Technologies Pvt Ltd</div>
          </section>
        </div>
      );
    }
  }

  export default WFHome
