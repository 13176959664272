import React,{Component} from 'react'
import Button from 'react-bootstrap/Button'
import Http from 'axios'
import './Chat.css';
import PubNub from 'pubnub'
import { PubNubProvider, usePubNub } from 'pubnub-react';


class Video extends Component {
	constructor(){
		super();
		this.state={msg:'start',sentiment:'unknown'};
	}
	handleChange = (event)=>{
		let nam=event.target.name;
		let val=event.target.value;
		this.setState({[nam]:val});
	}
	handleSth = (event) => {
		alert("A function");
	}
	/*login = (form) => {
		var video_out = document.getElementbyId('vid-box');
		  var phone = window.phone = PHONE({
			        number        : form.username.value || "Anonymous", // listen on username line else Anonymous
			        publish_key   : 'pub-c-23f811ec-42c1-4443-a957-45c3e4f5f4fc',
			        subscribe_key : 'sub-c-7ea44132-4f16-11e4-95c2-02ee2ddab7fe',
			    });  
		  phone.ready(function(){ form.username.style.background="#55ff5b"; });
		  phone.receive(function(session){
			        session.connected(function(session) { video_out.appendChild(session.video); });
			        session.ended(function(session) { video_out.innerHTML=''; });
			    });
		  return false;   // So the form does not submit.
	}*/
	/*makeCall=(form)=>{
		  if (!window.phone) alert("Login First!");
		  else phone.dial(form.number.value);
		  return false;
	}*/



	sendVideo = (event) => {
		event.preventDefault();
		var config = {
                  method: 'get',
                  url: 'https://api.wit.ai/message?v=20200827&q='+this.state.msg,
                  headers: {
                    'Authorization': 'Bearer DDWMOBTNMSYZ7B7JHDLQ233BMLC7WXRG'
                  }
                };

                Http(config)
                .then(function (response) {
                  const sentiment=response.data.traits.wit$sentiment[0].value;
                  const chatMsgElement = document.querySelector('span#sentiment');
                  chatMsgElement.innerHTML = `Examinee is feeling ` + sentiment;
                })
                .catch(function (error) {
                  console.log(error);
                });
                console.log('Submitted' + this.state.msg);
	}

        render(){
	return(
		 <div className="chat-popup" id="myForm" style={{float:"right"}}>
                   <form className="form-container">
                     <h5>Video</h5>
                     <label for="msg"><b>Message {new Date().toLocaleTimeString()}</b></label>
		<br></br>
                     <textarea placeholder="Video Call with Student.." name="msg" onChange={this.handleChange} required></textarea>
		<br></br>
		     <p><span id='sentiment'></span></p>
                     <Button type="submit" className="btn" style={{backgroundColor:"#00b3b3",border:0}} onClick={this.sendVideo}>Send</Button>
                     <Button type="button" className="btn cancel" onClick="closeForm()" style={{backgroundColor:"#00b3b3",margin:"4px",border:0,align:"right"}}>Close</Button>
                   </form>
		<div id="vid-box"></div>
		<form name="loginForm" id="login" action="#" onSubmit={this.handleSth}>
		    <input type="text" name="username" id="username" placeholder="Pick a username!" />
		    <input type="submit" name="login_submit" value="Log In" />
		</form>
		<form name="callForm" id="call" action="#" onSubmit={this.handleSth}>
		  <input type="text" name="number" placeholder="Enter user to dial!" />
		  <input type="submit" value="Call"/>
		</form>
                 </div>
	);
	}
}

export default Video
