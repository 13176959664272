import React,{Component} from 'react'
import ReactDOM from 'react-dom'
import Button from 'react-bootstrap/Button'
import Http from 'axios'
import '../Auth/Auth.css'
import Card from 'react-bootstrap/Card'
import Dashboard from './Dashboard'
import Login from '../Auth/Login'

class ExamDashboard extends Component{
	constructor(){
		super();
		this.state=
			{
				isLogged:false,
				isLoaded:false, 
				exams:[],
				id:0,
				found:false,
				showDate:false,
			};
	}
	gotoProfile=(id,exam)=>{
		//localStorage.setItem('exam',JSON.stringify(this.state.exams[id-1]));
	}
	doesUserExist=(user,email)=> {
		//matching the user and the invigilator
		if((user.email==email)&&(user.invig_id==this.state.user.id)) {
		   this.state.found=true;
		   console.log("user found")
		   this.state.email = email
                   return true; 
		}
		else
		{
		   //console.log("user does not exist")
		   const errorMsgElement = document.querySelector('span#errorMsg');
	       errorMsgElement.innerHTML="User not attached to Invigilator. Please invite user";
		   return false;
		}
	}
	handleChange = (event)=>{
		let nam=event.target.name;
		let val=event.target.value;
		this.setState({id:0});
		//TODO: return false if user doesn't exist and send an email. If exists send id to create a map entry
		//email to user_id conversion
		if(nam =='user_id')
		//this.state.users.filter(user=>((user.email==val)&&(user.invig_id==this.state.user.id))).map(user=>(this.setState({user_id:user.id})));
		this.state.users.filter(user=>(this.doesUserExist(user,val))).map(user=>(this.setState({user_id:user.id})));
		else
		this.setState({[nam]:val});
		//if(!this.state.found)
		//	console.log("Failed to find user");


	}
	//Add Student
	handleClick=(exam)=>{
		localStorage.setItem('exam1',JSON.stringify(exam));
		var status;

		if(exam.date!==undefined) {
		//status=(Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))<(Date.now())?(((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z")+exam.duration*60000)>(Date.now()))?"Ongoing":"Done"):"Not Started";
	        status=((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))<(Date.now()))?(((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z")+exam.duration*60000)>(Date.now()))?"Ongoing":"Late"):"Not Started";
		console.log(status);
		if(status=='Ongoing') //Ongoing Exam
		{
			console.log("Too late to add a student");
		}
		else if(status=="Not Started"){ //Upcoming Exam
			console.log("Adding student to exam");
		    if(this.state.user_id) {
		    var api="exam-api/exame/" + this.state.user_id + "/" + exam.id  + "/" + this.state.email + "/" + this.state.user.email;
		//TODO Add function to  invite user here if he does not exist
	        Http.post(api)
	        .then(res => {
                     console.log(res);
               })
               .catch(function (error) {
                     console.log(error);
               });
		}
		else {
		    console.log("Failed to find user");
		}
		}
		else //Past Exam
		{
			console.log("Exam has already occured.");
		}
		}
		else //unscheduled exam
		{
		if(this.state.user_id) {
		    var api="exam-api/exame/" + this.state.user_id + "/" + exam.id + "/" + this.state.email+ "/" + this.state.user.email;
			//TODO Add function to  invite user here
	        Http.post(api)
	        .then(res => {
                     console.log(res);
               })
               .catch(function (error) {
                     console.log(error);
               });
		}
		}
	}
	//New functionality to schedule an exam
	scheduleExam=(exam)=>{
	    const errorMsgElement = document.querySelector('span#errorMsg');
		localStorage.setItem('exam1',JSON.stringify(exam));
		if(exam.date===undefined)
		{
		    console.log("Exam Schedule routine clicked");
		    this.state.showDate=true;
		    var x=new Date(Date.parse(this.state.date)+Date.parse("1970-01-01T"+this.state.start_time+"Z"));
		    var x=new Date(Date.parse(this.state.date+"T"+this.state.start_time+"Z"));
		    var y=new Date(x.getTime()+x.getTimezoneOffset()*60000);
		    this.state.date=y.toISOString();
		    let time=this.state.date.split("T");
		    this.state.date=time[0];
		    time=time[1].split(".");
		    this.state.start_time=time[0];
		    exam.date=this.state.date;
		    exam.start_time = this.state.start_time;
                Http.put('exam-api/exam/' + exam.id, {
                    "date": this.state.date,
                    "start_time": this.state.start_time,
                }, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then(res => {
                    console.log("Exam scheduled Successfully");
                    errorMsgElement.innerHTML = "Exam scheduled successfully";
                })
                .catch(function(error) {
                    errorMsgElement.innerHTML = error;
                    console.log(error);
		});
		}
		else
		{
			console.log("Exam already scheduled at ",exam.date, exam.start_time);
		}
	        
	}
	async componentWillMount() {
		const user = JSON.parse(localStorage.getItem('user'));
	        var tout,minutes_left;
		if(this.state.user=user)
                    this.state.isLogged=true;
		else{
	            console.log("User not Logged in");
		    if(this.props.user)
		    {
	                this.state.user=this.props.user;
                        this.state.isLogged=true;
		    }
		    else
                        this.state.isLogged=false;
		}
		//for invigilators and 
		//TODO: admin
	        if(this.state.user.role=='invig') {
		    var api="exam-api/exam";
          	}
	        else {
		    var api="exam-api/examu/" + this.state.user.id ;
		    if(this.state.user)
		       localStorage.setItem('user',JSON.stringify(this.state.user));
	        }
	     const response = await Http.get(api);
	/*	.then(res => {
                      const response = res.data;
                      this.setState({ isLoaded:true, exams:response.data });
		      //errorMsgElement.innerHTML="Exam Scheduled Successfully";
	              //return <Redirect to="/examdash" />
                })
                .catch(function (error) {
                  //this.setState({ status:true});
                  console.log(error);
                  this.setState({ isLoaded:false});
		  //errorMsgElement.innerHTML=error;
                });*/
                this.setState({ isLoaded:true, exams:response.data });
	}
	async componentDidMount(){
	        var api;
    Http.defaults.headers.common['Authorization'] = this.state.user.token;
		api='user-api/user';
		const response = await	Http.get(api);
                this.setState({ isLoaded:true, users:response.data });
	}
	componentWillUnmount(){
		console.log(localStorage.getItem('exam1'));
		console.log(localStorage.getItem('user'));
	}
	toLocal = (utctime) => { var x=new Date(Date.parse("1970-01-01T"+utctime.toString()+"Z"));x+=this.state.user.timezone*60000;return(x.toLocaleString().slice(16,24));}
	
	
	render() {
		if(this.state.isLogged)
		{
	return(
		<div>
                <nav className="navbar" style={{color:"#00b3b3",padding:"8px"}}>
                  <nav className="navbar-brand">
	        	<h3><a style={{color:"#fafafa"}} href="/"><img alt="monitorexam" src="/monitorexam_t.png" width="213px" height="120px"></img></a></h3>
                  </nav>
                  <nav className="navbar appmenu right" style={{padding:"8px"}}>
                    <a className="nav-link" style={{display:this.state.user.role=='invig'?'block':'none',color:"#00b3b3"}} href="/dashboard">Student Dashboard</a>
                    <a className="nav-link" href="/login" style={{color:"#00b3b3",borderRadius:"30px"}} onClick={()=>localStorage.clear()}>Logout</a>
                  </nav>
                </nav>
		<br />
		<br />
		<br />
		<br />
		<h4 style={{color:"white",backgroundColor:"#00b3b3", padding:"8px"}}>{this.state.user.first_name}'s Exam Dashboard</h4>
		<div className="row invite" style={{backgroundColor:"#f8faf7"}}>
		<div style={{backgroundColor:"white", color:"#00b3b3",margin:"16px"}} className="column side">
		<h5 style={{display:this.state.user.role=='invig'?"block":"none"}}>INV{this.state.user.id}</h5>
		<h5>Side Menu</h5>
		<ul>
		<li><a style={{color:""}} href="/testdash">Ongoing</a></li>
		<li><a style={{color:""}} href="#upcoming">Upcoming</a></li>
		<li><a style={{color:""}} href="#unscheduled">Unscheduled</a></li>
		<li><a style={{color:""}} href="#other">Past</a></li>
		</ul>
		</div>
		<div className="column middle">
        <Button style={{backgroundColor:"#00b3b3",justifyContent:"right",display:this.state.user.role=='invig'?'block':'none'}} className="float-right"><a className="float-right" style={{boarderRadius:"6px",textAlign:"right",display:this.state.user.role=='invig'?'block':'none',backgroundColor:"#00b3b3",color:"white"}} href="/form#createexam">Create Exam</a></Button>
		<div class="toast show" role="alert" aria-live="assertive" aria-atomic="true" style={{display:this.state.displayToast?"block":"none"}}>
		<div class="toast-header">
		<strong class="me-auto">Exam Management</strong>
		<small>0 mins ago</small>
		<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={()=>this.setState({displayToast:false})}></button>
		</div><div class="toast-body">
		  <p><span id="errorMsg"></span></p>
		</div></div>
		
		<table style={{borderSpacing:"5px",width:"100%"}} className='css-serial table-responsive'>
		<div id="ongoing">
		<Card style={{width:"100%"}}>
		<Card.Title>Ongoing Exams</Card.Title>
		<Card.Body>

		<tr style={{paddingLeft:"15px", textAlign:"centre"}}>
		  <th> #</th>
		  <th style={{padding:"15px"}}> Date</th>
		  <th style={{padding:"15px"}}> Name </th>
		  <th>Status</th>
		  <th>Duration(in minutes)</th>
		  <th style={{padding:"15px"}}>Start Time</th>
		  </tr>
		<>
		{this.state.exams.filter(exam=>((exam.date!==undefined)&&((exam.invig_id==this.state.user.id)||(exam.invig_id==this.state.user.invig_id)))).filter(exam=>(((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))<(Date.now()))&&((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z")+exam.duration*60000)>(Date.now())))).map(exam=>( 
			<tr style={{padding:"15px",textAlign:"centre"}}>
			  <td id="exam_id">{++this.state.id}</td>
			  <td>{Intl.DateTimeFormat().format(Date.parse(exam.date) + Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))}</td>
  			  <td> {exam.name}</td>
			  <td>{((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))<(Date.now()))?(((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z")+exam.duration*60000)>(Date.now()))?"Ongoing":"Past"):"Upcoming"}</td>
			  <td>{exam.duration}</td>
			  <td>{this.toLocal(exam.start_time)}</td>
			<td>
			<input name="note" type="text" style={{display:this.state.user.role=='invig'?"none":"none"}} placeholder="Observation about student" onChange={this.handleChange} />
			</td>
			<td>
			<Button>
			<a href='/monidash' style={{color:"white",display:this.state.user.role=='invig'?"block":"none"}} onClick={()=>this.handleClick(exam)}>
			{((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))<(Date.now()))?(((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z")+exam.duration*60000)>(Date.now()))?"Monitor":"View"):"Add Student"}
			</a>
			<a href='/profile' style={{color:"white",display:this.state.user.role=='invig'?"none":"block"}} onClick={()=>localStorage.setItem('exam1',JSON.stringify(exam))}>
			{((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))<(Date.now()))?(((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z")+exam.duration*60000)>(Date.now()))?"Start Exam":"Done"):"Not Started"}
			</a>
			</Button>
			</td>
			</tr>))}
		</>
		</Card.Body>
		</Card>
		</div>
		<div id="upcoming">
		<Card style={{width:"100%"}}>
		<Card.Title>Upcoming Exams</Card.Title>
		<Card.Body>

		<tr style={{paddingLeft:"15px", textAlign:"centre"}}>
		  <th> #</th>
		  <th style={{padding:"15px"}}> Date</th>
		  <th style={{padding:"15px"}}> Name </th>
		  <th>Status</th>
		  <th>Duration(in minutes)</th>
		  <th style={{padding:"15px"}}>Start Time</th>
		  </tr>
		<>
		{this.state.exams.filter(exam=>((exam.date!==undefined)&&((exam.invig_id==this.state.user.id)||(exam.invig_id==this.state.user.invig_id)))).filter(exam=>((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))>(Date.now()))).map(exam=>( 
			<tr style={{padding:"15px",textAlign:"centre"}}>
			  <td id="exam_id">{++this.state.id}</td>
			  <td>{Intl.DateTimeFormat().format(Date.parse(exam.date) + Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))}</td>
  			  <td> {exam.name}</td>
			  <td>{((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))<(Date.now()))?(((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z")+exam.duration*60000)>(Date.now()))?"Ongoing":"Past"):"Upcoming"}</td>
			  <td>{exam.duration}</td>
			  <td>{this.toLocal(exam.start_time)}</td>
			<td>
			  <input name="user_id" type="text" style={{display:this.state.user.role=='invig'?"block":"none"}} placeholder="Student Email" onChange={this.handleChange} />
			</td>
			<td style={{backgroundColor:"#00b3b3",color:"white",borderRadius:'6px',padding:'10px'}}>
			<a href='/monidash' style={{color:"white",display:this.state.user.role=='invig'?"block":"none"}} onClick={()=>this.handleClick(exam)}>
			{(Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))<(Date.now())?(((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z")+exam.duration*60000)>(Date.now()))?"Monitor":"View"):"Add Student"}
			</a>
			<a href={(((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z")-10*60000)<(Date.now()))?'/wait':'#upcoming')} style={{color:"white",display:this.state.user.role=='invig'?"none":"block"}} onClick={()=>localStorage.setItem('exam1',JSON.stringify(exam))}>
			{(Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))<(Date.now())?(((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z")+exam.duration*60000)>(Date.now()))?"Start Exam":"Done"):(((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z")-10*60000)<(Date.now()))?"Enter Waiting Room":"Allowed to enter 10 minutes early")}
			</a>
			</td>
			</tr>))}
		</>
		</Card.Body>
		</Card>
		</div>
		<div id="unscheduled">
		<Card style={{width:"100%"}}>
		<Card.Title>Unscheduled Exams</Card.Title>
		<Card.Body>

		<tr style={{paddingLeft:"15px", textAlign:"centre"}}>
		  <th> #</th>
		  <th style={{padding:"15px"}}> Date</th>
		  <th style={{padding:"15px"}}> Name </th>
		  <th>Status</th>
		  <th>Duration(in minutes)</th>
		  <th style={{padding:"15px"}}>Start Time</th>
		  </tr>
		<>
		{this.state.exams.filter(exam=>((exam.date===undefined)&&((exam.invig_id==this.state.user.id)||(exam.invig_id==this.state.user.invig_id)))).map(exam=>( 
			<tr style={{padding:"15px",textAlign:"centre"}}>
			  <td id="exam_id">{++this.state.id}</td>
			  <td>
		    <input type='date' className="flex-item" placeholder='Enter Date' name='date' style={{display:this.state.showDate?"block":"nonse"}} onChange={this.handleChange} style={{margin:'2px'}}></input>
		    <input type='time' className="flex-item" placeholder='Enter Start Time' name='start_time' style={{display:this.state.showDate?"block":"none"}} onChange={this.handleChange} style={{margin:'2px'}}></input>
			</td>
  			  <td> {exam.name}</td>
			  <td>Unscheduled</td>
			  <td>{exam.duration}</td>
			  <td>NA</td>
			<td>
			  <input name="user_id" type="text" style={{display:this.state.user.role=='invig'?"block":"none"}} placeholder="Student Email" onChange={this.handleChange} />
			</td>
			<td>
			<Button style={{backgroundColor:"#00b3b3",color:"white",borderRadius:'30px',marginBottom:'10px'}}>
			<a href='/monidash' style={{color:"white",padding:"4px",display:this.state.user.role=='invig'?"block":"none"}} onClick={()=>this.handleClick(exam)}>
			Add Student
			</a>
			</Button>
			</td>
			<td>
			<Button style={{backgroundColor:"#00b3b3",color:"white",borderRadius:'30px',marginBottom:'10px'}}>
			<a href='#' style={{color:"white",padding:"4px",display:this.state.user.role=='invig'?"block":"block"}} onClick={()=>this.scheduleExam(exam)}>
			Schedule Exam
			</a>
			</Button>
			</td>
			</tr>))}
		</>
		</Card.Body>
		</Card>
		</div>
		<div id="other">
		<Card style={{width:"100%"}}>
		<Card.Title>Past Exams</Card.Title>
		<Card.Body>
		<tr style={{paddingLeft:"15px", textAlign:"centre"}}>
		  <th> #</th>
		  <th style={{padding:"15px"}}> Date</th>
		  <th style={{padding:"15px"}}> Name </th>
		  <th>Status</th>
		  <th style={{padding:"15px"}}> Duration(in minutes)</th>
		  <th style={{padding:"15px"}}>Start Time</th>
		  </tr>
		<>
		{this.state.exams.filter(exam=>((exam.date!==undefined)&&((exam.invig_id==this.state.user.id)||(exam.invig_id==this.state.user.invig_id)))).filter(exam=>((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z")+exam.duration*60000)<(Date.now()))).map(exam=>( 
			<tr style={{padding:"15px",textAlign:"centre"}}>
			  <td id="exam_id">{++this.state.id}</td>
			  <td>{Intl.DateTimeFormat().format(Date.parse(exam.date) + Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))}</td>
  			  <td> {exam.name}</td>
			  <td>{((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))<(Date.now()))?(((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z")+exam.duration*60000)>(Date.now()))?"Ongoing":"Past"):"Upcoming"}</td>
			  <td>{exam.duration}</td>
			  <td>{this.toLocal(exam.start_time)}</td>
			<td>
			<Button>
			<a href='/completed' style={{color:'white',display:this.state.user.role=='invig'?"block":"none"}} onClick={()=>this.handleClick(exam)}>
			{((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))<(Date.now()))?(((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z")+exam.duration*60000)>(Date.now()))?"Monitor":"View"):"Add Student"}
			</a>
			<p style={{color:'white',padding:"4px",display:this.state.user.role=='invig'?"none":"block"}} onClick={()=>localStorage.setItem('exam1',JSON.stringify(exam))}>
			{(Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))<(Date.now())?(((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z")+exam.duration*60000)>(Date.now()))?"Start Exam":"Done"):"Not Started"}
			</p>
			</Button>
			</td>
			</tr>))}
		</>
		</Card.Body>
		</Card>
		</div>
		</table>
		</div>
		</div>
		</div>
	);
	}
	else
	    return(<Login />);
	}
}


export default ExamDashboard
