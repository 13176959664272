import React, { useState } from 'react';
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga';
const FAQ = () => {


  const [openIndex, setOpenIndex] = useState(null);

  const handleAccordionClick = (index) => {
    if (openIndex === index) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  return (
	  <div>
          <header className="header">
            <div className="">
              <a href="/" className="logo"><img src="../images/monitor-exam-logo.png" alt="logo" width={120} /></a>
              <input className="menu-btn" type="checkbox" id="menu-btn" />
              <label className="menu-icon" htmlFor="menu-btn"><span className="navicon" /></label>
              <ul className="menu">
                <li><a href="\">Home</a></li>
		<li><a href="\about" >About Us</a></li>
                <li className="hiw"><a href="\howitworks" >How It Works</a></li>
                <li><a href="\pricing">Pricing</a></li>
                <li><a href="\contact">Contact</a></li>
                <li><a href="\newlogin" className="btn btn-lg btn-outline-primary header-btn login">Login</a></li>
                <li><a href="\newiregister" className="btn btn-lg btn-primary header-btn">Register</a></li>
              </ul>
            </div>
          </header>
		                        <div className="nk-block" style={{backgroundColor:"#f5f6fa"}}>
		                        <div className="column col-3 side">
		                        </div>
		                        <div className="column col-9 pt-100">
      <h3 className="nk-block-title fw-normal text-center" >Frequently Asked Questions</h3>
      <h4 className="text-center" >General</h4>
      <div id="accordion-2" class="accordion accordion-s1">
      {faqs.map((faq, index) => (
        <div className="accordion-item" key={index}>
	  <a href="#" class="accordion-head" data-bs-toggle="collapse" data-bs-target="#accordion-item-2-1">
          <h6 className="lm-5 title" onClick={() => handleAccordionClick(index)}>{faq.question}</h6><span class="accordion-icon"></span></a>
          {openIndex === index && <div className="accordion-body collapse show" id="accordion-item-2-1" data-bs-parent="#accordion-2"><div class="accordion-inner"><p>{faq.answer}</p></div></div>}
       </div> 
      ))}
	  </div>
      <h4 className="text-center" >Test Administrator</h4>
      <div id="accordion-2" class="accordion accordion-s1">
      {faqi.map((faq, index) => (
        <div className="accordion-item" key={index}>
	  <a href="#" class="accordion-head" data-bs-toggle="collapse" data-bs-target="#accordion-item-2-1">
          <h6 className="lm-5 title" onClick={() => handleAccordionClick(index)}>{faq.question}</h6><span class="accordion-icon"></span></a>
          {openIndex === index && <div className="accordion-body collapse show" id="accordion-item-2-1" data-bs-parent="#accordion-2"><div class="accordion-inner"><p>{faq.answer}</p></div></div>}
       </div> 
      ))}
	  </div>
      <h4 className="text-center" >Test Taker</h4>
      <div id="accordion-2" class="accordion accordion-s1">
      {faqi.map((faq, index) => (
        <div className="accordion-item" key={index}>
	  <a href="#" class="accordion-head" data-bs-toggle="collapse" data-bs-target="#accordion-item-2-1">
          <h6 className="lm-5 title" onClick={() => handleAccordionClick(index)}>{faq.question}</h6><span class="accordion-icon"></span></a>
          {openIndex === index && <div className="accordion-body collapse show" id="accordion-item-2-1" data-bs-parent="#accordion-2"><div class="accordion-inner"><p>{faq.answer}</p></div></div>}
       </div> 
      ))}
	  </div>
    </div>
    </div>
    </div>
  );
};

const faqs = [
  {
    question: 'What is MonitorExam digital proctoring software?',
    answer:
      'MonitorExam is an online tool that uses technology to monitor and record exam-taking sessions. It includes features like video recording, screen monitoring, and identity verification to ensure the integrity of the exam.',
  },
  {
    question: 'How does MonitorExam digital proctoring software work?',
    answer:
      'MonitorExam digital proctoring software works by monitoring test-takers behavior during an exam using a variety of techniques. This includes video recording, screen monitoring, and identity verification. The software then analyzes the data collected to detect any suspicious behavior or anomalies that could indicate cheating or misconduct.',
  },
  {
    question: 'What are the benefits of using MonitorExam digital proctoring software?',
    answer:
      'The benefits of using MonitorExam digital proctoring software include increased exam security, greater flexibility and convenience for test-takers, and reduced administrative burden for exam administrators. It also offers a customizable platform with various features to fit specific exam needs.',
  },
  {
    question: 'Is MonitorExam digital proctoring software reliable?',
    answer:
      'MonitorExam digital proctoring software is generally reliable, as it is designed with the latest technology and monitoring tools to ensure exam integrity. However, like any technology, it may occasionally encounter glitches or errors.',
  },
  {
    question: 'Is MonitorExam digital proctoring software ethical?',
    answer:
      'The ethics of MonitorExam digital proctoring software are considered ethical as it only collects data that is necessary to monitor exam-taking behavior, and it follows industry standards and regulations to ensure exam integrity. It also protects test-takers privacy and accommodates test-takers with disabilities.',
  },
  {
    question: 'How does MonitorExam digital proctoring software protect test-takers privacy?',
    answer:
      'MonitorExam digital proctoring software is designed to protect test-takers privacy by only collecting data that is necessary to monitor exam-taking behavior. It also adheres to industry standards and regulations regarding data protection and privacy, and it encrypts data to ensure it remains secure.',
  },
  {
    question: 'How does MonitorExam digital proctoring software accommodate test-takers with disabilities?',
    answer:
      'MonitorExam digital proctoring software provides alternative methods for monitoring behavior, such as audio monitoring instead of video monitoring, to accommodate test-takers with disabilities. Additionally, the software is designed to be compatible with assistive technology, and the company is committed to making reasonable accommodations for test-takers with disabilities, as required by law.',
  },
  {
    question: 'What support options are available for MonitorExam users?',
    answer:
      'MonitorExam provides comprehensive support options for users, including email support, live chat support, and phone support during business hours.'
  }
];
const faqi = [
  {
    question: 'Can I schedule exam later?',
    answer:'Yes, you can. Please do not enter the date and time of the exam while creating the exam. It will then be visible in unscheduled exams section.'
  },
  {
    question: 'Can I reschedule an exam?',
    answer:'At present we do not provide capability to re-schedule an exam. However you can create a new exam at the new time.'
  },
  {
    question: 'Can I conduct exams in regional languages like Kannada?',
    answer:'Yes, the exam will be visible in the regional language but the candidate needs to know some English to use the software in English',
  },
  {
    question: 'Can I export my existing google forms to this app?',
    answer:'Yes, you can use your existing google forms software to this. While creating the exam use the option Online and in the link put an embeddable google link.',
  },
  {
    question: 'Can I export use existing word document tests on this?',
    answer:'This is available only on request. You can upload them on a storage location like google drive and select the option google PDF in test creation'
  },
  {
    question: 'Can I know when a student is using google to find answers?',
    answer:'Yes, if the student is trying to do some mischief on his device you will see those students on Monitor Dashboard as "Students Needing Attention"'
  },
];
const faqt = [
  {
    question: 'What all equipment do I need to give the exam?',
    answer:'You need a laptop with broadband connectivity, camera, power-backup, UPS backed-up router to give the exam. Other material requirements like calculator, paper, pen etc will depend on the requirements of the exam.',
  },
  {
    question: 'Can a proctor see my screen?',
    answer:'Yes, a proctor can see your screen when they notice that you have been distracted for a while.',
  },
  {
    question: 'What will happen if I log Off due to connectivity failure?',
    answer:'You can log back in and continue the exam from where you left. The exam will end at the stipulated time.',
  },
  {
    question: 'My verification is continuously failing. What should I do?',
    answer:'Please inform your invigilator about the same via chat. The invigilator will give you an OTP and using that you can start writing the exam. The invigilator will verify you by alternate means during the exam',
  },
  {
    question: 'What should I check before starting my exam?',
    answer:'1. A working camera should be able to click your photo.  2. Admit Card                                         3. Noiseless, clutter-free Desk                        4. Power backup for computer and internet router       ' 
  },
  {
    question: 'How do I ensure that I am comfortable with the platform during the exam?',
    answer:' 1. A one-time pre-verification step is there for all new users which can be done anytime. This makes you experience the exam day verification process well in advance so there is no fear of software undertainty on exam day.  2. Go through our User Manual carefully before the exam day 3. Join 10 minutes in advance to ensure that you are not in a state of anxiety while entering 4. In case of doubt ask your invigilator on chat                       ' 
  },
  ];

export default FAQ
