import React, { Component } from 'react';
import Pubnub  from './Pubnub'; //student chat

class MinChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minimized: false
    };
    this.toggleMinimize = this.toggleMinimize.bind(this);
  }

  toggleMinimize() {
    this.setState(prevState => ({
      minimized: !prevState.minimized
    }));
  }

  render() {
    const { minimized } = this.state;
    
    return (
      <div className={`minimizable-component ${minimized ? 'minimized' : ''}`}>
         <a className="btn btn-icon" onClick={this.toggleMinimize} style={{color:"#6576ff"}}><div class="icon expanded"><i class="ni ni-chat-circle"></i></div>{minimized ? 'Chat with Proctor' : 'Click to Minimize Chat'}</a>
	    
        {!minimized && (
          <div className="content">
            {/* Your component content here */}
            <Pubnub uuid={this.props.uuid} />
          </div>
        )}
      </div>
    );
  }
}

export default MinChat;

