import React, {Component} from 'react'
import Footer from '../Home/Footer';
class AboutUs extends React.Component {
  constructor(){
		super();
		this.state = {
                status: false,
		open: false
            };
  }
  render() {
    return (
      <div>
        {/*  Last Published: Mon Feb 19 2024 04:04:46 GMT+0000 (Coordinated Universal Time)  */}
        <meta charSet="utf-8" />
        <title>About</title>
        <meta content="At MonitorExam, the vision is to make test administration a calm and stress-free experience. Founded by Manu Gupta, MonitorExam is on a mission to transform the way exams are conducted. With a focus on student well-being, MonitorExam offers a seamless and stress-free proctoring solution for colleges and tutoring services" name="description" />
        <meta content="About" property="og:title" />
        <meta content="At MonitorExam, the vision is to make test administration a calm and stress-free experience. Founded by Manu Gupta, MonitorExam is on a mission to transform the way exams are conducted. With a focus on student well-being, MonitorExam offers a seamless and stress-free proctoring solution for colleges and tutoring services" property="og:description" />
        <meta content="About" property="twitter:title" />
        <meta content="At MonitorExam, the vision is to make test administration a calm and stress-free experience. Founded by Manu Gupta, MonitorExam is on a mission to transform the way exams are conducted. With a focus on student well-being, MonitorExam offers a seamless and stress-free proctoring solution for colleges and tutoring services" property="twitter:description" />
        <meta property="og:type" content="website" />
        <meta content="summary_large_image" name="twitter:card" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <link href="../assets/css/normalize.css" rel="stylesheet" type="text/css" />
        <link href="../assets/css/webflow.css" rel="stylesheet" type="text/css" />
        <link href="../assets/css/monitorexam-4f64b7a40832b476405c7c490d4.webflow.css" rel="stylesheet" type="text/css" />
        <link href="https://fonts.googleapis.com" rel="preconnect" />
        <link href="https://fonts.gstatic.com" rel="preconnect" crossOrigin="anonymous" />
        <link href="../images/website/favicon.png" rel="shortcut icon" type="image/x-icon" />
        <link href="../images/website/webclip.png" rel="apple-touch-icon" />
        <div data-animation="default" className="navlink white link-2 w-nav" data-easing2="ease-in" data-easing="ease-out" data-collapse="all" role="banner" data-duration={400} data-doc-height={1} sr>
          <div className="w-container">
            <a href="/home" className="logo-block w-nav-brand"><img src="../images/website/monitor-exam-logox2.png" alt="" className="logo fixed link-2" /></a>
            <address role="navigation" className="nav-menu w-nav-menu">
		      <a href="/product" className="product w-nav-link">PRODUCT</a>
		      <a href="/about" aria-current="page" className="about w-nav-link w--current">ABOUT</a>
		      <a href="/pricing" className="pricing w-nav-link">PRICING</a>
		      <a href="https://talk.monitorexam.com" className="blog w-nav-link">BLOG</a>
		      <a href="/contact" className="contact w-nav-link">CONTACT</a>
	              <button onClick={()=>window.location.href='/newLogin'} className="user-log-in-log-out" data-wf-user-logout="LOGOUT" data-wf-user-login="LOGIN" type="button">LOGIN</button>
            </address>
          </div>
            <div className="fixed-nav-menu-button w-nav-button" onClick={()=>this.setState({open:!this.state.open})}>
            <div className="icon w-icon-nav-menu" />
          </div>
        </div>
	  <div class="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0" style={{height:this.state.open?"2846px": "0px", display:this.state.open?"block": "none"}}>
	      <address role="navigation" class="nav-menu w-nav-menu" style={{transform: "translateY(0px) translateX(0px)", transition: "transform 400ms ease-out 0s;"}} data-nav-menu-open="">
                <a href="/product" className="product w-nav-link w--nav-link-open">PRODUCT</a>
                <a href="/about" className="about w-nav-link w--nav-link-open">ABOUT</a>
                <a href="/pricing" className="pricing w-nav-link w--nav-link-open">PRICING</a>
                <a href="https://talk.monitorexam.com" className="blog w-nav-link w--nav-link-open">BLOG</a>
                <a href="/contact" className="contact w-nav-link w--nav-link-open">CONTACT</a>
	      <button className="user-log-in-log-out" data-wf-user-logout="LOGOUT" data-wf-user-login="LOGIN" onClick={()=>window.location.href='/newLogin'}type="button">LOGIN</button>
        </address></div>
        <div className="subpage-header about-2">
          <div className="container w-container">
            <h2 data-ix="fade-in-on-load" className="subpage-title pt-5">About MonitorExam</h2>
            <div data-ix="fade-in-on-load-2" className="page-subtitle">At MonitorExam, the vision is to make test administration a calm and stress-free experience.<br />Founded by Manu Gupta, MonitorExam is on a mission to transform the way exams are conducted. With a focus on student well-being, MonitorExam offers a seamless and stress-free proctoring solution for colleges and tutoring services</div>
          </div>
        </div>
        <div className="section tint">
          <div className="container w-container">
            <div className="overall-row w-row">
              <div className="about-us-col-left w-col w-col-6 w-col-stack w-col-tiny-tiny-stack">
                <h2 className="section-title">Our Story</h2>
                <div className="section-description">In 2020, as online education boomed, Manu recognized the negative impact exam environment had on students. After dedicating over 2500 hours, MonitorExam was launched in February 2022, providing a fully functional proctoring software with auto-proctoring capabilities aimed at creating a safe space for conducting exams credibly.</div>
                <a href="https://web.monitorexam.com/contact" target="_blank" className="button w-button">Get in touch with us</a>
              </div>
              <div className="image-column-right w-col w-col-6 w-col-stack w-col-tiny-tiny-stack" />
            </div>
          </div>
        </div>
        <section className="features-list">
          <div className="container-6">
            <div className="features-wrapper-two">
              <div className="features-left">
                <h3>Benefits</h3>
                <p className="features-paragraph">MonitorExam is a platform which keeps in mind the convenience of test administrators and test-takers. </p>
                <a href="#" className="text-link-arrow w-inline-block">
                  <div>Learn more</div>
                  <div className="arrow-embed w-embed"><svg width={17} height={17} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.13 8.5L6.87296 4.24291L5.87067 5.24379L9.129 8.5L5.87067 11.7555L6.87225 12.7571L11.13 8.5Z" fill="currentColor" />
                    </svg></div>
                </a>
              </div>
              <ul role="list" className="features-right w-list-unstyled">
                <li className="features-block-two"><img src="../images/website/Verification.png" loading="lazy" alt="" className="features-image" />
                  <p>For student convenience there is only <strong>one verification page</strong>, that too can be by-passed with OTP&nbsp;under exceptional circumstances.</p>
                </li>
                <li className="features-block-two"><img src="../images/website/Screenshot-2023-08-24-134426.png" loading="lazy" alt="" className="features-image" />
                  <p><strong>Award winning Security with Privacy</strong>. MonitorExam is the first solution to implement MFA where biometric data is maintained locally to ensure greater privacy to the test-taker</p>
                </li>
                <li className="features-block-two"><img src="../images/website/trial1.jpg" loading="lazy" alt="" className="features-image" />
                  <p><strong>Supports non LMS&nbsp;based test delivery options</strong> like Google Forms, typeForm, JotForm. This enables professors to secure their test papers and they have more flexibility and freedom</p>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="hero-stack">
          <div className="container-2">
            <div className="hero-wrapper-two">
              <h1>Our Journey so far</h1>
              <p className="margin-bottom-24px"><br />Winning the Secure Dev Challenge in 2022, MonitorExam's commitment to innovation and security is evident. Designed by a team with 75 years of experience, MonitorExam prioritizes student well-being and incorporates cutting-edge security features. Join the growing community of institutions and educators who trust MonitorExam for calm and secure online exams. Experience hassle-free integration, customizable options, and on-the-go scheduling, tailored to meet the unique needs of the US market. Discover how MonitorExam can transform your exam administration today.</p>
              <a href="https://bit.ly/monitorexam" className="button-primary w-button">talk to us</a><img src="../images/website/MonitorExam-04.jpg" loading="lazy" alt="" className="hero-image shadow-two" />
            </div>
          </div>
        </section>
        <div className="section cta-section">
          <div className="container w-container">
            <h4 className="dark-cta-title">Join us in our mission of supporting 1 million students be able to give scholarship exams at a deeply discounted price</h4>
            <a href="https://bit.ly/monitorexam" className="button cta-button w-button" data-ix="open-sign-up-popup">schedule demo</a>
          </div>
        </div>
        <section className="testimonial-image-left">
          <div className="container-9">
            <div className="testimonial-wrapper-four-2">
              <div className="testimonial-left-2"><img src="../images/website/anit.jpg" loading="lazy" alt="" className="testimonial-image-four-2" /></div>
              <div className="testimonial-right-2">
                <div className="heading-small-2 margin-bottom-32px">We got AISCT&nbsp;2022 conducted by MonitorExam</div>
                <p className="testimonial-text-four-2">“We found the MonitorExam team structured, collaborative and systematic. They were willing to adapt. They agreed to add additional functionality like practice test, on our request, in a timely manner. They conducted office hours for students to get them comfortable with the platform, provided live proctoring and later a credibility report of the AISCT Olympiad. In short Though the team is small the experience was similar to dealing with an established organization.”</p>
                <h3 className="testimonial-author-name-three-2">Anit Gupta</h3>
                <div className="testimonial-title-2">Co-Founder and CEO of ARC</div>
              </div>
            </div>
          </div>
        </section>
        <section className="team-circles">
          <div className="container-6">
            <h2 className="centered-heading">Team section</h2>
            <p className="centered-subheading">We are currently boot-strapped and driven by purpose. We hire based on merit and passion and that is what keeps them going</p>
            <div className="team-grid">
              <div id="w-node-_13f2de6e-95c4-85ec-ffb5-e90e5a999182-2aadaa2b" className="team-card"><img src="../images/website/manu.jpg" loading="lazy" alt="" className="team-member-image-2" />
                <div className="team-member-name-2">Manu Gupta</div>
                <div className="team-member-position">Founder at MonitorExam<br />‍Previously: </div>
                <p>Manu is a passionate entrepreneur who loves to build technology services which are based on calm technology and context awareness , These improve lives of the people. <br />She is an engineer from NIT&nbsp;Kurukshetra and has done Business Management from IIM&nbsp;Calcutta. <br />She is a mom of a middle schooler and an Undergraduate Student.<br />In her free time she loves to talk</p>
              </div>
              <div id="w-node-_13f2de6e-95c4-85ec-ffb5-e90e5a99918d-2aadaa2b" className="team-card"><img src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a55c612aae2_portfolio%202%20-%20wide.svg" loading="lazy" alt="" className="team-member-image-2" />
                <div className="team-member-name-2">Pramukh MG</div>
                <div className="team-member-position">Founder's Office<br />‍Previously: </div>
                <p>Pramukh is a passionate seeker and is honing his entrepreneurial skills in all aspects of building an organization be it sales which he knows very well and product which he is very curious to learn.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="footer-dark-2">
          <div className="container-5">
            <div className="footer-wrapper-2">
              <a href="#" className="footer-brand-2 w-inline-block"><img src="../images/website/monitor-exam-logox2.png" loading="lazy" width={150} height={60} alt="" className="image-3" /></a>
              <div className="footer-content-2">
                <div id="w-node-eeb73b85-36b4-cbd8-d791-d54cf9e8c0b1-2aadaa2b" className="footer-block-2">
                  <div className="title-small-2">Company</div>
                  <a href="https://monitorexam.com/howitworks" className="footer-link-3">How it works</a>
                  <a href="https://monitorexam.com/pricing" className="footer-link-3">Pricing</a>
                  <a href="#" className="footer-link-3">Docs</a>
                </div>
                <div id="w-node-eeb73b85-36b4-cbd8-d791-d54cf9e8c0ba-2aadaa2b" className="footer-block-2">
                  <div className="title-small-2">Resources</div>
                  <a href="https://talk.monitorexam.com/untitled-3/" className="footer-link-3">How to create a quiz in typeform</a>
                  <a href="#" className="footer-link-3">See all resources &gt;</a>
                  <a href="https://talk.monitorexam.com/proctoring-in-colleges-earn-while-you-learn/Proc" className="footer-link-3">Proctor in Colleges, Earn while you learn</a>
                  <a href="https://talk.monitorexam.com/untitled-3/" target="_blank" className="footer-link-3">How MonitorExam Ensures both Security and Privacy</a>
                </div>
                <div id="w-node-eeb73b85-36b4-cbd8-d791-d54cf9e8c0c5-2aadaa2b" className="footer-block-2">
                  <div className="title-small-2">About</div>
                  <a href="https://monitorexam.com/terms" className="footer-link-3">Terms &amp; Conditions</a>
                  <a href="https://monitorexam.com/privacy" className="footer-link-3">Privacy policy</a>
                  <div className="footer-social-block-2">
                    <a href="https://twitter.com/monitorexam" className="footer-social-link-2 w-inline-block"><img src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124ac15112aad5_twitter%20small.svg" loading="lazy" alt="" className="footer-social-link footer-link-3" /></a>
                    <a href="https://linkedin.com/company/monitorexam" className="footer-social-link-2 w-inline-block"><img src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a389912aad8_linkedin%20small.svg" loading="lazy" alt="" className="footer-link-3" /></a>
                    <a href="https://instagram.com/monitorexam" className="footer-social-link-2 w-inline-block"><img src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a51bf12aae9_facebook%20small.svg" loading="lazy" alt="" className="bottom-footer-link footer-link-3" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-divider-2" />
          <div className="footer-copyright-center-2">Copyright © 2014-2024 Innokreat Technologies Pvt Ltd</div>
        </section>
      </div>
    );
  }
}
export default AboutUs
