
import React, {
    Component,
    useState,
    useEffect
} from 'react'
import ReactDOM from 'react-dom'
import Http from 'axios'
import Button from 'react-bootstrap/Button'
import Base64js from 'base64-js'

class WebAuthnRegister extends React.Component {
        constructor() {
            super();
	    this.state={
			WebAuthnSupport:false,
		        first_name:'rajiv'
		};
	    this.handleClick = this.handleClick.bind(this);
        }
        componentWillMount() {
		this.state.WebAuthnSupport=(window.PublicKeyCredential===undefined)
		console.log("Webauthn Support is "+ !this.state.WebAuthnSupport);
	}
        handleChange = (event) => {
            let nam = event.target.name;
            let val = event.target.value;
            this.setState({
                [nam]: val
            });
        }
	async componentDidMount() {
	    const headers = {
		      'Access-Control-Allow-Origin': '*',
		      'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
		      'Access-Control-Allow-Headers': 'Origin,X-Requested-With,Content-Type,Accept,Authorization',
		};
            const url='makeCredential/'
			+ this.state.first_name 
			+'?attType=direct&authType=&userVerification=discouraged&residentKeyRequirement=false&txAuthExtension=';

            //const  response = await Http.get( 'https://webauthn.io/makeCredential/rajiv',{params:{attType:'direct',authType:'',userVerification:'discouraged',residentKeyRequirement:false,txAuthExtension:''}},{headers});
            //const  response = await Http.get( url,{headers});
            /*Http.get( url,{headers})
		.then(makeCredentialOptions=> {            
	            makeCredentialOptions.data.publicKey.challenge = this.bufferDecode(makeCredentialOptions.data.publicKey.challenge);
	            makeCredentialOptions.data.publicKey.user.id = this.bufferDecode(makeCredentialOptions.data.publicKey.user.id);
	            if (makeCredentialOptions.data.publicKey.excludeCredentials) {
		       for (var i = 0; i < makeCredentialOptions.data.publicKey.excludeCredentials.length; i++) {
		           makeCredentialOptions.data.publicKey.excludeCredentials[i].id = this.bufferDecode(makeCredentialOptions.data.publicKey.excludeCredentials[i].id);
		       }
		    }
		    console.log("Credential Creation Options");
		    console.log(makeCredentialOptions.data);
		    navigator.credentials.create({
		         publicKey: makeCredentialOptions.data.publicKey
		    }).then(newCredential=> {
		         console.log("PublicKeyCredential Created");
		         console.log(newCredential);
		         this.state.createResponse = newCredential;
		         this.registerNewCredential(newCredential);
		    }).catch((err)=> {
		         console.info(err);
		    });
		});*/
		//navigator.credentials.create({ publicKey })
		//        .then((newCredentialInfo) => {
		//        console.log('SUCCESS', newCredentialInfo)
		//        })
		//        .catch((error) => {
		//		            console.log('FAIL', error)
		//        })

            //console.log(response);
	    //const publicKey = response.data.publicKey;
	}
	bufferDecode(value) {
	    return Uint8Array.from(atob(value), c => c.charCodeAt(0));
	}

	// This should be used to verify the auth data with the server
	// step 5 of the mozilla doc
	registerNewCredential(newCredential) {
	         // Move data into Arrays incase it is super long
	         let attestationObject = new Uint8Array(newCredential.response.attestationObject);
	         let clientDataJSON = new Uint8Array(newCredential.response.clientDataJSON);
		 //globally unique identifier of the user
	         let rawId = new Uint8Array(newCredential.rawId);
		 rawId = this.bufferEncode(rawId);
	
	         Http.post('makeCredential', { 
		     id: newCredential.id,
	             rawId: rawId,
	             type: newCredential.type,
		     response: {
		        attestationObject: this.bufferEncode(attestationObject),
		        clientDataJSON: this.bufferEncode(clientDataJSON),
		     },
		 })
		 .then(res=>{
			 console.log(res);
		 })
	         .catch(error=> {
			 console.log(error);
		 });
	 } 
	// Encode an ArrayBuffer into a base64 string.
	// TODO: To verify this
	 bufferEncode(value) {
           // return Buffer.from(value).toString('base64');
	     return Base64js.fromByteArray(value)
	        .replace(/\+/g, "-")
	        .replace(/\//g, "_")
	        .replace(/=/g, "");
	 }
	checkUserExists() {
		    Http.get('/user/' + this.state.first_name + '/exists', {}, null, 'json')
		        .then(response=> {
				console.log("User Exists");
				            return true;
				        })
			.catch(error=> {
				console.log("User does not Exist");
				            return false;
				       });
	}


	handleSubmit=async event=> {
	}
	async handleClick(){
	    const headers = {
		      'Access-Control-Allow-Origin': '*',
		      'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
		      'Access-Control-Allow-Headers': 'Origin,X-Requested-With,Content-Type,Accept,Authorization',
		};
            const url='makeCredential/'
			+ this.state.first_name
			+'?attType=direct&authType=&userVerification=discouraged&residentKeyRequirement=false&txAuthExtension=';

            //const  response = await Http.get( 'https://webauthn.io/makeCredential/rajiv',{params:{attType:'direct',authType:'',userVerification:'discouraged',residentKeyRequirement:false,txAuthExtension:''}},{headers});
            //const  response = await Http.get( url,{headers});
            Http.get( url,{headers})
		.then(makeCredentialOptions=> {            
	            makeCredentialOptions.data.publicKey.challenge = this.bufferDecode(makeCredentialOptions.data.publicKey.challenge);
	            makeCredentialOptions.data.publicKey.user.id = this.bufferDecode(makeCredentialOptions.data.publicKey.user.id);
	            if (makeCredentialOptions.data.publicKey.excludeCredentials) {
		       for (var i = 0; i < makeCredentialOptions.data.publicKey.excludeCredentials.length; i++) {
		           makeCredentialOptions.data.publicKey.excludeCredentials[i].id = this.bufferDecode(makeCredentialOptions.data.publicKey.excludeCredentials[i].id);
		       }
		    }
		    console.log("Credential Creation Options");
		    console.log(makeCredentialOptions.data);
		    navigator.credentials.create({
		         publicKey: makeCredentialOptions.data.publicKey
		    }).then(newCredential=> {
		         console.log("PublicKeyCredential Created");
		         console.log(newCredential);
		         this.state.createResponse = newCredential;
		         this.registerNewCredential(newCredential);
		    }).catch(err=> {
		         console.log(err);
		    });
		});
		//TODO: make credential will use the value entered by the user
            console.log("form submitted");
            //const url='user-api/usere/' + this.state.email + '/' + this.state.password
            //if(!(this.state.email===undefined)) {
            //const url='https://webauthn.io/makeCredential/'
	    //+ 'rajiv' 
	    //+'?attType=direct&authType=&userVerification=discouraged&residentKeyRequirement=false&txAuthExtension=';
            //const  response = await Http.get( 'https://webauthn.io/makeCredential/rajiv',{params:{attType:'direct',authType:'',userVerification:'discouraged',residentKeyRequirement:false,txAuthExtension:''}});
		/*.then(res => {
                    console.log(res);
                    const response = res.data;
                    console.log(response);
                }).catch(function(error) {
		            console.log(error);
		});*/
		//}
		//else {
		//	console.log("Email not defined");
		//}
	}

        render() {
		return (<div>
			<h5>Hello Webauthn</h5>
			<input type='text' placeholder='Enter name' name='first_name' onChange={this.handleChange} style={{marginBottom:'4px'}} required autoFocus></input>
		        <br></br>
			<Button style={{backgroundColor:"#6576ff",paddingTop:"10px", alignSelf:"right",border:"none"}} variant="primary" type='submit' placeholder='Register Device' size="lg" onClick={this.handleClick} required>Register Device</Button><br />
			</div>);
	}
}
export default WebAuthnRegister
