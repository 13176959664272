import React, {
    Component,
    useState,
    useEffect
} from 'react'
import Http from 'axios'
import NewLogin from './NewLogin'
import Base64js from 'base64-js'
/*
1. Show the reset window
2. If user exists then send a message with OTP to reset password
*/

class NewResetPassword extends React.Component {
    constructor() {
        super();
        this.state = {
            status: false,
            displayToast:true,
            enterOtp:false,
            changePassword:false,
            menu:false
        };
        //this.state={name:"She",email:"she@email.com",mobile:"1111122222"};
    }
//TODO: This needs to be removed
    componentWillMount() {
        const user = JSON.parse(localStorage.getItem('user'));
        this.state.user = user;
        if (user) this.state.status = true;
    }
    handleChange = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({
            [nam]: val
        });
    }
    SendOtp = () => {
      const step1=document.getElementById("step1");
      const listep1=document.getElementById("listep1");
      const countstep1=document.getElementById("countstep1");
      const step2=document.getElementById("step2");
      const listep2=document.getElementById("listep2");
      const countstep2=document.getElementById("countstep2");
  
      Http.put('user-api/usero/'+this.state.email)
	    .then(res=>{
                    const response = res.data;
                    const errorMsgElement = document.querySelector('span#errorMsg');
                    errorMsgElement.innerHTML = response;
      		          console.log("OTP Sent");
		                this.setState({enterOtp:true});
                    step1.classList.add("noactive");
                    listep1.classList.remove("active");
                    countstep1.classList.remove("active-num");
                    step2.classList.remove("noactive");
                    listep2.classList.add("active");
                    countstep2.classList.add("active-num");
            
	    })
      .catch(function(error) {
        const errorMsgElement = document.querySelector('span#errorMsg');
            if(error.message.includes("401"))
            {
                errorMsgElement.innerHTML = "Incorrect username";
            }
            else {
                errorMsgElement.innerHTML = error;
            }
            console.log(error);
        });
    }
    VerifyOtp = () => {
      const step1=document.getElementById("step1");
      const listep1=document.getElementById("listep1");
      const countstep1=document.getElementById("countstep1");
      const step3=document.getElementById("step3");
      const listep3=document.getElementById("listep3");
      const countstep3=document.getElementById("countstep3");
      const step2=document.getElementById("step2");
      const listep2=document.getElementById("listep2");
      const countstep2=document.getElementById("countstep2");
  
        const errorMsgElement = document.querySelector('span#errorMsg');
        errorMsgElement.innerHTML = "Verifying";
        Http.get('user-api/userv/' + this.state.email + '/' + this.state.otp)
            .then(res => {
                const response = res.data;
                        const errorMsgElement = document.querySelector('span#errorMsg');
                this.setState({
                    user: response
                });
                errorMsgElement.innerHTML = "OTP Verified";
                console.log("OTP Verified");
                this.setState({changePassword:true});
                step2.classList.add("noactive");
                listep2.classList.remove("active");
                countstep2.classList.remove("active-num");
                step3.classList.remove("noactive");
                listep3.classList.add("active");
                countstep3.classList.add("active-num");  
      })
        .catch(function(error) {
            const errorMsgElement = document.querySelector('span#errorMsg');
            errorMsgElement.innerHTML = "OTP Verification failed";
            console.log(error);
            this.setState({changePassword:false});
            step2.classList.add("noactive");
            listep2.classList.remove("active");
            countstep2.classList.remove("active-num");
            step1.classList.remove("noactive");
            listep1.classList.add("active");
            countstep1.classList.add("active-num");  
          });
        
    }
    handleSubmit = (event) => {
        event.preventDefault();
        const errorMsgElement = document.querySelector('span#errorMsg');
        var password;
        password = this.state.password;
        if(this.state.password!=this.state.rePassword)
        {
          errorMsgElement.innerHTML="Passwords do not match";
          return;
        }
        else if(this.state.changePassword)
        {
          Http.defaults.headers.common['Authorization'] = this.state.user.token;
          Http.put('user-api/user/' + this.state.user.id, {
              "password": this.state.password,
          }, {
              headers: {
                  "Content-Type": "application/json"
              }
          })
          .then(res => {
              const response = res.data;
              this.setState({
                  user: response
              });
              this.setState({
                  status: true
              });
              localStorage.setItem('user', JSON.stringify(response));
              const errorMsgElement = document.querySelector('span#errorMsg');
              errorMsgElement.innerHTML = "Password changed Successfully";
          })
          .catch(function(error) {
              const errorMsgElement = document.querySelector('span#errorMsg');
              errorMsgElement.innerHTML = error;
              console.log(error);
          });
        }
    }
    toggleMenu() {
        this.setState({menu:!this.state.menu});  
    }  
    render() {
        const show = this.state.menu ? "active" : "";
        const showMenu = this.state.menu ? "content-active" : "";

        if (this.state.status === false) {
      return (
        <div>
          <meta charSet="utf-8" />
          <meta name="author" content="Softnio" />
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          <meta name="description" content="@@page-discription" />
          {/* Fav Icon  */}
          <link rel="shortcut icon" href="../images/favicon.png" />
          {/* Page Title  */}
          <title>Registration | Monitor Exam</title>
          {/* StyleSheets  */}
          <link rel="stylesheet" href="../assets/css/style.css?ver=1.2.0" />
          <link rel="stylesheet" href="../assets/css/custom.css" />
          <link id="skin-default" rel="stylesheet" href="../assets/css/theme.css?ver=1.2.0" />
          {/*Header*/}
          <header className="header">
            <div className="container">
              <a href className="logo"><img src="../images/monitor-exam-logo.png" alt="logo" width={180} /></a>
              <input className="menu-btn" type="checkbox" id="menu-btn" />
              <label className="menu-icon" htmlFor="menu-btn"><span className="navicon" /></label>
              <ul className="menu">
                <li><a href="\">Home</a></li>
                <li><a href="\pricing">Pricing</a></li>
                <li><a href="\contact">Contact</a></li>
                <li><a href="\faq">FAQ's</a></li>
                <li><a href="\howitworks">How It Works</a></li>
                <li><a href="\newiregister" className="btn btn-lg btn-outline-primary header-btn login">Register</a></li>
                <li><a href="\newlogin" className="btn btn-lg btn-primary header-btn ">Login</a></li>
              </ul>
            </div>
          </header>
          {/* app body @s */}
          <div className="nk-app-root">
            <div className="nk-split nk-split-page nk-split-md">
              <div className="nk-split-content nk-block-area-column nk-auth-container bg-lighter mt-80">
                <div className="nk-block nk-block-middle nk-auth-body">
                  <div className="nk-block-head">
                    <div className="nk-block-head-content">
                      <h5 className="nk-block-title">Reset Password</h5>
                      <div className="nk-block-des">
                        <p>Proctor your online exams with <strong><em>Monitor Exam.</em>
                          </strong></p></div><strong>
                      </strong></div><strong>
                    </strong></div>{/* .nk-block-head */}
                    <div className="nk-block login-process">
                      <div className="row">
                        <div className="col-lg-2">
                          <span className="pro-num count-step1 active-num">1</span>
                        </div>
                        <div className="col-lg-10">
                          <span id="countstep1" className="num-heading">Step 1</span>
                          <p>Enter your username to generate OTP</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-2">
                          <span className="pro-num count-step2">2</span>
                        </div>
                        <div className="col-lg-10">
                          <span id="countstep2" className="num-heading">Step 2</span>
                          <p>Verify OTP </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-2">
                          <span id="countstep3" className="pro-num count-step3">3</span>
                        </div>
                        <div className="col-lg-10">
                          <span className="num-heading">Step 3</span>
                          <p>Enter new password</p>
                        </div>
                      </div>
                    </div>                    
                  </div>{/* .nk-block */}<strong>
                  {/* nk-block */}
                </strong></div>{/* nk-split-content */}
                <div className="nk-split-content nk-split-stretch bg-lighter d-flex" data-content="athPromo" data-toggle-screen="lg" data-toggle-overlay="true">
                <div class="nk-block nk-auth-body mt-80">
                    <div class="nk-block-head">
                        <div class="nk-block-head-content">
                        <div class="nk-block-des"><p>Enter your username below and check your mailbox.</p></div>
                        </div>
                    </div>
                    <ul className="progressbar">
                      <li id="listep1" className="count-step1 active">Step 1</li>
                      <li id="listep2" className="count-step2 ">Step 2</li>
                      <li id="listep3" className="count-step3 ">Step 3</li>
                    </ul>
                    <form onSubmit={this.handleSubmit}>
                    <span id="errorMsg" className="badge badge-dim badge-outline-info "></span>
                      <div id="step1" className="row step1">
                        <div class="form-group">
                          <div class="form-label-group">
                            <label class="form-label" for="default-01">Username</label>
                          </div>
                          <div class="form-control-wrap">
                              <input type="text" name="email" class="form-control form-control-lg" id="default-01" placeholder="Enter your username" onChange={this.handleChange} />
                          </div>
                        </div>
                        <div class="form-group">
                          <button class="btn btn-lg btn-primary btn-block" onClick={this.SendOtp}>Send OTP</button>
                        </div>
                      </div>
                      <div id="step2" className="row step2 noactive">
                        <div class="form-group">
                          <div class="form-label-group">
                            <label class="form-label" for="default-01">OTP</label>
                          </div>
                          <div class="form-control-wrap">
                              <input type="text" name="otp" class="form-control form-control-lg" id="otp" placeholder="Enter OTP received" onChange={this.handleChange} />
	                  </div>
                        </div>
                        <div class="form-group">
                          <button class="btn btn-lg btn-primary btn-block" onClick={this.VerifyOtp}>Verify OTP</button>
                        </div>
                      </div>
                      <div id="step3" className="row step3 noactive">
                        <div class="form-group">
                          <div class="form-label-group">
                            <label class="form-label" for="password">Password</label>
                          </div>
                          <div class="form-control-wrap">
                              <input type="password" name="password" class="form-control form-control-lg" id="password" placeholder="Enter New Password" onChange={this.handleChange} />
	      		  </div>
	                </div>
                        <div class="form-group">
                          <div class="form-label-group">
                            <label class="form-label" for="rePassword">Confirm Password</label>
                          </div>
                          <div class="form-control-wrap">
                              <input type="password" name="rePassword" class="form-control form-control-lg" id="rePassword" placeholder="ReEnter New Password" onChange={this.handleChange} />
	                  </div>
	                </div>
                        <div class="form-group">
                          <button type="submit" class="btn btn-lg btn-primary btn-block" onClick={this.handleSubmit}>Reset Password</button>
                        </div>
                      </div>
                    </form>
                    <div class="form-note-s2 pt-5">
                        <a href="\newlogin"><strong>Return to login</strong></a>
                    </div>
                </div>
                </div>{/* nk-split-content */}
              </div>{/* nk-split */}<strong>
            </strong></div>{/* app body @e */}<strong>
            {/* JavaScript */}
          </strong></div>
      );
        } else {
            return ( < NewLogin message="Password Reset Successful"/ > );
        }
    }
}
export default NewResetPassword
