//Shows the user image and admit card. Then invokes Verify to get rest of the details
import React, {
    Component
} from 'react'
import ReactDOM from 'react-dom'
import VerifyStudent from './Verify'
import Test from './Test'
import Jumbotron from 'react-bootstrap/Jumbotron'
import {
    Redirect
} from 'react-router-dom'
import Http from 'axios'
import './Auth.css'
import Button from 'react-bootstrap/Button'
import ScriptTag from 'react-script-tag'
import TimeSeries from '../Util/TimeSeries'
import Chat from '../Chat/Chat'
import Card from 'react-bootstrap/Card'

/*

            <article id = "test"
            style = {
                {
                    display: this.state.user.role == 'invig' ? 'none' : 'none'
                }
            }
            className = "container" >
            <
            Test exam = {
                this.state.exam
            }
            /> <
            /article>
*/


//An image is clicked every 5 minutes
class Profile extends React.Component {
    constructor() {
        super();
        this.state = {
            first_name: 'Manu',
            last_name: 'Gupta',
            file: null,
            captured: false,
	        face:'visible',
	        battery_state:false,
	        battery_level:5
        };
	    this.handleCapture=this.handleCapture.bind(this);
    }

    componentWillMount() {
    
    	const user = JSON.parse(localStorage.getItem('user'));
        const exam = JSON.parse(localStorage.getItem('exam1'));
        if (user)
            this.state.user = user;
        else if (this.props.user)
            this.state.user = this.props.user;
        else
            console.log("User not available");
        if (exam)
            this.state.exam = exam;
        else if (this.props.exam)
            this.state.exam = this.props.exam;
        else
            console.log("Exam not available");

        //console.log(this.state.user);
        //console.log(this.state.exam);
        
        //Time passed since exam and left
	    const date = Date.parse(this.state.exam.date);
	    const time = Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z");
	    var   now  = Date.now();
	    var   left = (date+time-now)/60000;
	    
	    
	    //minutes_left = Math.floor(((Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z"))/60000)) + this.state.exam.duration - Math.floor((Date.now()/60000));
	    left= Math.floor(left);
	    console.log("Minutes "+left);
	    this.state.left=left;
	    

        
    }
    async componentDidMount() {
        const video = document.getElementById('video');
        const errorMsgElement = document.querySelector('span#errorMsg');
        

        
        const constraints = {
            audio: false,
            video: {
                width: 320,
                height: 180
            }
        };
        try {
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            window.stream = stream;
            video.srcObject = stream;
	    //Will click image once after 5 minutes
		//TODO: May have to remove later
            setTimeout(() => {this.handleCapture() }, 2000);
            this.state.user.camera_state=true;
        } catch (e) {
            console.log(`navigator.getUserMedia error:${e.toString()}`);
            if(`navigator.getUserMedia error:${e.toString()}`.includes("Requested device not found"))
            {
                 errorMsgElement.innerHTML = "Please connect a camera to the computer";
            }
            this.state.user.camera_state=false;
        }
    }
	//Upload the pic of the user
    uploadPic = (e) => {
        const errorMsgElement = document.querySelector('span#errorMsg');

        var data = new FormData();
        this.setState({
            file: URL.createObjectURL(e.target.files[0])
        });
        data.append('file', e.target.files[0]);
        Http.post('user-api/usera', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(res => {
            errorMsgElement.innerHTML = res.data;
		    if(res.data.includes('not'))
                          errorMsgElement.innerHTML = "Photo Verification Failed";
		    else
                          errorMsgElement.innerHTML = "Photo Verified";
            })
            .catch(function(error) {
                console.log(error);
            });

    }
    
    //upload a file code and save the link in DB
    uploadAdmitCard = (e) => {
        const errorMsgElement = document.querySelector('span#errorMsg');
        var data = new FormData();
        data.append('file', e.target.files[0]);
        console.log(e.target.files[0]);
        this.setState({
            fileAdmit: URL.createObjectURL(e.target.files[0])
        });
        console.log(this.state.fileAdmit);
        var api="exam-api/examac/" + this.state.user.id + "/" + this.state.exam.id ;
        Http.put(api, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(res => {
                errorMsgElement.innerHTML = res.data;
                var tout;
                if(this.state.user.camera_state && this.state.captured && this.state.fileAdmit && this.state.left>0) 
                {
                     tout=setTimeout(()=>{document.getElementById("wait").href="/test";document.getElementById("wait").innerHTML="Enter Now"},this.state.left*60000);
                }

            })
            .catch(function(error) {
                this.setState({
                    fileAdmit: ""
                });
                errorMsgElement.innerHTML = "Admit card upload failed";
                console.log(error);
            });
        }
        updateState = (isAway) => {
            Http.defaults.headers.common['Authorization'] = this.state.user.token;
            Http.put('user-api/user/' + this.props.user.id, {
                    "admit_card": this.state.fileAdmit,
                    "status": this.state.status,
                }, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then(res => {
                    //console.log("User updated Successfully");
                })
        }
        showPosition = (position) => {
            this.state.lat = position.coords.latitude;
            this.state.lng = position.coords.longitude;
        }
        handleCapture = (event) => {
        const video = document.getElementById('video');
        const canvas = document.getElementById('canvas');
        const snap = document.getElementById("snap");
        const errorMsgElement = document.querySelector('span#errorMsg');
        const timeMsgElement = document.querySelector('span#timeMsg');
        const filename = document.querySelector('input#filename');
        const fname = document.querySelector('input#fname');
        const status = document.querySelector('input#status');
         this.setState({
                             captured: true
                        });
        // Draw image
        var context = canvas.getContext('2d');
        context.drawImage(video, 0, 0, 320, 240);
        var blob, url;
        
       
        fname.value = "E"+this.state.exam.id.toString()+"_U"+this.state.user.id.toString()+"_entry";
        console.log(fname.value);
        
        //const url= canvas.toDataURL('image/png').replace('image/png','image/octet-stream');
        canvas.toBlob(function(blob) {
            var newImg = document.getElementById('imageSrc'),
                url = URL.createObjectURL(blob);
            //console.log(url);
            //console.log(blob);
            newImg.src = url;
            
            var data = new FormData();
            var file = new File([blob],fname.value);
            //var file = new File([blob],filename.value);
            //console.log(file);
            data.append('file', file);
            if (blob) {
                Http.post('user-api/usera', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    errorMsgElement.innerHTML = res.data;
		            filename.value = res.data.split(' ',1);
		            if(res.data.includes('not')) { 
			            //status.value="Away";
                        errorMsgElement.innerHTML = "Photo Verification Failed. Click again";
                        status.value="Invalid Photo";

		            }
		            else {
			           //status.value="Present";
                       errorMsgElement.innerHTML = "Photo Valid"+String.fromCharCode("10003");
                       status.value="Photo Valid";
   

		            }


                })
                .catch(function(error) {
                    errorMsgElement.innerHTML = "Failed to save Photo";
                    console.log(error);
 
                });
            }   
            //this.setState({profilePic:blob});         
         });
         
         
         var count=6;
	     var t30=setInterval(()=>{count=count-1;timeMsgElement.innerHTML="Verifying ..."+count+" seconds left";if(count<=0) clearInterval(t30);},1000);
		  
         //Wait for 5 seconds to open verify screen
        setTimeout(() => { 
		//if(status.value==("Photo Valid"+String.fromCharCode("10003")))
		if(filename.value)
		{
		    
			this.state.file=filename.value;
			this.state.user.live_pic=filename.value;
			this.state.user.status = status.value;
			console.log(this.state.file);
			console.log(this.state.user.status);
			ReactDOM.render(<VerifyStudent user={this.state.user} />,document.getElementById("verify"));
		}
	    else
	    {
			this.state.user.status="Not Verified";

	    }
	    }, 5000);

	    //TODO: The code below is currently assumed dead. Need to remove this
        /*if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(canvas.msToBlob(), "pic" + this.state.user.id);
        } else {}*/
    }
    onOpenCvReady() {
        document.getElementById('errorMsg').innerHTML = 'OpenCV.js is ready.';
        console.log("opencv is ready");
    }
    render() {

        return ( <
            div >
            <
            ScriptTag type = "text/javascript"
            src = "opencv.js" / >
            <
            meta name = "viewport"
            content = "width=device-width,initial-scale=1.0" / >
            <
            nav className = "navbar" >
            <
            nav className = "navbar-brand" >
                       	        	<h3><a style={{color:"#fafafa"}} href="/"><img alt="monitorexam" src="/monitorexam_t.png" width="213px" height="120px"></img></a></h3>
            <
            /nav> <
            nav className = "navbar appmenu right" >
            <
            a className = "nav-link"
            style = {
                {
                    color: "#6576ff"
                }
            }
            href = "/examdash" > Exam Dashboard < /a> <
            a className = "nav-link"
            style = {
                {
                    display: this.state.user.role == 'invig' ? 'block' : 'none',
                    color: "#6576ff"
                }
            }
            href = "/form" > Create Exam < /a> <
            a className = "nav-link"
            href = "/login"
            style = {
                {
                    backgroundColor: "#6576ff",
                    color: "white",
                    borderRadius: "30px"
                }
            }
            onClick = {
                () => localStorage.clear()
            } > Logout < /a> <
            /nav> <
            /nav>
		<br />
		<br />
		<br />
		<br />
		<
            p > < span id = "errorMsg" > < /span></p >
            <
            hr / >
            < div className = "row invite" style = { { backgroundColor: "white" } } >
            < div className = "column side" > 
	    < ul >
            < li > < a href = "#profile" > Profile < /a></li >
            < li > < a href = "#test" > Start Test < /a></li >
            < li > < /li> 
		< /ul> 
		< /div> 
		< div className = "column middle" >
            < h4 > {
                this.state.user.first_name
            } {
                this.state.user.last_name
            }'s Verification Room</h4> 
		< span className = "dot" id = "dot" > < a href = "#step1" > 1 < /a></span > < span className = "line" > &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; < /span> <
            span className = "dot" > < a href = "#step2" > 2 < /a></span > < span className = "line" > &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; < /span> <
            span className = "dot" > < a href = "#step3" > 3 < /a></span >
            <
            hr / >
            <
            section className = "flex-container"
            style = {
                {
                    flexDirection: "row"
                }
            } >
            <
            article className = "flex-container"
            id = "profile"
            style = {
                {
                    flexDirection: "row",
                    backgroundColor: "#f7f6f8"
                }
            } >
            <
            div className = "flex-item" >
            <
            div className = "flex-container"
            style = {
                {
                    flexDirection: "column"
                }
            } >
                <Card style={{ width: '23rem', padding: '1rem',backgroundColor:'#f7f6f8' }}>
            <
            h6 id = "step1" > Step 1: Click Picture < /h6> 
		    <div className = "controller" >
	            <input type="hidden" name="filename" id="filename" />
	    	    <input type="hidden" name="fname" id="fname" />

                < Button id = "snap" style = { { backgroundColor: "#6576ff", paddingTop: "10px", align: "right", border: "none", width: "-webkit-fill-available", boundary: "none", margin: '2px' } } variant = "primary" type = 'submit' placeholder = 'Capture' size = "md" required onClick = { this.handleCapture } > Capture < /Button> 
		        < /div> 
		        < input type = 'file' accept = 'image/*' id = 'fileInput' ref = { this.fileInput } onChange = { this.uploadPic } style = {
                    {
                        display: 'none'
                    }
                }
                />
		    <img src={this.state.file} id='imageSrc' style={{height:'320px',width:'240px',border:'none',draggable:'true', backgroundColor:'lightGray',display:'none'}}></img >
            < div className = "video-wrap" >
            < video id = "video" width = "320px" height = "240px" style = { { display: this.state.captured ? "none" : "block" } } playsInline autoPlay > < /video> 
	        < canvas id = "canvas" width = "320px" height = "240px" style = { { display: this.state.captured ? "block" : "none" } } > < /canvas> 
	        <input type="hidden" name="status" id="status" readonly/>    
		    < /div> 
		    </Card>
		    < /div> 
		    < /div> 
		    < div className = "flex-item" >
            < div className = "flex-container" style = { { flexDirection: "column" } } >
            <Card  id="verify" style={{ width: '23rem', padding: '1rem',backgroundColor:'#f7f6f8' }}>
                <            p > < span id = "timeMsg" > < /span></p >
		    </Card>
		    < /div> 
	    < /div> 
		    < div className = "flex-item" >
            < div className = "flex-container accordion" style = {
                {
                    flexDirection: "column"
                }
            } >
                <Card style={{ width: '100%', padding: '1rem',backgroundColor:'#f7f6f8' }}>
            <h6 id = "step3" > Step 3: Upload Admit Card < /h6> 
		    < label className = "button" id = "snap" for = "upload"
            style = {
                {
                    textAlign: "center",
                    backgroundColor: "#6576ff",
                    color: "white",
                    borderRadius: "30px",
                    paddingTop: "10px",
                    align: "right",
                    border: "none",
                    margin: '2px',
                    padding: ".375rem",
                    fontSize: "1rem",
                    lineHeight: "1.5",
                }
            }
            size = "md"
            required > Upload Admit Card < /label> 
		    <input id = "upload" type = 'file' name = 'fileAdmit' disabled = {!this.state.captured} accept = "image/*" onChange = { this.uploadAdmitCard } />
		    <img src={this.state.fileAdmit}   accept="image/ * " id='imageAdmit' style={{height:'240px',width:'320px',border:'none',draggable:'true', display:this.state.fileAdmit?"block":"none",backgroundColor:'lightGray'}}></img> 
		    < hr / >
		    < object data = { this.state.fileAdmit } type = "application/pdf" width = "320px" height = "0px" >
            < p style = { { display: "none" } } > Alternative text - include a link < a href = "myfile.pdf" > to the PDF! < /a></p >
            < /object>
		    </Card>
		    <
            /div> <
            /div> 
	    
		    </article> 
		    <article>
		    <Button style={{display:(this.state.user.camera_state && this.state.captured && this.state.fileAdmit)?"block":"none",backgroundColor:"#6576ff",paddingTop:"10px", align:"right",border:"none",margin:'2px'}} variant="primary" type='submit' placeholder='Wait' size="md" >
			<a id="wait" href={((Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z"))<(Date.now()))?(((Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z")+this.state.exam.duration*60000)>(Date.now()))?'/test':'#'):'#'} style={{color:"white",display:this.state.user.role=='invig'?"none":"block"}}>
			{((Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z"))<(Date.now()))?(((Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z")+this.state.exam.duration*60000)>(Date.now()))?"Enter Exam":"Sorry You are Late"):this.state.left+" minutes left"}
			</a></Button>
		    </article>

		    <article id="chat" className="column right">
		    <Chat />
		    </article>
		    <
            /section> <
            /div> <
            /div> <
            /div>
        );
    }
}

export default Profile
