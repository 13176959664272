import './HiW.css'
import React from 'react'
class HiW extends React.Component{
  constructor(){
		super();
  }
  render() {
    return (
      <div>
        <meta charSet="utf-8" />
        <title>How It Works</title>
        <meta name="description" content="Figma htmlGenerator" />
        <meta name="author" content="htmlGenerator" />
        <link href="https://fonts.googleapis.com/css?family=Poppins&display=swap" rel="stylesheet" />
        <link rel="stylesheet" href="styles.css" />
        <style dangerouslySetInnerHTML={{__html: "\n              /*\n                Figma Background for illustrative/preview purposes only.\n                You can remove this style tag with no consequence\n              */\n              body {background: #E5E5E5; }\n            " }} />
        <div className="e184_2">
          <div className="e184_3">
            <span className="e184_4">Privacy Policy | Terms &amp; Conditions</span>
            <span className="e184_5">Follow us on:</span><span className="e184_6">© 2017-2022 Innokreat Technologies Pvt Ltd </span>
            <div className="e184_8" />
            <div className="e184_9" />
            <div className="e184_10" />
            <div className="e184_11" />
            </div>
            <div className="e184_12">
              <div className="e184_13" />
              <div className="e184_19">
                <div className="e184_20" />
                <span className="e184_21">Register</span>
                </div>
                <div className="e184_118">
                  <span className="e184_119">Home</span>
                  <span className="e184_120">Pricing</span>
                  <span className="e184_121">Contact</span>
                  <span className="e184_122">Support</span>
                  <span className="e184_123">About Us</span>
                  <span className="e184_124">How It Works</span>
                  </div>
                  </div>
                  <div className="e184_22">
                    <div className="e184_23">
                      <span className="e184_24">Sign up in 2 minutes</span>
                      <div className="e184_32" />
                      <span className="e189_199">Get your photo and
                location verified at
                the time of signup.
                We just need your
                mail and phone no.
                No application download required.</span>
                </div>
                <div className="e184_33">
                  <span className="e184_34">Bring your own exam</span>
                  <div className="e184_43" />
                  <span className="e189_202">Get exam in any format like google forms, microsoft forms, pdf, word, jotform,etc.
                Schedule it as a Live Test or Practice Test.
                View submissions after exam.</span>
                </div>
                <div className="e189_208" />
                <div className="e184_44">
                  <span className="e184_45">Proctor the exam</span>
                  <div className="e184_53" />
                  <span className="e189_203">Live Proctor the exam.
                Chat with students and respond to their messages.
                Add remarks for suspicious behaviour.</span>
                </div>
                <div className="e184_54">
                  <span className="e184_55">How It Works?</span>
                  <span className="e184_56">We reduce the stress in conducting exams and make online exams credible in just a few steps-</span>
                  </div>
                  <div className="e189_321">
                    <div className="e189_322" />
                    <span className="e189_323">1</span>
                    <div className="e189_324" />
                    <div className="e189_325" />
                    <span className="e189_326">Step</span>
                    </div>
                    <div className="e189_327">
                      <div className="e189_328" />
                      <span className="e189_329">2</span>
                      <div className="e189_330" />
                      <div className="e189_331" />
                      <span className="e189_332">Step</span>
                      </div>
                      <div className="e189_333">
                        <div className="e189_334" />
                          <span className="e189_335">3</span>
                        <div className="e189_336" />
                        <div className="e189_337" />
                          <span className="e189_338">Step</span>
                        </div>
                        </div>
                        </div>
      </div>
    );
  }
}
export default HiW
