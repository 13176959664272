import React,{Component} from 'react'
import ReactDOM from 'react-dom'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Http from 'axios'
import StudentCard from './StudentCard'
import '../Auth/Auth.css'
import Card from 'react-bootstrap/Card'
import '../Chat/Chat.css';
import Chat from '../Chat/Chat'



class ExamCompleted extends Component{
	constructor(){
		super();
		this.state={isLoaded:false, users:[]};
	}
	async componentWillMount() {
	 const exam = JSON.parse(localStorage.getItem('exam1'));
	 this.state.exam=exam;
	 const user = JSON.parse(localStorage.getItem('user'));
	 this.state.user=user;
    Http.defaults.headers.common['Authorization'] = this.state.user.token;
	 var api;
	 if(exam) 
		api='user-api/userua/'+exam.id;
	 else
		api='user-api/user';
		const response = await	Http.get(api);
         this.setState({ isLoaded:true, users:response.data });
	}
        async updateState (event) {
	}
	toLocal = (utctime) => { 
        var x=new Date(Date.parse("1970-01-01T"+utctime.toString()+"Z"));
        //x+=this.state.user.timezone*60000;
        //return(x.toLocaleString().slice(16,24));
        return(x.toLocaleTimeString());
    }
	// toLocal = (utctime) => { var x=new Date(Date.parse("1970-01-01T"+utctime.toString()+"Z"));x+=this.state.user.timezone*60000;return(x.toLocaleString().slice(16,24));}
	handleChange = (event)=>{
		let nam=event.target.name;
		let val=event.target.value;
		this.setState({[nam]:val});
		this.setState({id:0});
	}
	toLocalTime = (last_login,timezone) => {
		       if(last_login=="")
	       return("Exam Not Given");
           var x=new Date(Date.parse(last_login.slice(0, 19).replace(' ', 'T')+'Z'));
           return(x.toLocaleString());
        }
	render() {
	return(
		<div>
<nav className="navbar">
  <nav className="navbar-brand">
	        	<h3><a style={{color:"#fafafa"}} href="/"><img src="/monitorexam_t.png" width="213px" height="120px"></img></a></h3>
  </nav>
  <nav className="navbar appmenu right">
    <a className="nav-link" style={{display:this.state.user.role=='invig'?'block':'none',color:"#00b3b3"}} href="/dashboard">Student Dashboard</a>
    <a className="nav-link" style={{color:"#00b3b3"}} href="/examdash"> Exam Dashboard</a>
    <a className="nav-link" style={{color:"#00b3b3"}} href="/form#createexam">Create Exam</a>
    <a className="nav-link" href="/login" style={{backgroundColor:"#00b3b3",color:"white",borderRadius:"30px"}} onClick={()=>localStorage.clear()}>Logout</a>
  </nav>
</nav>
		<br />
		<br />
		<br />
		<br />
		<h4 style={{backgroundColor:"white",color:"#00b3b3",padding:"8px"}}>Invigilator Monitoring Dashboard for {this.state.exam.name}</h4>
		<div className="row" style={{backgroundColor:"#fff"}}>
		<div style={{backgroundColor:"white", color:"#00b3b3",margin:"16px"}} className="column side">
		</div>
		<div style={{backgroundColor:"white", color:"#00b3b3",margin:"16px"}} className="column middle">
		<Card>
		<Card.Title style={{color:"00b3b3"}}> Exam Details</Card.Title>
		<Card.Body>
		<p> <b>Exam Name</b> {this.state.exam.name} </p> 
			  
		<p> <b>Exam Date</b> {Intl.DateTimeFormat().format(Date.parse(this.state.exam.date))} </p> <p> <b>Start Time</b> {this.toLocal(this.state.exam.start_time)} </p> 
		<p> <b>Exam Duration</b> {this.state.exam.duration} minutes </p>
		<p> <a href={this.state.exam.link}><b>Exam Link</b></a> </p>
		</Card.Body>
		</Card>
		</div>
		</div>
		<div className="row" style={{backgroundColor:"#fff"}}>
		<div style={{backgroundColor:"white", color:"#00b3b3",margin:"16px"}} className="column side">
		</div>
		<div style={{backgroundColor:"white", color:"#00b3b3",margin:"16px"}} className="column middle">
		<Card>
		<Card.Title style={{color:"00b3b3"}}>Students Registered for Exam</Card.Title>
		<Card.Body>
		<table style={{borderSpacing:"5px",width:"100%"}} class="table-responsive" >

		    <tr style={{paddingLeft:"15px", textAlign:"centre"}}><th> #</th><th></th><th style={{padding:"15px"}}> Name </th><th>Last Seen</th><th>Admit Card</th><th>Attendance</th><th>Answer Sheet</th><th>Cred Score</th><th>Remarks</th></tr>
		<>
		{this.state.users.map(user=>( <tr style={{padding:"15px",textAlign:"centre"}}><td> {user.id}</td>
		<td>
		    <img src={'http://d1bumj8k6ax755.cloudfront.net/uploads/'+user.reg_pic.split("'")[1]} class="limsthumb" style={{width:"50px",borderRadius:"50%"}} alt="reg pic"/>
		    <img src={'http://d1bumj8k6ax755.cloudfront.net/uploads/'+user.live_pic.split("'")[1]} class="limsthumb" style={{width:"50px",borderRadius:"50%"}} alt="live pic"/>
		</td>
		    <td> {user.first_name} {user.last_name}</td>
		    <td style={{display:"none"}}>{user.email}</td>
			<td>{this.toLocalTime(user.last_login,user.timezone) }</td><td><img src={user.admit_card} class="limsthumb" style={{width:"50px",borderRadius:"5%"}} alt="admit card"/></td><td>{user.attendance}</td><td><a href={user.answer}>Answer Sheet</a></td>
			<td>
			<p style={{display:this.state.user.role=='invig'?"block":"none"}} >{user.cred}</p>
			</td>
			<td>
			<p style={{display:this.state.user.role=='invig'?"block":"none"}} >{user.remarks}</p>
			</td>
			</tr>))}
		</>
		</table>
		</Card.Body>
		</Card>
	    </div>
	    </div>
	    <br />
	    </div>
	);
	}
}

export default ExamCompleted
