
import React,{Component} from 'react'
import ReactDOM from 'react-dom'
import {useParams} from 'react-router-dom'
import ReactGA from 'react-ga';
import InvigAuth from '../Auth/InvigAuth';
import HowItWorks from '../Home/HowItWorks';
import Http from 'axios'
import '../Auth/Auth.css'
import Alert from 'react-bootstrap/Alert'
import Card from 'react-bootstrap/Card'
function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}


class SubmitJotForm extends React.Component {
    constructor() {
        super();
		// Add your tracking ID created from https://analytics.google.com/analytics/web/#home/
		ReactGA.initialize('UA-53038418-2');
		// This just needs to be called once since we have no routes in this case.
		ReactGA.pageview(window.location.pathname);
                Http.defaults.baseURL=window.location.origin;
    }

    componentDidMount() {
      var path=window.location.pathname;
//  const exam = JSON.parse(localStorage.getItem('exam1'));

        path=path.slice(12);
        console.log(path);
        //var decstring=String.fromCharCode.apply(null, decoded);
        //console.log(decstring);
            //Http.defaults.headers.common['Authorization'] = this.state.user.token;
            Http.put('exam-api/exams/' + this.props.params.studentId.slice(1) + '/' + this.props.params.examId.slice(1), {
                    "status": "paid",
                }, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then(res => {
                    console.log("User Exam submitted Successfully");
                })
	    console.log("Submission Successful");
	    alert("Submission Successful");
        }
    handleCapture = (event) => {
        const video = document.getElementById('video');
        const canvas = document.getElementById('canvas');
        const snap = document.getElementById("snap");
        const errorMsgElement = document.querySelector('span#errorMsg');
        this.setState({
            captured: true
        });
        // Draw image
        var context = canvas.getContext('2d');
        context.drawImage(video, 0, 0, 320, 240);
        var blob, url;
        //const url= canvas.toDataURL('image/png').replace('image/png','image/octet-stream');
        canvas.toBlob(function(blob) {
            var newImg = document.getElementById('imageSrc'),
                url = URL.createObjectURL(blob);
            console.log(url);
            console.log(blob);
            newImg.src = url;
            
            var data = new FormData();
            var file = new File([blob],"selfie.png");
            console.log(file);
            data.append('file', file);
            if (blob) {
                Http.post('user-api/usera', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    errorMsgElement.innerHTML = res.data;
		    if(res.data.includes('not')) { this.updateState("away");}
                })
                .catch(function(error) {
                    console.log(error);
                });
            }   
            this.setState({profilePic:blob});
         });
        if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(canvas.msToBlob(), "pic" + this.state.user.id);
        } else {}
    }
    onOpenCvReady() {
        document.getElementById('errorMsg').innerHTML = 'OpenCV.js is ready.';
        console.log("opencv is ready");
    }
render_old(){
    return (
      <div>
          <header className="header" style={{top:"0px"}}>
            <div className="">
              <a href="/" className="logo"><img src="/images/monitor-exam-logo.png" alt="logo" width={180} /></a>
              <input className="menu-btn" type="checkbox" id="menu-btn" />
              <label className="menu-icon" htmlFor="menu-btn"><span className="navicon" /></label>
              <ul className="menu">
                <li><a href="\">Home</a></li>
		<li><a href="\about" >About Us</a></li>
                <li className="hiw"><a href="\howitworks" >How It Works</a></li>
                <li><a href="\faq">FAQ's</a></li>
                <li><a href="\contact">Contact</a></li>
                <li><a href="\newlogin" className="btn btn-lg btn-outline-primary header-btn login">Login</a></li>
                <li><a href="\newiregister" className="btn btn-lg btn-primary header-btn">Register</a></li>
              </ul>
            </div>
          </header>
        <meta charSet="UTF-8" />
        <title> Submission Confirmation Page</title>
        <link rel="stylesheet" href="style.css" />
        {/* Fontawesome CDN Link */}
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <div className="container pt-100">
          <div className="content">
            <div className="right-side">
              <div className="address details">
                <i className="fas fa-map-marker-alt" />
                <div className="topic">Address</div>
                <div className="text-one"> Sector 52, Gurugram</div>
                <div className="text-two">Haryana:122003</div>
              </div>
              <div className="mobile details">
                <i className="fas fa-mobile-alt" />
                <div className="topic">Mobile</div>
                <div className="text-one">
		<p><a href="tel:=+917982151592" style={{color:"#6576ff"}}>+91 7827637626</a></p></div>
              </div>
              <div className="email details">
                <i className="fas fa-envelope" />
                <div className="topic">Email</div>
                <div className="text-one"> 
		<p><a href="mailto:care@monitorexam.com" style={{color:"#6576ff"}}>care@monitorexam.com</a></p> 
	        </div>
              </div>
            </div>
            <div className="left-side">
              <div className="topic-text">Get in touch with us!!</div>
              <h5>Thank You for your Submission</h5>
                <p> The results will be shared soon.  </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
	render(){
    return (
      <div>
        <meta charSet="utf-8" />
        <meta name="author" content="Softnio" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="description" content="@@page-discription" />
        {/* Fav Icon  */}
        <link rel="shortcut icon" href="/images/favicon.png" />
        {/* Page Title  */}
        <title>Pricing | Monitor Exam</title>
        {/* StyleSheets  */}
        <link rel="stylesheet" href="/assets/css/style.css?ver=1.2.0" />
        <link rel="stylesheet" href="/assets/css/custom.css" />
        <link id="skin-default" rel="stylesheet" href="/assets/css/theme.css?ver=1.2.0" />
        <div className="nk-app-root">
          {/* main @s */}
          <div className="nk-main ">
            {/* wrap @s */}
            <div className="nk-wrap ">
              {/*Header*/}
              <header className="header nk-header nk-header-fixed is-light">
                <div className="container">
                  <a href className="logo"><img src="/images/monitor-exam-logo.png" alt="logo" width={180} /></a>
                  <input className="menu-btn" type="checkbox" id="menu-btn" />
                  <label className="menu-icon" htmlFor="menu-btn"><span className="navicon" /></label>
                  <ul className="menu">
                    <li><a href="#">Home</a></li>
                    <li><a href="#">Pricing</a></li>
                    <li><a href="#">Contact</a></li>
                    <li><a href="#">Support</a></li>
                    <li><a href="/newiregister" className="btn btn-lg btn-primary header-btn">Register</a></li>
                  </ul>
                </div>
              </header>
              {/* content @s */}
              <div className="nk-content ">
                <div className="container wide-xl">
                  <div className="nk-content-inner">
                    {/* .nk-aside */}
                    <div className="nk-content-body">
                      <div className="nk-content-wrap">
                        <div className="nk-block-head nk-block-head-lg">
                          <div className="nk-block-between-md g-4">
                            <div className="nk-block-head-content">
                              <h2 className="nk-block-title mt-55">Your Test Has been Submitted</h2>
                              <div className="nk-block-des">
                            <Alert variant='success' dismissable>
                              <Alert.Heading>Exam is submitted.  </Alert.Heading>
                              <p> Please connect with <a href="mailto:care@monitorexam.com">care@monitorexam.com</a> for any clarifications </p>
                              <p>Logout by clicking the button below</p>
                            </Alert>
                            <p> Your exam has been submitted successfully. Please logout by clicking the button below</p>
                            <a href="/newLogin" className="btn btn-lg btn-primary" onClick={() => localStorage.clear()} >Logout</a>
                              </div>
                            </div>
                          </div>
                        </div>{/* .nk-block-head */}
                        <div className="nk-block mb-4">
                          <div className="row g-gs">
                          </div>{/* .row */}
                        </div>{/* .nk-block */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* content @e */}
            </div>
            {/* wrap @e */}
            {/*footer*/}
            {/* main @e */}
          </div>
          {/* app-root @e */}
          {/* JavaScript */}
        </div></div>
    );
  }
}
export default withParams(SubmitJotForm)
