import React, {
    Component
} from 'react'
import ReactDOM from 'react-dom'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Alert from 'react-bootstrap/Alert'
import Http from 'axios'
import {
    Redirect
} from 'react-router-dom'
import './Auth.css'
import Chat from '../Chat/Chat'
import Button from 'react-bootstrap/Button'


class Test extends React.Component {
    constructor() {
        super();
        this.state = {
                status: "Initial",
                testStarted: false,
                camera_state: false,
                network_state: false,
                battery_state: false,
                battery_level: 5,
                cred: 0,
                ip: '0.0.0.0',
                captured: false,
                displayToast:true,
        };
	    this.handleCapture=this.handleCapture.bind(this);
	    this.captureDesktop=this.captureDesktop.bind(this);
    }
    
    
    
        showPosition = (position) => {
            this.state.lat = position.coords.latitude;
            this.state.lng = position.coords.longitude;
        }
        
        
        updateState = (event) => {
		//cred is a credibility score
		const errorMsgElement = document.querySelector('span#errorMsg');
        const istatus = document.querySelector('input#status');
        const deskfilename = document.querySelector('input#deskfilename');
        this.state.desk_pic = deskfilename.value;

		if(this.state.status=="Completed"|| this.state.exam_status=="Done"||this.state.minutes_left<=0)
            {
                clearInterval(this.timerID);//stop sending status
                clearInterval(this.timerCap);//stop sending pictures
                console.log("Exam Over. Stopped sending status");
                errorMsgElement.innerHTML = "Exam Over. Stopped sending status";
                if(this.state.minutes_left<=0||this.state.status=="Completed")
                    this.state.exam_status="Done";
                //TODO: add code to sent cred score and remarks here
                var api="exam-api/examc/" + this.state.user.id + "/" + this.state.exam.id+ "/" + this.state.user.cred ;
	                Http.put(api)
	                .then(res => {
                     console.log(res);
                     //errorMsgElement.innerHTML = "Cred Score submitted successfully";
                     //console.log("Cred Score submitted successfully");
                   })
                   .catch(function (error) {
                     errorMsgElement.innerHTML = "Error sending cred score";
                     console.log(error);
                   });
                   this.state.user.status="free";
                   this.state.user.cred=0;
                   localStorage.setItem('user', JSON.stringify(this.state.user));
                    Http.put('user-api/user/' + this.state.user.id, {
                    "status": status,
		            "cred":cred,
                    }, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                    })
                    .then(res => {
                    //console.log("User updated Successfully");
                    });
                return;
            }
            var status,cred =0;
            Http.defaults.headers.common['Authorization'] = this.state.user.token;
            console.log(document.hidden || document.msHidden || document.webkitHidden);
            console.log(document.webkitVisibilityState);
		    //Mark attendance at the beginnging
		    if(this.state.cred==0) {
		    var api="exam-api/examp/" + this.state.user.id + "/" + this.state.exam.id ;
	            Http.post(api)
	            .then(res => {
                     console.log(res);
                     errorMsgElement.innerHTML = "Attendance marked successfully";
                     console.log("Attendance marked successfully");
                   })
                   .catch(function (error) {
                     errorMsgElement.innerHTML = "Error marking attendance";
                     console.log(error);
                   });
		        if(this.state.user.cred!==undefined)
			        cred = this.state.user.cred;
			    else
			        console.log("Cred not yet defined");
		    }
		    else {
		        cred = this.state.cred;
		    }
		    this.setState({status:istatus.value});
		    console.log(this.state.status);
            if (document.hidden || document.webkitVisibilityState != "visible" && istatus.value=="Present") {
                status = "Distracted";
		        cred=cred-1;
		        if(cred<-20&&this.state.user.desk_pic==null)
		        {		        
		            this.captureDesktop();
		            errorMsgElement.innerHTML = `Invigilator has requested a desktop screenshot. Please allow`;           
		        }
            }
	        else if(this.state.status=="Away")
	        {
		        status = "Away";
		        cred=cred-1;
	        } 
	        else if(this.state.status=="Doubt")
	        {
		        status = "Doubt";
		        cred=cred-2;
	        } 
	        else if(this.state.status=="Completed")
	        {
	            status="Completed";
	        }   else
	        {
		        status = "Present";
		        cred=cred+4;
	        }
	        this.setState({cred:cred});
	        this.environmentCheck();
	    
	        if(this.state.exam_status=="Done")
	        {
	            clearInterval(this.timerID);
	            clearInterval(this.timerCap);
	            status="Completed";
	        }
	    
	        this.state.user.status=status;
	        this.state.user.cred=cred;
            this.setState({last_login:last_login});
            this.state.user.last_login=last_login;
            this.state.user.network_state=this.state.network_state;
            this.state.user.battery_state=this.state.battery_state;
            this.state.user.battery_level=this.state.battery_level;
            this.state.user.camera_state=this.state.camera_state;
            this.state.user.live_pic=this.state.live_pic;
            this.state.user.desk_pic=this.state.desk_pic;
            localStorage.setItem('user', JSON.stringify(this.state.user));
	    
            const last_login = new Date().toISOString().slice(0, 19).replace('T', ' ');
		    //separete handling for user having a registered pic and not
		    if(this.state.user.live_pic||this.state.live_pic) {
                Http.put('user-api/user/' + this.state.user.id, {
                    "camera_state": this.state.camera_state,
                    "network_state": this.state.network_state,
                    "battery_state": this.state.battery_state,
                    "battery_level": this.state.battery_level.toString(),
                    "status": status,
                    "ip": this.state.user.ip,
                    "lat": this.state.lat,
                    "lng": this.state.lng,
                    "last_login": last_login,
		            "live_pic":this.state.live_pic,
		            "cred":cred,
		            "desk_pic":this.state.desk_pic
                }, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then(res => {
                    //console.log("User updated Successfully");
                })
		} else {
            Http.put('user-api/user/' + this.props.user.id, {
                    "camera_state": this.state.camera_state,
                    "network_state": this.state.network_state,
                    "battery_state": this.state.battery_state,
                    "battery_level": this.state.battery_level.toString(),
                    "status": status,
                    "ip": this.state.user.ip,
                    "lat": this.state.lat,
                    "lng": this.state.lng,
                    "last_login": last_login,
                }, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then(res => {
                    //console.log("User updated Successfully");
                })
		}
        }
	//Clear the timerID set in ComponentDidUpdate test whether this works or not
	componentWillUnmount() {
	      clearInterval(this.timerID);
	      clearInterval(this.timerCap);
	}
	async startPiP(event) {
		  console.log('Toggling Picture-in-Picture...');
                  const video = document.getElementById('video');
		 // togglePipButton.disabled = true;
		  try {
			      if (video !== document.pictureInPictureElement)
				        await video.requestPictureInPicture();
			      else
				        await document.exitPictureInPicture();
			    } catch(error) {
				        console.log(`> Argh! ${error}`);
				} finally {
					          //togglePipButton.disabled = false;
		        }
	}
	async captureDesktop(event){
	    const deskvideo = document.getElementById('deskvideo');
	    const deskcanvas = document.getElementById('deskcanvas');
	    const errorMsgElement = document.querySelector('span#errorMsg');
	    const deskfname = document.querySelector('input#deskfname');
	    const deskfilename = document.querySelector('input#deskfilename');
        var context = deskcanvas.getContext('2d');
	    const constraints = {
                audio: false,
                video: {
                    width: 320,
                    height: 240
                }
        };
	    let captureDesktop=null;

	    try {
            const stream = await navigator.mediaDevices.getDisplayMedia(constraints);
            window.stream = stream;
            deskvideo.srcObject = stream;
	        deskvideo.play();
            context.drawImage(deskvideo, 0, 0, 320, 240);

        } 
        catch (e) {
            errorMsgElement.innerHTML = `navigator.getDisplayMedia error:${e.toString()}`;
        }
        deskfname.value = "E"+this.state.exam.id.toString()+"_U"+this.state.user.id.toString()+"_D";
        console.log(deskfname.value);
        
        var blob, url;
        deskcanvas.toBlob(function(blob) {
            var newImg = document.getElementById('deskimageSrc'),
            url = URL.createObjectURL(blob);
            console.log(url);
            console.log(blob);
            newImg.src = url;
            
            var data = new FormData();
            var file = new File([blob],deskfname.value);
            //var file = new File([blob],filename.value);
            console.log(file);
            data.append('file', file);
            if (blob) {
                Http.post('user-api/usera', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    //errorMsgElement.innerHTML = res.data;
		            deskfilename.value = res.data.split(' ',1);
		            console.log(deskfilename.value);
                })
                .catch(function(error) {
                    console.log(error);
                });
            }   
            //this.setState({profilePic:blob});
         });
         this.state.desk_pic=deskfilename.value;
	}
    handleCapture = (event) => {
        const video = document.getElementById('video');
        const canvas = document.getElementById('canvas');
    //    const snap = document.getElementById("snap");
        const errorMsgElement = document.querySelector('span#errorMsg');
        const timeMsgElement = document.querySelector('span#timeMsg');
        const filename = document.querySelector('input#filename');
        const fname = document.querySelector('input#fname');
        const status = document.querySelector('input#status');
        this.setState({
            captured: true
        });
        // Draw image
        var context = canvas.getContext('2d');
        context.drawImage(video, 0, 0, 320, 240);
        var blob, url;
        fname.value = "E"+this.state.exam.id.toString()+"_U"+this.state.user.id.toString()+"_";
        console.log(fname.value);
        //const url= canvas.toDataURL('image/png').replace('image/png','image/octet-stream');
        
        canvas.toBlob(function(blob) {
            var newImg = document.getElementById('imageSrc'),
            url = URL.createObjectURL(blob);
            console.log(url);
            console.log(blob);
            newImg.src = url;
            
            var data = new FormData();
            var file = new File([blob],fname.value);
            //var file = new File([blob],filename.value);
            console.log(file);
            data.append('file', file);
            if (blob) {
                Http.post('user-api/usera', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    //errorMsgElement.innerHTML = res.data;
		            filename.value = res.data.split(' ',1);
		            if(res.data.includes('not')) { 
			            status.value="Away";
		            }
		            else {
		            //get numFaces
	                    const cdata=res.data.split(" ");
	                    console.log(cdata[2]);
	                    if(cdata[2]==1)	            
			                status.value="Present";
			            else
			                status.value="Doubt";  
			            console.log(status.value);  
		            }
                })
                .catch(function(error) {
                    console.log(error);
                });
            }   
            //this.setState({profilePic:blob});
         });
		if(filename.value)
		{
			this.state.file=filename.value;
			this.state.live_pic=filename.value;
			this.state.status = status.value;
		}
	    else
	    {
			this.state.status="Away";

	    }
	    
	    
	    
        if(this.state.minutes_left==1)
        {        //reverse countdown in seconds
                var count=50; //change this if you change frequency of handleCapture
                var t30=setInterval(()=>{count=count-1;timeMsgElement.innerHTML=count+" seconds left<br /> Please submit your answers";if(count<0) clearInterval(t30);},1000);
        }
        if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(canvas.msToBlob(), "pic" + this.state.user.id);
        } else {}
    }
        async environmentCheck() {
            const video = document.getElementById('video');
            const errorMsgElement = document.querySelector('span#errorMsg');
            //const cameraMsgElement = document.querySelector('span#cameraMsg');
            //const nwMsgElement = document.querySelector('span#nwMsg');
            //const baMsgElement = document.querySelector('span#baMsg');
		//Location
            navigator.geolocation.getCurrentPosition(this.showPosition);
		//GetIP
            Http.get('https://api.ipify.org?format=json')
                .then(res => {
                    const ipnew = res.data.ip;
                    console.log(ipnew);
                    this.setState({
                        ip: ipnew
                    });
                });
            this.setState({
                network_state: navigator.onLine
            });
		//Network
            var connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
	        connection.onChange=this.updateState;
            /*if (connection) {
                var speed = `None`;
                switch (connection.effectiveType) {
                    case "4g":
                        speed = "good";
                        break;
                    case "3g":
                        speed = "fair";
                        break;
                    case "2g":
                        speed = "slow";
                        break;
                    case "slow-2g":
                        speed = "slow";
                        break;
                }
                nwMsgElement.innerHTML = `Network speed is ` + connection.downlink + "Mbps";
            }*/
            var battery=navigator.battery||navigator.mozBattery||navigator.webkitBattery;
		//Battery
           if (navigator.getBattery) {
                 var level=navigator.getBattery().then(function(battery) {
                    //baMsgElement.innerHTML = `<img alt="battery" src="/i/battery.png" width="15%"> ...... &#10003  level ` + battery.level * 100 + '% ' + (battery.charging ? 'Charging' : 'Not Charging')
                    //baMsgElement.innerHTML += `<progress value=` + battery.level * 100 + ` max="100" style={{color:"#6576ff"}} width=250px height=25px>%</progress>`;
		            return battery.level*100;
                }).then((result)=>this.setState({ battery_state: true,battery_level:result }));
            } else {
                this.setState({
                    battery_state: false
                });
                //baMsgElement.innerHTML = `<img  alt="battery"  src='/i/battery.png width="15%"/> ...... &#10005`
            }

	    //Bluetooth
            /*
            navigator.bluetooth.requestDevice({
                    acceptAllDevices: true
                })
                .then(function(device) {
                    console.log(device.name);
                });
            */
            const constraints = {
                audio: false,
                video: {
                    width: 320,
                    height: 240
                }
            };
           

	    //Camera
            try {
                const stream = await navigator.mediaDevices.getUserMedia(constraints);
                window.stream = stream;
                video.srcObject = stream;
                this.setState({
                    camera_state: true
                });
                //cameraMsgElement.innerHTML = `Camera ...... &#10003`;
            } catch (e) {
                this.setState({
                    camera_state: false
                });
                //cameraMsgElement.innerHTML = `Camera ...... &#10005`;
                //errorMsgElement.innerHTML = `navigator.getUserMedia error:${e.toString()}`;
            }
	    //TODO: Desktop
		//Image Capture
		//Voice Capture
        }
    uploadAnswerSheet = (e) => {
        var data = new FormData();
        var tout
        const errorMsgElement = document.querySelector('span#errorMsg');
        const timeMsgElement = document.querySelector('span#timeMsg');
        data.append('file', e.target.files[0]);
        //data.append('cred',this.state.user.cred);
        console.log(e.target.files[0]);
        this.setState({
            fileAnswerSheet: URL.createObjectURL(e.target.files[0])
        });
        console.log(this.state.fileAnswerSheet);
        var api="exam-api/exama/" + this.state.user.id + "/" + this.state.exam.id ;
        Http.put(api, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(res => {
                errorMsgElement.innerHTML = "Answer Sheet submitted <a href="+res.data+">View Here</a><br /> We will log you out after 30 seconds";
                var count=30;

                this.state.status="Completed";
                this.updateState();
                clearInterval(this.timerID);
	            clearInterval(this.timerCap);
                
                //Mark status as Done
                tout=setTimeout(()=>{this.setState({exam_status:"Done"});},30000);
                //reverse countdown in seconds
                var t30=setInterval(()=>{count=count-1;timeMsgElement.innerHTML=count+" seconds";if(count<0) clearInterval(t30);},1000);
            })
            .catch(function(error) {
                console.log(error);
                errorMsgElement.innerHTML = error;
            });
        

        
        //TODO Mark exam status as done         
    }
    async componentWillMount() {
    //Get ready for action
    /* Get data from localStorage*/
        const user = JSON.parse(localStorage.getItem('user'));
        const exam = JSON.parse(localStorage.getItem('exam1'));
        const video = document.getElementById('video');
        const errorMsgElement = document.querySelector('span#errorMsg');
	    var tout,minutes_left;
	    
        if (user)
            this.state.user = user;
        else if (this.props.user)
            this.state.user = this.props.user;
        else
            console.log("User not available");
        //TODO: May be add login again to above
        
        if (exam)
            this.state.exam = exam;
        else if (this.props.exam)
            this.state.exam = this.props.exam;
        else
            console.log("Exam not available");

        /************************************************/
        //        console.log(this.state.user);
        //        console.log(this.state.exam
        //        the variable this.state.exam_status will be updating every minute
        /************************************************/

        //Time passed since exam and left
	    const date = Date.parse(this.state.exam.date);
	    const time = Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z");
	    var   now  = Date.now();
	    var   passed = (now-date-time)/60000;
	    
	    
	    //minutes_left = Math.floor(((Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z"))/60000)) + this.state.exam.duration - Math.floor((Date.now()/60000));
	    minutes_left= Math.floor(this.state.exam.duration - passed);
	    console.log(minutes_left);
	    this.state.minutes_left=minutes_left;
        
        
        //this.state.exam_status=(Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z"))<(Date.now())?(((Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z")+this.state.exam.duration*60000)>(Date.now()))?"Ongoing":"Done"):"Not Started";
	    this.state.exam_status=(minutes_left>this.state.exam.duration)?"Not Started":((minutes_left<=0)?"Done":"Ongoing");
	    //TODO: Minutes can be calculated from here. Put timeout or time interval?
	    if(this.state.exam_status!="Done")
	    {
     //       tout=setTimeout(()=>{this.state.exam_status=(Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z"))<(Date.now())?(((Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z")+this.state.exam.duration*60000)>(Date.now()))?"Ongoing":"Done"):"Not Started";},60000);
	        tout=setInterval(()=>{this.state.exam_status=(this.state.minutes_left-->this.state.exam.duration)?"Not Started":((minutes_left<0)?"Done":"Ongoing")},60000);
	    }
	    else
	    {
	        clearInterval(tout);
	        clearInterval(this.timerID);
	        clearInterval(this.timerCap);
        }
        /* View PDF code the api-id is via manu...gmail*/
         if(exam.platform==="Offline")
         {
             document.addEventListener("adobe_dc_view_sdk.ready", function(){ (function(exam){
	         console.log(exam);
      
             window.adobeDCView = new window.AdobeDC.View({clientId: "0eb4d90958fe48ebbdd31257add46610", divId: "adobe-dc-view"});
             //window.adobeDCView = new window.AdobeDC.View({clientId: "74b45275061347f88822164d43063adb", divId: "adobe-dc-view"});
             //window.adobeDCView = new window.AdobeDC.View({clientId: "1224c4840c8345aba5aeaece3d4d1819", divId: "adobe-dc-view"});
             window.adobeDCView.previewFile({
             content:{ location: 
             { url: exam.link}},
             metaData:{fileName: exam.platform}
             },
             {
	          defaultViewMode:"FIT_WIDTH",showDownloadPDF: false, showPrintPDF: false
              });
              })(exam);});
        }
    }
    toLocal = (utctime) => { var x=new Date(Date.parse("1970-01-01T"+utctime.toString()+"Z"));x+=this.state.user.timezone*60000;return(x.toLocaleString().slice(16,24));}
            toLocalTime = () => {
            console.log(this.props.exam.date);
            var x = new Date(this.props.exam.date + "T" + this.props.exam.start_time + "Z");
            return x.toLocaleTimeString();
    }
    handleChange = (event) => {
        this.setState({
            type: event.target.value
        });
        
        //TODO remove the code below
        if (this.state.exam.platform == "google") {
            this.setState({
                testSrc: "https://docs.google.com/forms/d/e/1FAIpQLSd91heY26bYYFLz1INIUZ0w7hitBS4ficHFhfEDvHcGE-4XSA/viewform?embedded=true"
            });
        } else if (this.state.exam.platform == "quizzizz") {
            this.setState({
                testSrc: "https://quizizz.com/join/quiz/5f6deb133dce58001c1794cc/start"
            });
        } else if (this.state.exam.platform == "microsoft") {
            this.setState({
                testSrc: "https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAN__rBlM09UMTFRUlU1WDE5NkcyQzlNWlNYTlBTTzNQVC4u&embed=true"
            })
        } else if (this.state.exam.platform == "offline") {
            this.setState({
                testSrc: "https://docs.google.com/forms/d/e/1FAIpQLSd91heY26bYYFLz1INIUZ0w7hitBS4ficHFhfEDvHcGE-4XSA/viewform?embedded=true"
            })
	}

    }

    async componentDidMount() {
        const video = document.getElementById('video');
        const errorMsgElement = document.querySelector('span#errorMsg');
        const constraints = {
            audio: false,
            video: {
                width: 320,
                height: 180
            }
        };
        try {
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            window.stream = stream;
            video.srcObject = stream;
	        video.play();
	        this.state.camera_state=true;
	        //video.requestPictureInPicture();
		    //TODO: May have to remove later
            this.handleCapture();
        } catch (e) {
            this.state.camera_state=false;
            //errorMsgElement.innerHTML = `navigator.getUserMedia error:${e.toString()}`;
        }
        
            this.timerID = setInterval(() => {
                this.updateState();
            }, 30000);
            this.timerCap = setInterval(() => {
		        this.handleCapture();
            }, 51000);
    }
    render() {
        //if(this.state.user.camera_state)
	if(this.state.exam_status!="Done")
        {
            return ( 
            <div >
                <nav className = "navbar" >
                <nav className="navbar-brand" style={{position:"absolute"}}>
	            <h3><a style={{color:"#fafafa"}} href="/"><img alt="monitorexam" src="/monitorexam_t.png" width="160px" ></img></a></h3>
                </nav>
                <nav className = "navbar appmenu right" >
                    < a className = "nav-link" style = { { color: "#6576ff" } } href = "/examdash" > Exam Dashboard < /a> 
		    < a className = "nav-link" style = { { display: this.state.user.role == 'invig' ? 'block' : 'none', color: "#6576ff" } } href = "/form" > Create Exam < /a> 
		    < a className = "nav-link" href = "/login" style = { { backgroundColor: "#6576ff", color: "white", borderRadius: "30px" } } onClick = { () => localStorage.clear() } > Logout < /a> 
		    <
            /nav> <
            /nav> 
		    <br />
		    <br />
		    <br />
		    <br />
		    <div>
            < div className = "row invite" style = { { backgroundColor: "white" } } >
            < div className = "column side" > 
		    </div>
            < div className = "column middle" > 


            <div onVisibilityChange = { this.updateState }>
	            <input type="hidden" name="filename" id="filename" />
	            <input type="hidden" name="status" id="status" />
	            <input type="hidden" name="fname" id="fname" />
                < div className = "video-wrap" >
                    < video id = "video" width = "320px" height = "240px" style = { { display: this.state.captured ? "block" : "block" } } playsInline autoPlay > < /video> 
	                <img src={this.state.file} id='imageSrc' style={{height:'320px',width:'240px',border:'none',draggable:'true', backgroundColor:'lightGray',display:'none'}}></img >
	                < canvas id = "canvas" width = "320px" height = "240px" style = { { display: this.state.captured ? "none" : "none" } } > < /canvas> 
		            <p><em>{this.state.user.id}: {this.state.user.first_name} {this.state.user.last_name}</em></p>
		        </div>
		        <div style={{display:"none"}}>
	                <input type="hidden" name="deskfilename" id="deskfilename" />
	                <input type="hidden" name="deskfname" id="deskfname" />
                    < div className = "video-wrap" >
                        < video id = "deskvideo" width = "320px" height = "240px" style = { { display: this.state.captured ? "block" : "block" } } playsInline autoPlay > < /video> 
	                    <img src={this.state.deskfile} id='deskimageSrc' style={{height:'320px',width:'240px',border:'none',draggable:'true', backgroundColor:'lightGray',display:'none'}}></img >
	                    < canvas id = "deskcanvas" width = "320px" height = "240px" style = { { display: this.state.captured ? "none" : "none" } } > < /canvas> 
		            </div>
		            <Button onClick = { this.captureDesktop }>Capture Desktop</Button>
		        </div>
		        <div class="toast show" role="alert" aria-live="assertive" aria-atomic="true" style={{display:this.state.displayToast?"block":"none"}}>
		            <div class="toast-header">
		                <strong class="me-auto"></strong>
		                <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={()=>this.setState({displayToast:false})}></button>
		            </div>
		            <div class="toast-body">
		                <p><span id="errorMsg"></span></p>
		                <p><span id = "timeMsg" style = {{ display: 'block' }}> </span></p>
		            </div>
		        </div>


                < section style = { { backgroundColor: "#f7f6f8" } } >
		    < div > 
		        < h3 style = { { float: "left" } } > { this.state.exam.name } < /h3> 
		        <p style={{textAlign:"center",float:"center"}}>{this.toLocal(this.state.exam.start_time)}</p>
		        <p style={{textAlign:"right",float:"right"}}>
		        {this.state.exam_status=(this.state.minutes_left>this.state.exam.duration)?"Not Started":((this.state.minutes_left<=0)?"Done":"Ongoing")}</p ><br />
		        <p style = {{textAlign: 'right',float:"right"}} >
		        

		         {(this.state.exam_status=="Ongoing")?(this.state.minutes_left+" min left"):""} < /p> 
		        <label> Test Progress </label><progress value={this.state.exam.duration-this.state.minutes_left} max={this.state.exam.duration} style={{color:"#6576ff"}} width="250px" height="25px"></progress>

                <br />
                <p style = {{textAlign: 'right'}} > {() => this.toLocalTime} < /p> 
		    </div> 
		        <article style = { { display: (Date.parse(this.state.exam.date) + Date.parse("1970-01-01T" + this.state.exam.start_time.toString() + "Z")) < (Date.now()) ? (((Date.parse(this.state.exam.date) + Date.parse("1970-01-01T" + this.state.exam.start_time.toString() + "Z") + this.state.exam.duration * 60000) > (Date.now())) ? "flex" : "none") : "none",flexDirection:"column" }} >
                <iframe src = { this.state.exam.link } onContextMenu = {()=>false} style={{display:(this.state.exam.platform!="Offline"?"block":"none")}} width = "100%" height = "1209" frameborder = "0" marginheight = "0" marginwidth = "0" > Loading < /iframe> 
		       <div id="adobe-dc-view" onContextMenu = {()=> false} style={{height: "500px",display:(this.state.exam.platform!="Offline"?"none":"block")}}></div>

		      <label className = "button"
                id = "snap"
                for = "uploadAnswerSheet"
                style = {
                    {
                        textAlign: "center",
                        backgroundColor: "#6576ff",
                        color: "white",
                        borderRadius: "4px",
                        paddingTop: "10px",
                        align: "right",
                        border: "none",
                        margin: '2px',
                        padding: ".375rem",
                        fontSize: "1rem",
                        lineHeight: "1.5",
                        display: this.state.exam.platform == "Offline" ? "block" : "none"
                    }
                }
                size = "md"
                required > Upload Answer Sheet < /label><input id="uploadAnswerSheet" type='file' style={{display:this.state.exam.platform=="Offline"?"block":"none"}} name='fileAS' onChange={this.uploadAnswerSheet}/ >
                <
                form style = {
                    {
                        textAlign: 'inherit',
                        display: 'none'
                    }
                } >
                <
                p > Q1.What is the.................................................. < /p> <
                input type = 'textarea'
                name = 'ans1'
                style = {
                    {
                        width: '400px'
                    }
                }
                />  <
                p > Q2.What is the.................................................. < /p> <
                input type = 'textarea'
                name = 'ans2'
                style = {
                    {
                        width: '400px'
                    }
                }
                />  <
                br / >
                <
                label style = {
                    {
                        margin: '2px'
                    }
                } > 40 Questions < /label><button style={{backgroundColor:"green",color:"white",padding:'3px',margin:'2px',height:'45px',width:'150px',align:'right'}}> Next </button >
                <
                /form> <
                /article >
	    < div className = "flex-item" >
            < div className = "flex-container" style = { { flexDirection: "column" } } >
		        <div style={{display:"flex"}} id="chat">
		            <article>
		           <Chat />
		            </article>
		         <br />
		      </div>
		    < /div>
		    </div>
		    < /section> 
		    </div>
		    </div>
		    </div>
		    </div>
		    </div>
            );
        }
        else
        	return(<Alert variant='danger' dismissable>
        		<Alert.Heading>Exam is now over.  </Alert.Heading>
			        <p> Hope you submitted the exam in time </p>
				    <p> Please connect with <a href="mailto:care@monitorexam.com">care@monitorexam.com</a> if you were not able to submit answersheet on time </p>
        	    </Alert>
        	);
        	
    }
}

export default Test
