
import Card from 'react-bootstrap/Card'
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button'
import React,{Component} from 'react'
import NewVerifyStudentE from '../Auth/NewVerifyStudentE'
import ReactDom from 'react-dom'

class NewStudentCard extends Component {
constructor(){
	super();
	this.state=({overall:"Initial"});
}

async componentDidMount(){
	if (this.props.user.network_state) {
		this.setState({overall:"Fair"});
			if (this.props.user.camera_state) {
				this.setState({overall:"Good"});
				if (this.props.user.battery_state) {
					this.setState({overall:"Very Good"});
					if (this.props.user.ip) {
						this.setState({overall:"Excellent"});
					}
				}
			}         
	}
	else {
		this.setState({overall:"Bad"});
	}
}
// Determine aura color based on status
    getAuraColor = () => {
        if (this.props.user.status === "Present") {
            return "green";
        } else if (this.props.user.status === "Distracted") {
            return "yellow";
        } else if (this.props.user.status === "Doubt") {
            return "red";
        } else if (this.props.user.status === "Looking down") {
            return "blue";
        } else if (this.props.user.status === "Away") {
            return "orange";
        } else {
            return "white"; // Default color
        }
    };

handleClick = () => {
        ReactDom.render(<NewVerifyStudentE user={this.props.user} />,document.getElementById("student"+this.props.user.id));
}
	toLocalLast = (last_login) => {
	       if(last_login=="")
	       return("Exam Not Given");
               var x=new Date(Date.parse(last_login.slice(0, 19).replace(' ', 'T')+'Z'));
               return(x.toLocaleString());
        }

render() {
return(                           <div className="col-sm-3">
                                  <div className="card card-bordered exam-card">
                                  <a href={"#student"+this.props.user.id} onClick={this.handleClick}>
	            <div style={{ position: "relative", display: "inline-block",width: "fit-content", border: `5px solid ${this.getAuraColor()}`  }}>

	                            	<img src={'http://d1bumj8k6ax755.cloudfront.net/uploads/'+this.props.user.live_pic.split("'")[1]} className="exam-card-img" alt="profile-pic" style={{ maxWidth: "100%", height: "auto" }} />
	            </div>
	                            	</a>
                                    <div className="card-inner">
                                      <p className="card-text pr-1"><span className="left">{this.props.user.first_name} {this.props.user.last_name}</span>
                                      <span className="right pr-1 ">{this.props.user.status}</span>
                                      <span className="right pr-1" style={{color:(this.props.user.cred<0)?"red":"green"}}>{this.props.user.cred}</span></p>
                                      <span className="text-center mb-2 d-none">Enviroment is {this.state.overall}</span>
                                      <a href={"#student"+this.props.user.id} onClick={this.handleClick} className="btn btn-outline-primary btn-block mb-2">Show Details</a>
	                              <div id={"student"+this.props.user.id}>
	                              </div>
                                    </div>
                                  </div>
                                </div>
                                );
}
}

export default NewStudentCard
