import React from 'react'
import ReactDOM from 'react-dom'
import Jumbotron from 'react-bootstrap/Jumbotron'
import ReactGA from 'react-ga';
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import ListGroup from 'react-bootstrap/ListGroup'

function HowItWorks(){
	return(
		<div>
          <header className="header" style={{top:"0px"}}>
            <div className="">
              <a href="/" className="logo"><img src="../images/monitor-exam-logo.png" alt="logo" width={120} /></a>
              <input className="menu-btn" type="checkbox" id="menu-btn" />
              <label className="menu-icon" htmlFor="menu-btn"><span className="navicon" /></label>
              <ul className="menu">
                <li><a href="\">Home</a></li>
		<li><a href="\about" >About Us</a></li>
                <li className="hiw"><a href="\howitworks" >How It Works</a></li>
                <li><a href="\faq">FAQ's</a></li>
		<li><a href="\pricing">Pricing</a></li>
                <li><a href="\contact">Contact</a></li>
                <li><a href="\newlogin" className="btn btn-lg btn-outline-primary header-btn login">Login</a></li>
                <li><a href="\newiregister" className="btn btn-lg btn-primary header-btn">Register</a></li>
              </ul>
            </div>
          </header>
		  <meta className="ml-200 mb-50 mr-200" name="viewport" content="width=device-width,initial-scale=1.0" />
		  <section className="bg-light container call_to_action_1">
		    <div className="row px-xl-0 justify-content-center pt-100">
		      <div className=" col-xl-6 col-md-8 col-sd-10" data-aos="fade-up" data-aos-delay={300}>
		        <img src="../images/HowItWorks.png" width="90%" ></img>
		      </div>
		    </div>
		    <div className="row px-xl-0 justify-content-center d-none">
		      <div className=" col-xl-6 col-md-8 col-sd-10" data-aos="fade-up" data-aos-delay={300}>
		        <a href="/newiregister" className="btn lg action-1">
		           Start Here 
		        </a>
		      </div>
		    </div>
		  </section>
		  <section className="pb-45 bg-dark text-center call_to_action_1">
		       <div className="container px-xl-0 text-center">
		           <div className="row justify-content-center">
		  <div className=" col-xl-6 col-md-8 col-sd-10" data-aos="fade-up" data-aos-delay={300}>
		   <h5 className="color-white"><em> Partners with</em> </h5><br />
		  <div className="color-white f-22" style={{}}>
		    <img width="150px" src="https://d0.awsstatic.com/logos/powered-by-aws-white.png" alt="Powered by AWS Cloud Computing" />
		    <img width="150px" src="../images/nvidia-inception-program-badge-rgb-for-screen.png" alt="nvidia inception" />
		    <img width="150px" src="../images/startups_wordmark_purple.png" alt="Microsoft for Startups" />
		  </div>
		  </div>
		  </div>
		  </div>
		  </section>
		  <section className="pb-45 bg-light text-center call_to_action_1">
		              <div className="container px-xl-0 text-center">
		                <div className="row justify-content-center">
		  <div className="col-xl-6 col-md-8 col-sd-10" data-aos="fade-up" data-aos-delay={300}>
		   <h5><em> Works with</em> </h5><br />
		   <a href="https://forms.google.com"><img width="150px" className="pr-3 pl-3" src="https://www.gstatic.com/images/branding/product/2x/forms_2020q4_48dp.png" alt="Google Forms Logo" /></a>
		   <a href="https://www.adobe.com"><img width="150px" className="pr-3 pl-3" src="https://www.adobe.com/content/dam/cc/icons/Adobe_Corporate_Horizontal_Red_HEX.svg" alt="Testmoz Logo" /></a>
		   <a href="https://forms.microsoft.com"><img width="150px" className="pr-3 pl-3" src="https://www.bing.com/th?id=OIP.1gu2XSql9LAD96bRZpm5LQAAAA&w=150&h=150&c=8&rs=1&qlt=90&o=6&dpr=1.5&pid=3.1&rm=2" alt="Microsoft Forms Logo" /></a>
		   <a href="https://www.quizizz.com"><img width="150px" className="pr-3 pl-3" src="https://cf.quizizz.com/img/quizizz_logos/purple-brandmark-600x164.png" alt="Quizizz Logo" /></a>
		   <a href="https://www.testmoz.com"><img width="150px" className="pr-3 pl-3" src="https://testmoz.com/static/1652430811/img/testmoz2.png" alt="Testmoz Logo" /></a>
		  </div>
		  </div>
		  </div>
		  </section>
		<div className="row py-5" style={{display:"none"}}>
			<div className="column side">
	    		</div>
	    		<div className="column middle" >
				<h1> How it Works </h1>
			<div className="flex-container">
				<Card style={{padding:"10px",margin:"10px",backgroundColor:"#f5f5f5"}} >
				<Card.Title style={{textAlign:"center"}}>Step 1</Card.Title>
				<Card.Body>
                		<ListGroup>
					<ListGroup.Item ><h5> < span >Prepare Exam< /span></h5>< /ListGroup.Item>
					<ListGroup.Item > <p> Google Forms</p> < /ListGroup.Item>
					<ListGroup.Item > <p> A test pdf</p> < /ListGroup.Item>
					<ListGroup.Item > <p> Any other software</p> < /ListGroup.Item>
					<Button style={{backgroundColor:"#6576ff",color:"white"}}><a  style={{color:"white"}} href='https://forms.google.com'>Create a Test Here</a></Button >
                		</ListGroup>
				</Card.Body>
				</Card>
				<Card style={{padding:"10px",margin:"10px",backgroundColor:"#f5f5f5"}} >
				<Card.Title style={{textAlign:"center"}}>Step 2</Card.Title>
				<Card.Body>
                		<ListGroup>
					<ListGroup.Item > <h5>< span > Create Exam< /span></h5>< /ListGroup.Item>
					<ListGroup.Item > <p> Decide on a Time</p> < /ListGroup.Item>
					<ListGroup.Item > <p> Identify Students</p> < /ListGroup.Item>
					<ListGroup.Item > <p> Choose Monitoring Level</p> < /ListGroup.Item>
				<Button style={{backgroundColor:"#6576ff",color:"white"}}><a  style={{color:"white"}} href='/newform#createexam'>Schedule Here</a></Button >
                		</ListGroup>
				</Card.Body>
				</Card>
				<Card style={{padding:"10px",margin:"10px",backgroundColor:"#f5f5f5"}} >
				<Card.Title style={{textAlign:"center"}}>Step 3</Card.Title>
				<Card.Body>
                		<ListGroup>
					<ListGroup.Item > < span >Monitor Students< /span> < /ListGroup.Item> 
					<ListGroup.Item > <p>Invite Students</p> < /ListGroup.Item>
					<ListGroup.Item > <p>Warn Cheaters</p> < /ListGroup.Item>
					<ListGroup.Item > <p>from ANYWHERE</p> < /ListGroup.Item>
					<Button style={{backgroundColor:"#6576ff",color:"white"}}><a style={{color:"white"}} href='/examdash'>Monitor Here</a></Button >
                		</ListGroup>
				</Card.Body>
				</Card>
			</div>
		</div>
		</div>
	</div>
	)
}
export default HowItWorks
