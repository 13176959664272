import React, { useState } from 'react';

function SpeechRecognitionComponent({ onTranscriptChange }) {
  const [transcript, setTranscript] = useState('');
  const [isListening, setIsListening] = useState(false);
  const startListening = () => {
    setIsListening(true);
    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.onresult = event => {
      let interimTranscript = '';
      let finalTranscript = '';
      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          finalTranscript += transcript + ' ';
      //onTranscriptChange(finalTranscript); // Pass transcript to parent
        } else {
          interimTranscript += transcript;
		      if(interimTranscript.toLowerCase().includes("mike testing"))
          {
            setTranscript(interimTranscript);
		      	onTranscriptChange(interimTranscript); // Pass transcript to parent
		      }
      //onTranscriptChange(interimTranscript); // Pass transcript to parent
        }
      }

      setTranscript(finalTranscript);
//      this.props.onTranscriptChange(finalTranscript); // Pass transcript to parent
      //onTranscriptChange(finalTranscript); // Pass transcript to parent

    };
    recognition.onend = () => {
      setIsListening(false);
    };
    recognition.start();
  };

  const stopListening = () => {
    window.webkitSpeechRecognition.stop();
    setIsListening(false);
  };

  return (
    <div>
      <a href="#step2" id="capture snap verification-start" className="btn btn-primary mt-2" variant="primary" placeholder='Capture' required onClick={isListening ? stopListening : startListening}> 
	  {isListening ? 'Stop Listening' : 'Click and Say Mic Testing'} 
      < /a>
      <p>{transcript}</p>
    </div>
  );
}

export default SpeechRecognitionComponent;

