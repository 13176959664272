import React from 'react'
import ReactDOM from 'react-dom'
import Jumbotron from 'react-bootstrap/Jumbotron'
import ReactGA from 'react-ga';
import Menu from '../Menu/Menu';
import Button from 'react-bootstrap/Button'
import './NewHome.css'
import Footer from './Footer';


//fa-user-clock
//const element = <FontAwesomeIcon icon={faCoffee} />
//import { faCoffee } from '@fortawesome/free-solid-svg-icons'
//<i class="fas fa-user-clock"></i>

    class NewHome extends React.Component {
	constructor(){
		super();
		// Add your tracking ID created from https://analytics.google.com/analytics/web/#home/
		ReactGA.initialize('UA-53038418-2');
		// This just needs to be called once since we have no routes in this case.
		ReactGA.pageview(window.location.pathname);
		this.state = {
                status: false
            };
	}
	
	
	componentWillMount() {
            const user = JSON.parse(localStorage.getItem('user'));
            if(user) {
            this.state.user = user;
            console.log(this.state.user);
            }
            if (user) this.state.status = true;
            //		                  <img src={'/RP/'+this.state.user.reg_pic.split("'")[1]} style={{width:"3rem",borderRadius:"50%",margin:"auto"}}
    }
        
    render() {
	    return (
		          <div className="main">
		            <nav className="pb-40 text-center navigation_1">
		              <div className="container px-xl-0">
		                <div className="row justify-content-center align-items-center f-20">
		                  <div className="mb-40 mb-lg-0 col-lg-3 text-lg-left col-12">
		                    <a href="#" className="link img_link">
		                      <img src="../images/monitor-exam-logo.png" alt="Online Exam Logo" className="img-fluid" width="200px" data-aos="fade-up" data-aos-delay={150} />
		                    </a>
		                  </div>
		                  <div className="col-lg-6 col-12 justify-content-center" data-aos="fade-up" data-aos-delay={0}>
		                    <a href="/about" className="mx-15 ">
		                      About Us
		                    </a>
		                    <a href="/howitworks" className="mx-15 ">
		                      How It Works
		                    </a>
		                    <a href="/faq" className="mx-15 ">
		                      FAQs
		                    </a>
		                    <a href="/pricing" className="mx-15 ">
		                      Pricing
		                    </a>
		                    <a href="/contact" className="mx-15 ">
		                      Contact
		                    </a>
		                  </div>
		                  <div className="mt-20 mt-lg-0 col-lg-3 col-12 align-items-center justify-content-center" data-aos="fade-up" data-aos-delay={150} style={{display:this.state.user?"none":"flex"}}>
		                    <a href="/newLogin" className="btn btn-outline-primary mr-15 mb-10">
		                      Login
		                    </a>
		                    <a href="/newiregister" className="btn mr-15 btn-primary">
		                      Register
		                    </a>
		                  </div>
		                  <div style={{display:this.state.user?"flex":"none"}} className="mt-20 mt-lg-0 col-lg-3 flex-wrap justify-content-center justify-content-lg-end align-items-center"  data-aos="fade-up" data-aos-delay={150}>

		                  <a href="/testdash" className="btn btn-outline-primary link mr-15 color-main">
		                      Dashboard
		                  </a>
		                  <a className ="btn mr-15 btn-primary" href = "/newlogin"style = {{
                        }} onClick = {
                            () => localStorage.clear()
                        } > Logout </a>
		                  </div>
		                </div>
		              </div>
		            </nav>
		            {/* Navigation Mobile type 2 */}
		            <a className="open_menu color-main  radius_full">
		              <i className="fas fa-bars lh-40">
		              </i>
		            </a>
		            <div className="navigation_mobile  type2">
		              <a className="close_menu color-main">
		                <i className="fas fa-times">
		                </i>
		              </a>
		              <div className="px-40 pt-50 pb-50 text-center inner">
		                <div>
		                  <a href="/" className="f-heading f-22 link color-main mb-20">
		                    Home
		                  </a>
		                </div>
		                <div>
		                  <a href="/about" className="f-heading f-22 link color-main mb-20">
		                    About Us
		                  </a>
		                </div>
		                <div>
		                  <a href="/contact" className="f-heading f-22 link color-main mb-20">
		                    Contact
		                  </a>
		                </div>
		                <div>
		                  <a href="/howitworks" className="f-heading f-22 link color-main mb-20">
		                    HowItWorks
		                  </a>
		                </div>
		                <div>
		                  <a href="/faq" className="f-12 link color-main op-7 text-uppercase sp-20 mb-20">
		                    F.A.Q.
		                  </a>
		                </div>
		                <div>
		                  <a href="#" className="f-12 link color-main op-7 text-uppercase sp-20 mb-20">
		                    About Us
		                  </a>
		                </div>
		                <div>
		                  <a href="#" className="f-12 link color-main op-7 text-uppercase sp-20 mb-20">
		                    Blog
		                  </a>
		                </div>
		                <div className="socials mt-60">
		                  <a href="#" target="_blank" className="link color-main f-18 mx-10">
		                    <i className="fab fa-twitter">
		                    </i>
		                  </a>
		                  <a href="#" target="_blank" className="link color-main f-18 mx-10">
		                    <i className="fab fa-facebook">
		                    </i>
		                  </a>
		                  <a href="#" target="_blank" className="link color-main f-18 mx-10">
		                    <i className="fab fa-instagram">
		                    </i>
		                  </a>
		                </div>
		              </div>
		            </div>
		            {/* Header 2 */}
		            <header className="pt-100 pb-100 bg-dark header_2 scroll1">
		              <nav className="header_menu_2 transparent pt-40 pb-30 mt-40">
		                <div className="container px-xl-0">
		                  <div className="row justify-content-between align-items-center">
		                    <div className="col-md-6 d-flex justify-content-end align-items-center" data-aos="fade-up" data-aos-delay={450}>
		                      <a href="#" className="link color-white f-18 mx-15">
		                      </a>
		                      <a href="#" className="link color-white f-18 mx-15">
		                      </a>
		                    </div>
		                  </div>
		                </div>
		              </nav>
		              <div className="container " >
		    <div className="headerbox col-lg-6 col-md-8 col-sm-12">
		        <h1 className="big features text-center">
		          Secure
		        </h1>
		        <h1 className="small tagline">
		          and
		        </h1>
		        <h1 className="big features text-center">
		          Stress-Free
		        </h1>
		        <h1 className="big color-white text-center tagline" data-aos="fade-up" data-aos-delay={0}>
		          Remote Testing
		        </h1>
		    <br />
		        <h1 className="big color-white text-center tagline" data-aos="fade-up" data-aos-delay={0}>
		          Experience
		        </h1>
		    <br />
		    </div>
		                <div data-aos="fade-up" data-aos-delay={300}>
		                <div data-aos="fade-up" data-aos-delay={300} className="pb-20 w-max-550px" style={{float:"right"}}>
		                  <a href="https://bit.ly/monitorexam" className="btn lg action-1 justify-right ml-2">
		                     Get Demo 
		                  </a>
		                </div>
		                  <div className="pt-40 color-white f-22 " style={{float:"right"}}>
		                    <a href="https://aws.amazon.com/activate"><img width="150px" src="https://d0.awsstatic.com/logos/powered-by-aws-white.png" alt="Online Exam Powered by AWS Cloud Computing" /></a>
		                     <a href="https://nvidia.com/en-us/startups"><img width="150px" src="../images/nvidia-inception-program-badge-rgb-for-screen.png" alt="nvidia inception" /></a>
		                     <a href="https://startups.microsoft.com"><img width="150px" src="../images/startups_wordmark_purple.png" alt="Microsoft for Startups" /></a>
		                  </div>
		                </div>

		                <div className="mw-620 mx-auto f-22 color-white op-7 text-center text-adaptive d-none" data-aos="fade-up" data-aos-delay={150}>
		                  <h4>Empowering Students with Privacy First Technology for Remote Proctoring in</h4>
		                </div>
		    		<div className="vwrap justify-center mt-30 d-none">
		                <div className="mw-620 f-40 color-white text-left vmove text-adaptive mt-25 d-none" >
		                <div className="vslide text-center">
		                <em style={{color:"#faceae"}}> Testing Centers</em> 
		                </div>
		                <div className="vslide text-center">
		                <em style={{color:"#faceae"}}>Certification Tests </em> 
		                </div>
		                <div className="vslide text-center">
		                <em style={{color:"#faceae"}}> Intern Hiring </em>
		                </div>
		                <div className="vslide text-center">
		                <em style={{color:"#faceae"}}> Distance Learning </em>
		                </div>
		                </div>
		                </div>

				</div>
		            </header>
		    <section className="bg-light">
		    <div className="ellipse">
		      <h5 className="textinellipse text-center f-24"> Empower your students with Privacy-First Remote Testing Experience:   </h5>
		    </div>
		    <div className="row block-container">
		      <div className="block block-red container col col-6 col-sm-3">
		    <div class="mt-250 justify-content-center">
		    <svg width="68" height="65" viewBox="0 0 68 65" fill="none" xmlns="http://www.w3.org/2000/svg">
		    <path d="M67.9632 26.0325C68.2381 24.1658 66.9429 22.4305 65.0708 22.1565L45.72 19.3361L37.0728 1.76669C36.7614 1.23078 36.3145 0.785706 35.7766 0.475867C34.0781 -0.502826 31.9053 0.07643 30.9234 1.77003L22.2796 19.3361L2.92873 22.1567C2.18891 22.2649 1.50509 22.6116 0.98115 23.1435C-0.344476 24.4898 -0.324204 26.6526 1.02608 27.9743L15.0401 41.6731L11.7331 61.0094C11.7017 61.1944 11.6856 61.3817 11.6852 61.5697C11.68 63.4589 13.2119 64.9948 15.107 65C15.6657 64.9992 16.2157 64.8619 16.7087 64.5999L33.9998 55.4852L51.2875 64.5966C51.9556 64.9502 52.7222 65.0733 53.4679 64.9467C55.3312 64.6297 56.5841 62.8672 56.2664 61.0094L52.9594 41.6731L66.9768 27.9707C67.5085 27.449 67.8548 26.7685 67.9632 26.0325ZM32.2877 52.5256L15.1104 61.5826L18.5677 41.3663C18.6607 40.8142 18.4772 40.2516 18.0762 39.8595L3.42341 25.5372L23.6671 22.5868C24.2261 22.5034 24.7085 22.1521 24.958 21.6466L32.2877 6.75288V52.5256ZM49.9232 39.8592C49.5223 40.2514 49.3388 40.814 49.4316 41.3661L52.8892 61.5793L35.7118 52.5254V6.75288L43.0413 21.6464C43.2909 22.1519 43.7734 22.5032 44.3323 22.5866L64.5793 25.5337L49.9232 39.8592Z" fill="#5C616E"/>
		    </svg>
		    </div>
		    <div className="mt-15 justify-content-center">
		    <p className="text-center">Testing Centers</p>
		    </div>

		    </div>
		      <div className="block block-light-red container col col-6 col-sm-3">
		    <div class="mt-250 justify-content-center">
		    <svg width="70" height="69" viewBox="0 0 70 69" fill="none" xmlns="http://www.w3.org/2000/svg">
		    <path d="M68.8434 2.54857C68.8434 1.85274 68.1454 1.50483 67.4474 1.15691C54.1855 -2.32222 39.8765 2.20065 31.1516 12.6381L26.6146 17.8568L17.1917 15.4214C13.3527 14.0297 9.51373 15.7693 7.76874 19.2484L0.439783 32.1212C0.439783 32.1212 0.439783 32.4691 0.0907847 32.4691C-0.258213 33.5128 0.439782 34.2087 1.48678 34.5566L13.3527 36.992C12.3057 40.1232 11.2587 43.2544 10.9097 46.3856C10.9097 47.0815 10.9097 47.4294 11.2587 47.7773L21.7287 57.8668C22.0777 58.2147 22.4266 58.2147 23.1246 58.2147C26.2656 57.8668 29.7556 57.171 32.8966 56.1272L35.3396 67.6083C35.3396 68.3042 36.3866 69 37.0846 69C37.4336 69 37.7826 69 37.7826 68.6521L50.6955 61.3459C53.8365 59.6063 55.2324 55.7793 54.5345 52.3002L52.0915 42.2107L56.9774 37.6878C67.7964 29.6858 72.3333 15.7693 68.8434 2.54857ZM4.62776 31.7733L10.5607 20.988C11.6077 18.9005 13.7017 18.2047 15.7957 18.9005L23.8226 20.988L20.3327 25.1629C18.2387 27.9462 16.1447 30.7295 14.7487 33.8608L4.62776 31.7733ZM49.2995 59.2584L38.4805 65.5209L36.3866 55.4314C39.5275 54.0397 42.3195 51.9522 45.1115 49.8648L49.6485 45.6898L51.7425 53.6918C51.7425 55.7793 51.0445 58.2147 49.2995 59.2584ZM54.8835 35.9482L42.6685 47.0815C37.4336 51.6043 30.8026 54.3876 23.8226 55.0835L14.7487 46.0377C15.7957 39.0795 18.5877 32.4691 23.1246 27.2504L28.7086 20.988L29.0576 20.6401L33.9436 15.0734C41.6215 6.0277 54.1855 1.85274 65.7024 4.28813C68.1454 16.1172 64.3064 28.2941 54.8835 35.9482ZM50.6955 12.6381C46.8565 12.6381 44.0645 15.7693 44.0645 19.2484C44.0645 22.7275 47.2055 25.8588 50.6955 25.8588C54.5345 25.8588 57.3264 22.7275 57.3264 19.2484C57.3264 15.7693 54.5345 12.6381 50.6955 12.6381ZM50.6955 22.7275C48.9505 22.7275 47.5545 21.3359 47.5545 19.5963C47.5545 17.8568 48.9505 16.4651 50.6955 16.4651C52.4405 16.4651 53.8365 17.8568 53.8365 19.5963C53.8365 21.3359 52.4405 22.7275 50.6955 22.7275Z" fill="#5C616E"/>
		    </svg>
		    </div>
		    <div class="mt-15 justify-content-center">

		    <p className="text-center ">Industry Certifications</p>
		    </div>

		    </div>
		      <div className="block block-light-blue container col col-6 col-sm-3">
		    <div class="mt-250 justify-content-center">
		    <svg width="54" className="justify-center" height="68" viewBox="0 0 54 68" fill="none" xmlns="http://www.w3.org/2000/svg">
		    <path d="M1.6875 54.4C0.755585 54.4 0 55.1612 0 56.1V66.3019C0.000617963 67.2403 0.755997 68.0006 1.6875 68H1.68956C2.62086 67.9994 3.37562 67.2384 3.375 66.3V56.1C3.375 55.1612 2.61942 54.4 1.6875 54.4ZM18.5625 40.8C17.6306 40.8 16.875 41.5612 16.875 42.5V66.3019C16.8756 67.2403 17.631 68.0006 18.5625 68H18.5646C19.4959 67.9994 20.2506 67.2384 20.25 66.3V42.5C20.25 41.5612 19.4944 40.8 18.5625 40.8ZM52.3125 0C51.3806 0 50.625 0.761182 50.625 1.7V66.3019C50.6256 67.2403 51.381 68.0006 52.3125 68H52.3146C53.2459 67.9994 54.0006 67.2384 54 66.3V1.7C54 0.761182 53.2444 0 52.3125 0ZM35.4375 23.8C34.5056 23.8 33.75 24.5612 33.75 25.5V66.3019C33.7506 67.2403 34.506 68.0006 35.4375 68H35.4396C36.3709 67.9994 37.1256 67.2384 37.125 66.3V25.5C37.125 24.5612 36.3694 23.8 35.4375 23.8Z" fill="white"/>
		    </svg>
		    </div>
		    <div class="mt-15 justify-content-center">
		    <p className="text-center color-white">Intern Hiring</p>

		    </div>
		    </div>
		      <div className="block block-blue container col col-6 col-sm-3">
		    <div class="mt-250 justify-content-center">
		    <svg width="53" height="66" className="justify-center"  viewBox="0 0 53 66" fill="none" xmlns="http://www.w3.org/2000/svg">
		    <path d="M51.3438 37.95H41.4062C40.4916 37.95 39.75 38.6888 39.75 39.6C39.75 40.5112 40.4916 41.25 41.4062 41.25H49.6293C48.8052 52.7067 39.6574 61.8202 28.1573 62.641C28.1569 62.641 28.1567 62.641 28.1562 62.641V29.7H34.7812C35.6959 29.7 36.4375 28.9612 36.4375 28.05C36.4375 27.1388 35.6959 26.4 34.7812 26.4H28.1562V19.632C32.8492 18.8388 36.432 14.8 36.4375 9.9C36.4375 4.43236 31.9883 0 26.5 0C21.0117 0 16.5625 4.43236 16.5625 9.9C16.5625 14.8025 20.1479 18.8445 24.8438 19.6336V26.4H18.2188C17.3041 26.4 16.5625 27.1388 16.5625 28.05C16.5625 28.9612 17.3041 29.7 18.2188 29.7H24.8438V62.6158C13.5199 61.8188 4.20552 52.8582 3.37073 41.25H11.5938C12.5084 41.25 13.25 40.5112 13.25 39.6C13.25 38.6888 12.5084 37.95 11.5938 37.95H1.65625C1.65564 37.95 1.65504 37.95 1.65423 37.95C0.740178 37.9506 -0.000606147 38.6892 3.72176e-07 39.6C0.0167812 54.1735 11.8713 65.9833 26.5 66C41.1287 65.9833 52.9832 54.1735 53 39.6C53 39.5994 53 39.5988 53 39.598C52.9994 38.6874 52.258 37.9494 51.3438 37.95ZM19.875 9.9C19.875 6.25497 22.8412 3.3 26.5 3.3C30.157 3.30463 33.1203 6.25679 33.125 9.9C33.125 13.545 30.1588 16.5 26.5 16.5C22.8412 16.5 19.875 13.545 19.875 9.9Z" fill="white"/>
		    </svg>
		    </div>
		    <div className="mt-15 justify-content-center">
		    <p className="text-center color-white">Distance Learning</p>
		    </div>

		    </div>
		    </div>
		    </section>
		    <section className="bg-light benefits-section">
		    <div className="benefits">
		    </div>
		    <p className="benefitstext">
		    Benefits
		    </p>
		    <div className="benefitrect ">
		    <div className="row">
		    <div className="col-md-5 col-10">
		    <h5 className="ml-60 mt-25 btext2"> Designed for Student Comfort and Privacy</h5>
		    </div>
		    <div className="col-md-5 col-10">
		    <h4 className="mt-25 btext2"> Delightful Student Experience</h4>
		    </div>
		    </div>
		    </div>
		    <div className="benefitrect benefitrect-light">
		    <div className="row">
		    <div className="col-md-5 col-10">
		    <h5 className="ml-60 mt-25 btext2">Less Frustration while Starting Exams</h5>
		    </div>
		    <div className="col-md-5 col-10">
		    <h4 className="mt-25 btext2"> Multi-Path Exam Entry</h4>
		    </div>
		    </div>
		    </div>
		    <div className="benefitrect benefitrect-lighter">
		    <div className="row">
		    <div className="col-md-5 col-10">
		    <h5 className="ml-60 mt-25 btext2">Optimized use of Network and Compute</h5>
		    </div>
		    <div className="col-md-5 col-10">
		    <h4 className="mt-25 btext2"> Low Carbon Footprint</h4>
		    </div>
		    </div>
		    </div>
		    </section>
		    <section className="bg-light testimonials-section">
		    <div className="container">
                    <div className="row mt-60">
		    <div className="testimonial block-blue col-md-6 col-sm-12 col-12 container">
		                <div className="row justify-content-center">
		                  <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10">
		                    <img src="/anit.jpg" alt="Online Exam Customer Testimonial" className="w-120 h-120 radius_full" data-aos="fade-up" data-aos-delay={300} />
		                    <div className="mt-20 f-16 medium" data-aos="fade-up" data-aos-delay={450}>
		                      Anit Gupta
		                    </div>
		                    <div className="mb-20 f-14 semibold color-heading text-uppercase sp-20" data-aos="fade-up" data-aos-delay={450}>
		                      Co-founder, ARCedtech
		                    </div>
		                    <div className="mt-20 mb-20 f-16 text-adaptive" data-aos="fade-up" data-aos-delay={150}>
		                     "We conducted <a href="https://arcedtech.com/aisct">AISCT</a> with MonitorExam. We found the MonitorExam team structured, collaborative and systematic. They were willing to adapt. They agreed to add additional functionality like practice test, on our request, in a timely manner. They conducted office hours for students to get them comfortable with the platform, provided live proctoring and later a credibility report of the AISCT Olympiad." 
		                    </div>
		                  </div>
		                  </div>
		    </div>
		    <div className="testimonial block-red col-md-6 col-sm-12 col-12 container">
		                <div className="row justify-content-center">
		                  <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10">
		                    <img src="/testimonial_2_img.jpg" srcSet="/testimonial_2_img@2x.jpg 2x" alt="Online Proctoring Customer Testimonial" className="w-120 h-120 radius_full" data-aos="fade-up" data-aos-delay={300} />
		                    <div className="mt-20 f-16 medium" data-aos="fade-up" data-aos-delay={450}>
		                      Sarika Narang
		                    </div>
		                    <div className="mb-20 f-14 semibold color-heading text-uppercase sp-20" data-aos="fade-up" data-aos-delay={450}>
		                      Principal, Sanskaar School
		                    </div>
		                    <div className="mt-20 mb-20 f-18 text-adaptive" data-aos="fade-up" data-aos-delay={150}>
		                     "With MonitorExam test taking has become easy. No longer do I have to keep staring at the online video session during the test. I can simply use the <em>cred score </em> at the end." 
		                    </div>
		                  </div>
		                </div>
		    </div>
		    <div className="ellipse ellipset">
		      <p className="textinellipse textinellipse-reverse test-center f-24 color-white"> Testimonials </p>
		    </div>
		    </div>
		    </div>
		    </section>
		            {/* Call to action 1 */}
		    {/*<section className="pb-25 bg-light text-center call_to_action_1 d-none">
		              <div className="container px-xl-0">
		                <div className="row justify-content-center">
		                  <div className="col-xl-8 col-lg-10">
		                    <h2 data-aos="fade-up" data-aos-delay={0}>
		                      AI Based Proctoring 
		                    </h2>
		                  </div>
		                  <div className="col-xl-7 col-lg-9 col-md-10">
		                    <div className="mt-20 mb-40 f-26 color-heading text-adaptive description" data-aos="fade-up" data-aos-delay={150}>
		                      <em><strong>        Setup Exams with <a href="forms.google.com">Google Forms</a></strong></em>.
		                      <br /> Know credibility with <em><strong> Cred Score</strong></em>. 
		                      <br /> Get <em><strong>Report</strong></em> on suspicious students<br />
		                    </div>
		                  </div>
		                </div>
		              </div>
		            </section>
			    */}
		            {/* Testimonial 2 */}
		            {/* Feature 1 */}
		    {/*<section className="pb-5 bg-dark text-center feature_1 d-none">
		              <div className="container px-xl-0">
		                <div className="row justify-content-center">
		                  <div className="col-xl-10">
		                    <h2 className="mb-25 small color-white" data-aos="fade-up" data-aos-delay={0}>
		                      MonitorExam Benefits
		                    </h2>
		                    <div className="row justify-content-center">
		                      <div className="col-md-4 mb-50" data-aos="fade-up" data-aos-delay={150}>
		                      <img src='/exam.png' width="140" height="140" style={{borderRadius:"10%"}} />
		                        <div className="mt-20 mb-25 f-22 title color-white">
		                          Exam Management
		                        </div>
		                        <div className="color-white text-adaptive">
		                          Ensures that exams start 
		                          <br /> and stop at the stipulated time
		                        </div>
		                      </div>
		                      <div className="col-md-4 mb-30" data-aos="fade-up" data-aos-delay={300}>
		                      <img src='/face-detection.png' width="140" height="140" style={{borderRadius:"10%"}} />
		                        <div className="mt-20 mb-25 f-22 title color-white">
		                          AI Based Face Detection
		                        </div>
		                        <div className="color-white text-adaptive">
		                          AI Based Face Detection ensures 
		                          <br /> that the invigilator knows when 
		                          <br /> student is away or is being helped 
		                        </div>
		                      </div>
		                      <div className="col-md-4 mb-30" data-aos="fade-up" data-aos-delay={450}>
		                      <img src='/logintimestamp.png' width="140" height="140" style={{borderRadius:"10%"}} />
		                        <div className="mt-20 mb-25 f-22 title color-white">
		                          Student Attention Level
		                        </div>
		                        <div className="color-white text-adaptive">
		                          Monitors student attention level 
		                          <br /> and highlights distracted 
		                          <br /> students
		                        </div>
		                      </div>
		                    </div>
		                  </div>
		                </div>
		              </div>
		            </section>*/}
		            <section className="last-section pb-20 bg-white subscribe d-none">
		              <div className="container px-xl-0 text-center">
		                <div className="row justify-content-center">
		                    <h2 className="mb-25 small" data-aos="fade-up" data-aos-delay={0}>
		    			Subscribe to Blog 
		    		    </h2>
		    		</div>
		            <div className="f-26 color-heading text-left description" data-aos="fade-up" data-aos-delay={150}>
		              <iframe allowtransparency="false" src="https://sreepriya.substack.com/embed" width="100%" height="100%" frameborder="0" scrolling="no" title="Subscribe to our Blog" style={{"backgroundColor":"#f5f6fa"}}></iframe>
	                    </div>
		              </div>
		            </section>
		  <section className="last-section pb-65 bg-light text-center call_to_action_1">
		    <div class="container pt-30 bg-light">

		    <div class="row text-center "><h2 >Awards and Mentions</h2></div>
		    <div class="row text-center ">
		      <div class="col-md-4">
		        <div class="row text-center">
		          <img src="https://i0.wp.com/fidoalliance.org/wp-content/uploads/2022/06/Hackathon-Banners-2022_1.png?resize=1536%2C241&amp;ssl=1">
		          </img>
		        </div>
		        <div class="row text-center "><a href="https://fidoalliance.org/fido-alliance-awards-winner-and-top-finalists-of-developer-challenge-india/" class="">MonitorExam wins FIDO Dev Challenge 2022</a></div></div><div class="col-md-4"><div class="row text-center"><img src="https://blog.upekkha.io/content/images/2022/05/m.jpg"></img></div><div class="row text-center "><a href="https://www.upekkha.io/blog/upekkha-value-saas-startup-series-02-monitorexam" class="">Upekkha Value SaaS Startup MonitorExam</a></div></div><div class="col-md-4"><iframe width="440" height="315" src="https://www.youtube.com/embed/oFSr9EptGnY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div></div>
		    </section>
		  <section className="last-section pb-65 bg-light text-center call_to_action_1">
		              <div className="container px-xl-0 text-center">
		                <div className="row justify-content-center">
		  <div className="" data-aos="fade-up" data-aos-delay={300}>
		   <h2>Works with</h2><br />
		   <div className="color-white f-22" style={{float:"right"}}>
		    <div className="row">
		    <div className="col-md-2">
		   <a href="https://forms.google.com"><img width="150px" className="pr-3 pl-3" src="https://www.gstatic.com/images/branding/product/2x/forms_2020q4_48dp.png" alt="Google Forms Online Exam" /><p>Google Forms</p></a>
		    </div>
		    <div className="col-md-2">
		   <a href="https://www.adobe.com"><img width="150px" className="pr-3 pl-3" src="https://www.adobe.com/content/dam/cc/icons/Adobe_Corporate_Horizontal_Red_HEX.svg" alt="Adobe Online Exam" /><p>Adobe Acrobat</p></a>
		    </div>
		    <div className="col-md-2">
		   <a href="https://forms.microsoft.com"><img width="150px" className="pr-3 pl-3" src="https://www.bing.com/th?id=OIP.1gu2XSql9LAD96bRZpm5LQAAAA&w=150&h=150&c=8&rs=1&qlt=90&o=6&dpr=1.5&pid=3.1&rm=2" alt="Microsoft Online Exam" /><p>Microsoft Forms</p></a>
		    </div>
		    <div className="col-md-2">
		   <a href="https://www.quizizz.com"><img width="150px" className="pr-3 pl-3" src="https://cf.quizizz.com/img/quizizz_logos/purple-brandmark-600x164.png" alt="Quizizz Logo" />Quizizz</a>
		    </div>
		    <div className="col-md-2">
		   <a href="https://www.testmoz.com"><img width="150px" className="pr-3 pl-3" src="/testmoz.png" alt="Testmoz Logo" /></a>
		    </div>
		    </div>
		    </div>
		  </div>
		  </div>
		  </div>
		  </section>
		  <section className="last-section pb-55 bg-dark text-center call_to_action_1 d-none">
		              <div className="container px-xl-0 text-center">
		                <div className="row justify-content-center">
		  <div className=" col-xl-6 col-md-8 col-sd-10" data-aos="fade-up" data-aos-delay={300}>
		   <h2 className="color-white"> Partners with </h2><br />
		  <div className="color-white f-22" style={{float:"right"}}>
		    <img width="150px" src="https://d0.awsstatic.com/logos/powered-by-aws-white.png" alt="Powered by AWS Cloud Computing" />
		    <img width="150px" src="../images/nvidia-inception-program-badge-rgb-for-screen.png" alt="nvidia inception" />
		    <img width="150px" src="../images/startups_wordmark_purple.png" alt="Microsoft for Startups" />
		  </div>
		  </div>
		  </div>
		  </div>
		  </section>
		    {/*<section className="pb-45 bg-light testimonial_2">
		              <div className="container px-xl-0 text-center">
		                <div className="row justify-content-center">
		                    <h2 className="mb-25 small" data-aos="fade-up" data-aos-delay={0}>
		    			Testimonials 
		    		    </h2>
		    		</div>
		                <div className="row justify-content-center">
		                  <div className="col-xl-6 col-lg-8 col-md-10">
		                    <div className="mt-20 mb-20 f-18 text-adaptive" data-aos="fade-up" data-aos-delay={150}>
		                     "With MonitorExam test taking has become easy. No longer do I have to keep staring at the online video session during the test. I can simply use the <em>cred score </em> at the end." 
		                    </div>
		                    <img src="/testimonial_2_img.jpg" srcSet="/testimonial_2_img@2x.jpg 2x" alt="" className="w-120 h-120 radius_full" data-aos="fade-up" data-aos-delay={300} />
		                    <div className="mt-20 f-16 medium" data-aos="fade-up" data-aos-delay={450}>
		                      Sarika Narang
		                    </div>
		                    <div className="mb-20 f-14 semibold color-heading text-uppercase sp-20" data-aos="fade-up" data-aos-delay={450}>
		                      Principal, Sanskaar School
		                    </div>
		                  </div>
		                  <div className="col-xl-6 col-lg-8 col-md-10">
		                    <div className="mt-20 mb-20 f-18 text-adaptive" data-aos="fade-up" data-aos-delay={150}>
		                     "We conducted <a href="https://arcedtech.com/aisct">AISCT</a> with MonitorExam. We found the MonitorExam team a reliable and flexible partner. They were flexible enough to add additional functionality like practice test, charging for the same on our request. They conducted office hours for students to get them comfortable with the platform, provided live proctoring and later a complete report on credibility of all the students." 
		                    </div>
		                    <img src="/anit.jpg" alt="Anit Gupta photo" className="w-120 h-120 radius_full" data-aos="fade-up" data-aos-delay={300} />
		                    <div className="mt-20 f-16 medium" data-aos="fade-up" data-aos-delay={450}>
		                      Anit Gupta
		                    </div>
		                    <div className="mb-20 f-14 semibold color-heading text-uppercase sp-20" data-aos="fade-up" data-aos-delay={450}>
		                      Founder, ARCedtech
		                    </div>
		                  </div>
		                </div>
		              </div>
		            </section>*/}
		            {/* Pricing table 1 */}
		            <section className="pt-35 pb-35 bg-light text-center pricing_table_1" style={{"display":"none"}}>
		              <div className="container px-xl-0">
		                <h2 className="small" data-aos="fade-up" data-aos-delay={0}>
		                  Pricing Plans
		                </h2>
		                <div className="mt-15 mb-60 f-22 color-heading text-adaptive" data-aos="fade-up" data-aos-delay={150}>
		                  *Cancel anytime.
		                </div>
		                <div className="row align-items-start no-gutters">
		                  <div className="col-lg-3">
		                    <div className="pt-35 radius10 noradius_right noborder_right block">
		                      <div className="f-22 title" data-aos="fade-up" data-aos-delay={0}>
		                        Parents
		                      </div>
		                      <div className="mt-10 mb-35 color-heading subtitle" data-aos="fade-up" data-aos-delay={0}>
		                        Forever Free
		                      </div>
		                      <div data-aos="fade-up" data-aos-delay={0}>
		                        <div className="d-inline-block f-58 relative price">
		                          <span className="absolute f-16">
		                            $
		                          </span>
		                          <b>
		                            0
		                          </b>
		                        </div>
		                      </div>
		                      <div data-aos="fade-up" data-aos-delay={0}>
		                        <a href="/pregister#signup" className="btn mt-40 mb-40  action-2 border-gray color-main">
		                          Get Started for Free
		                        </a>
		                      </div>
		                      <div className="item" data-aos="fade-up" data-aos-delay={0}>
		                        2 Test-Takers
		                      </div>
		                      <div className="item" data-aos="fade-up" data-aos-delay={0}>
		                        Unlimited Tests 
		                      </div>
		                      <div className="item" data-aos="fade-up" data-aos-delay={0}>
		                      </div>
		                    </div>
		                  </div>
		                  <div className="col-lg-3">
		                    <div className="pt-35 noborder_right block">
		                      <div className="f-22 title" data-aos="fade-up" data-aos-delay={150}>
		                        Tutor
		                      </div>
		                      <div className="mt-10 mb-35 color-heading subtitle" data-aos="fade-up" data-aos-delay={150}>
		                        Perfect for Tutors
		                      </div>
		                      <div data-aos="fade-up" data-aos-delay={150}>
		                        <div className="d-inline-block f-58 relative price">
		                          <span className="absolute f-16">
		                            $
		                          </span>
		                          <b>
		                            6
		                          </b>
		                        </div>
		                      </div>
		                      <div data-aos="fade-up" data-aos-delay={150}>
		                      <div ><a  className="btn mt-40 mb-40 action-1" style={{color:"white"}} href='https://www.payumoney.com/paybypayumoney/#/C3665E868F59148D931C3E3725D84FC0'> Pay to Get Started*</a></div >
		                      </div>
		                      <div className="item" data-aos="fade-up" data-aos-delay={150}>
		                        Exam Management
		                      </div>
		                      <div className="item" data-aos="fade-up" data-aos-delay={150}>
		                        Upto 20 students
		                      </div>
		                      <div className="item" data-aos="fade-up" data-aos-delay={150}>
		                        Cancel Anytime
		                      </div>
		                      <div className="item" data-aos="fade-up" data-aos-delay={150}>
		                      </div>
		                    </div>
		                  </div>
		                  <div className="col-lg-3">
		                    <div className="pt-35 noborder_right block">
		                      <div className="f-22 title" data-aos="fade-up" data-aos-delay={300}>
		                        Schools	
		                      </div>
		                      <div className="mt-10 mb-35 color-heading subtitle" data-aos="fade-up" data-aos-delay={300}>
		                        Customizable
		                      </div>
		                      <div data-aos="fade-up" data-aos-delay={300}>
		                        <div className="d-inline-block f-58 relative price">
		                          <span className="absolute f-16">
		                            $
		                          </span>
		                          <b>
		                            499
		                          </b>
		                        </div>
		                      </div>
		                      <div data-aos="fade-up" data-aos-delay={300}>
		                        <a href="/contact" className="btn mt-40 mb-40 border-gray  action-2 color-main">
		                          Contact Us
		                        </a>
		                      </div>
		                      <div className="item" data-aos="fade-up" data-aos-delay={300}>
		                        Exam Management
		                      </div>
		                      <div className="item" data-aos="fade-up" data-aos-delay={300}>
		                        500 students
		                      </div>
		                      <div className="item" data-aos="fade-up" data-aos-delay={300}>
		                        20 Invigilators
		                      </div>
		                      <div className="item" data-aos="fade-up" data-aos-delay={300}>
		                        Monthly Billing
		                      </div>
		                      <div className="item" data-aos="fade-up" data-aos-delay={300}>
		                      </div>
		                    </div>
		                  </div>
		                  <div className="col-lg-3">
		                    <div className="pt-35 radius10 noradius_left block">
		                      <div className="f-22 title" data-aos="fade-up" data-aos-delay={450}>
		                        EdTech Startups
		                      </div>
		                      <div className="mt-10 mb-35 color-heading subtitle" data-aos="fade-up" data-aos-delay={450}>
		                        Customizable
		                      </div>
		                      <div data-aos="fade-up" data-aos-delay={450}>
		                        <div className="d-inline-block f-58 relative price">
		                          <span className="absolute f-16">
		                            $
		                          </span>
		                          <b>
		                            999
		                          </b>
		                        </div>
		                      </div>
		                      <div data-aos="fade-up" data-aos-delay={450}>
		                        <a href="/contact" className="btn mt-40 mb-40 border-gray  action-1 color-main">
		                          Contact Us
		                        </a>
		                      </div>
		                      <div className="item" data-aos="fade-up" data-aos-delay={450}>
		                        As Many tests as needed
		                      </div>
		                      <div className="item" data-aos="fade-up" data-aos-delay={450}>
		                        Unlimited Test-Takers
		                      </div>
		                      <div className="item" data-aos="fade-up" data-aos-delay={450}>
		                        Unlimited Invigilators
		                      </div>
		                      <div className="item" data-aos="fade-up" data-aos-delay={450}>
		                        Unlimited Tests
		                      </div>
		                      <div className="item" data-aos="fade-up" data-aos-delay={450}>
		                        On Call Support
		                      </div>
		                      <div className="item" data-aos="fade-up" data-aos-delay={450}>
		                      </div>
		                    </div>
		                  </div>
		                </div>
		              </div>
		            </section>
		    	    <section className="last-section">
		    	        <Footer />
		            </section>
		          </div>
		        );
	  }
}
export default NewHome
